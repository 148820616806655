import { 
    OBTENER_DATA_TABLA_ADMINISTRAR_PERMISOS,
    OBTENER_FILTRO_TABLA_ADMINISTRAR_PERMISOS,
    OBTENER_ADMINISTRAR_TIPOS_PERMISOS,
    SPIN_ACCIONES_ADMINISTRAR_PERMISOS,
    SPIN_TABLE_ADMINISTRAR_PERMISOS,
} from "../../../../Constantes/Administrar/Permisos/Permisos";

const INIT_STATE = {
    rex_data_tabla_permisos : [],
    rex_filtro_tabla_permisos : [],
    rex_data_tipos_permisos : [],
    rex_spin_acciones_permisos: false,
    rex_spin_permisos: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_TABLA_ADMINISTRAR_PERMISOS :
            return {
                ...state,
                rex_data_tabla_permisos : action.payload
            }
        case OBTENER_FILTRO_TABLA_ADMINISTRAR_PERMISOS :
            return {
                ...state,
                rex_filtro_tabla_permisos : action.payload
            } 
        case OBTENER_ADMINISTRAR_TIPOS_PERMISOS :
            return {
                ...state,
                rex_data_tipos_permisos : action.payload
            } 
        case SPIN_ACCIONES_ADMINISTRAR_PERMISOS :
            return {
                ...state,
                rex_spin_acciones_permisos : action.payload
            }
        case SPIN_TABLE_ADMINISTRAR_PERMISOS :
            return {
                ...state,
                rex_spin_permisos : action.payload
            }
        default:
            return state;
    }
}