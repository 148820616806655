import { 
    OBTENER_DATA_TABLA_ADMINISTRAR_PAISES,
    OBTENER_FILTRO_TABLA_ADMINISTRAR_PAISES,
    SPIN_ACCIONES_ADMINISTRAR_PAISES,
    SPIN_TABLE_ADMINISTRAR_PAISES,
} from "../../../../Constantes/Administrar/Paises/Paises";

const INIT_STATE = {
    rex_data_tabla_paises : [],
    rex_filtro_tabla_paises : [],
    rex_spin_acciones_paises: false,
    rex_spin_paises: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_TABLA_ADMINISTRAR_PAISES :
            return {
                ...state,
                rex_data_tabla_paises : action.payload
            }
        case OBTENER_FILTRO_TABLA_ADMINISTRAR_PAISES :
            return {
                ...state,
                rex_filtro_tabla_paises : action.payload
            } 
        case SPIN_ACCIONES_ADMINISTRAR_PAISES :
            return {
                ...state,
                rex_spin_acciones_paises : action.payload
            }
        case SPIN_TABLE_ADMINISTRAR_PAISES :
            return {
                ...state,
                rex_spin_paises : action.payload
            }
        default:
            return state;
    }
}