import React, {useEffect, useState} from 'react'
import { Button, Table, Modal, Spin, Tooltip } from 'antd'
import {
    LoadingOutlined,
    DeleteOutlined,
} from '@ant-design/icons'
import Column from 'antd/lib/table/Column'
import Moment from 'moment'
import 'moment/locale/es'
import {useDispatch, useSelector} from "react-redux"
import {
    EliminarDataAdministrarPaisesReducer,
} from '../../../Redux/Actions/Administrar/Paises/Paises'

const TablaLista = (props) => {
    const seleccionarFila = props.seleccionarFila
    const rex_spin_paises = props.rex_spin_paises
    const rex_filtro_tabla_paises = props.rex_filtro_tabla_paises
    const vaciarFormulario = props.vaciarFormulario
    const pageTableTiposUsuarios = props.pageTableTiposUsuarios

    const dispatch = useDispatch()

    const abrirModal = (eliminar, paipais) => {
        Modal.confirm({
            title: 'Eliminar Usuario',
            content: `Deseas eliminar el pais ${paipais.painombre}?`,
            okText: 'Eliminar',
            cancelText: 'Cancelar',
        async onOk() {
            await dispatch(
                EliminarDataAdministrarPaisesReducer(eliminar)
            )
            vaciarFormulario()
        },
        onCancel() {
            vaciarFormulario()
        },
        })
    }

    const iconoCarga = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    )

    return(
        <Spin 
            spinning={rex_spin_paises} 
            indicator={iconoCarga}
        >
            <Table 
                className="table-administrarUsuarios pointer tablaAdministrarPaises"
                dataSource={rex_filtro_tabla_paises}
                scroll={{
                    y: 460,
                }}
                pagination={{
                    defaultCurrent: pageTableTiposUsuarios,
                    current: pageTableTiposUsuarios,
                }}
                onRow={seleccionarFila}
            >
                <Column 
                    title="Item"
                    dataIndex="index" 
                    key="index"
                    fixed="left"
                    width={60}
                    render={(value, item, index) => (pageTableTiposUsuarios - 1) * 10 + (index + 1)}
                />
                <Column 
                    title="Estado" 
                    dataIndex="estid" 
                    key="estid"
                    width={70} 
                    render={(estid) => (
                        <Tooltip title={estid === 1 ? 'Activo' : 'Inactivo'}>
                            <p className={estid === 1 ? 'activoColumnaUsuario' : 'inactivoColumnaUsuario'}></p>
                        </Tooltip>
                    )}
                />
                <Column 
                    title="Pais" 
                    dataIndex="painombre" 
                    key="painombre" 
                    width={130}
                /> 
                <Column 
                    title="Prefijo" 
                    dataIndex="paiprefijo"
                    key="paiprefijo"
                    width={80}
                />
                <Column 
                    title="Icono" 
                    dataIndex="paiicono"
                    key="paiicono"
                    width={130}
                    render={(paiicono) => (
                        <img src={paiicono} style={{width: '80px'}} />
                    )}
                />
                <Column 
                    title="Fecha de Creación" 
                    dataIndex="created_at" 
                    key="created_at"
                    width={180}
                    render={(created_at) => (
                        <>{Moment(created_at).format('D MMMM YYYY HH:mm')}</>
                    )}
                />
                <Column 
                    title="Acciones" 
                    dataIndex="paiid" 
                    key="paiid"
                    fixed="right"
                    width={100}
                    responsive={['sm']}
                    render={(paiid, paipais) => 
                        <Tooltip title="Eliminar">
                            <Button 
                                onClick={() => {
                                    abrirModal(paiid, paipais)
                                }}
                                danger
                                className="colorBotonEliminar"
                            >
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    }
                />
                <Column 
                    title="Acciones" 
                    dataIndex="paiid" 
                    key="paiid"
                    width={100}
                    responsive={['xs']}
                    render={(paiid, paipais) => 
                        <Tooltip title="Eliminar">
                            <Button 
                                onClick={() => {
                                    abrirModal(paiid, paipais)
                                }}
                                danger
                            >
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    }
                />
            </Table>
        </Spin>
    )
}

export default TablaLista