import React from 'react'
import '../../Estilos/Rutas/Descarga/Descarga.css'

const BtnDescarga = (props) => {

    const title = props.title
    const seleccionado = props.seleccionado
    const click = props.click

    return (
        <div 
            className={seleccionado == true ? 'Btn-Seleccionado-Descarga' : 'Btn-No-Seleccionado-Descarga'}
            onClick={click}
        >
            <div 
                className={seleccionado == true ?'W700-S14-H19-CFFFFFF' : 'W600-S14-H19-C706C64'}
                style={{
                    alignSelf: "center"
                }}
            >
                {title}
            </div>
        </div>
    )
}

export default BtnDescarga