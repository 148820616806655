import config from '../../../../config'
import { 
    OBTENER_DATA_TABLA_ADMINISTRAR_USUARIOS,
    OBTENER_DATA_TIPO_ADMINISTRAR_USUARIOS,
    OBTENER_DATA_EXCEL_ADMINISTRAR_USUARIOS,
    OBTENER_DATA_EXCEL_AUDITORIAS_ADMINISTRAR_USUARIOS,
    CREAR_DATA_ADMINISTRAR_USUARIOS,
    ACTUALIZAR_DATA_ADMINISTRAR_USUARIOS,
    ELIMINAR_DATA_ADMINISTRAR_USUARIOS,
    SPIN_TABLE_ADMINISTRAR_USUARIOS,
    ESTADO_ADMINISTRAR_USUARIOS,
} from "../../../../Constantes/Administrar/Usuarios/Usuarios"
import { estadoRequestReducer } from "../../EstadoRequest"
import { notification } from 'antd'

const notificacionServidor = (type, mensaje, errores) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning' && errores){
        notification['warning']({
            message: 'Advertencia',
            description:<>
                            {mensaje}<br/>
                            {errores.map((e) => <>- {e}<br/></>)}
                        </>,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: 'Error con el servidor. Vuelva a ingresar más tarde.',
        })
    }
}

export const ObtenerDataTablaAdministrarUsuariosReducer = (numeroPagina, txtBuscar) => async(dispatch, getState) => {
    
    dispatch({
        type: SPIN_TABLE_ADMINISTRAR_USUARIOS,
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/mostrar-usuarios?pag='+numeroPagina,
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                txtBuscar : txtBuscar
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_TABLA_ADMINISTRAR_USUARIOS,
                payload : data['datos']
            })
		}else{
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    })

    dispatch({
        type: SPIN_TABLE_ADMINISTRAR_USUARIOS,
        payload: false
    })

}

export const ObtenerDataExcelUsuariosAdministrarUsuariosReducer = () => async(dispatch, getState) => {

    dispatch({
        type: OBTENER_DATA_EXCEL_ADMINISTRAR_USUARIOS,
        payload:[]
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/obtener-usuarios-excel',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            let descargableBigData = []
            descargableBigData = await LimpiarArrayDescargarReducer(data.datos)
            dispatch({
                type: OBTENER_DATA_EXCEL_ADMINISTRAR_USUARIOS,
                payload : descargableBigData
            })
		}else{
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    });

    return true;

}

export const ObtenerDataExcelAuditoriasAdministrarUsuariosReducer = (fechaIngresoLogin) => async(dispatch, getState) => {

    let respuesta;

    dispatch({
        type: OBTENER_DATA_EXCEL_ADMINISTRAR_USUARIOS,
        payload:[]
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/obtener-auditorias-usuarios-excel',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                fechaIngresoLogin: fechaIngresoLogin,
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                let descargableBigData = []
                descargableBigData = await LimpiarArrayDescargarReducer(data.datos)
                dispatch({
                    type: OBTENER_DATA_EXCEL_AUDITORIAS_ADMINISTRAR_USUARIOS,
                    payload : descargableBigData
                })
                respuesta = true;
            }else{
                respuesta = false;
                notificacionServidor('warning', data.mensaje)
            }
		}else{
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    });

    return respuesta;

}

export const CrearDataAdministrarUsuariosReducer = (nombreUsuario, apellidoUsuario, correoCorporativoUsuario, correoPersonalUsuario, contraseniaUsuario, celularUsuario, tipoUsuario, fechaInicioUsuario, fechaFinUsuario, paisUsuario, estadoUsuario, correoBienvenidaUsuario, numeroPagina, txtBuscar) => async(dispatch, getState) => {
    let respuesta = true

    dispatch({
        type: CREAR_DATA_ADMINISTRAR_USUARIOS,
        payload: false
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/crear-usuarios',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                nombreUsuario : nombreUsuario,
                apellidoUsuario : apellidoUsuario, 
                correoCorporativoUsuario : correoCorporativoUsuario, 
                correoPersonalUsuario : correoPersonalUsuario, 
                contraseniaUsuario : contraseniaUsuario, 
                celularUsuario : celularUsuario, 
                tipoUsuario : tipoUsuario,
                fechaInicioUsuario : fechaInicioUsuario,
                fechaFinUsuario : fechaFinUsuario,
                paisUsuario : paisUsuario,
                estadoUsuario : estadoUsuario,
                correoBienvenidaUsuario : correoBienvenidaUsuario
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                dispatch({
                    type: CREAR_DATA_ADMINISTRAR_USUARIOS,
                    payload: true
                })
                notificacionServidor('success',data.mensaje)
                await dispatch(ObtenerDataTablaAdministrarUsuariosReducer(numeroPagina, txtBuscar))
                respuesta = data.respuesta
            }else{
                dispatch({
                    type: CREAR_DATA_ADMINISTRAR_USUARIOS,
                    payload: false
                })
                notificacionServidor('warning',data.mensaje)
                respuesta = data.respuesta
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
            respuesta = data.respuesta
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
        respuesta = false
    })

    return respuesta
}

export const ActualizarDataAdministrarUsuariosReducer = (idUsuario, nombreUsuario, apellidoUsuario, correoCorporativoUsuario, correoPersonalUsuario, contraseniaUsuario, celularUsuario, tipoUsuario, fechaInicioUsuario, fechaFinUsuario, paisUsuario, estadoUsuario, correoBienvenidaUsuario, numeroPagina, txtBuscar) => async(dispatch, getState) => {

    let respuesta = true

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/actualizar-usuarios',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                idUsuario : idUsuario,
                nombreUsuario : nombreUsuario,
                apellidoUsuario : apellidoUsuario, 
                correoCorporativoUsuario : correoCorporativoUsuario, 
                correoPersonalUsuario : correoPersonalUsuario, 
                contraseniaUsuario : contraseniaUsuario, 
                celularUsuario : celularUsuario, 
                tipoUsuario : tipoUsuario,
                fechaInicioUsuario : fechaInicioUsuario,
                fechaFinUsuario : fechaFinUsuario,
                paisUsuario : paisUsuario,
                estadoUsuario : estadoUsuario, 
                correoBienvenidaUsuario : correoBienvenidaUsuario
            }),
      	}
    )
    .then(async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                dispatch({
                    type: ACTUALIZAR_DATA_ADMINISTRAR_USUARIOS,
                    payload: true
                })
                notificacionServidor('success',data.mensaje)
                await dispatch(ObtenerDataTablaAdministrarUsuariosReducer(numeroPagina, txtBuscar))
                respuesta = data.respuesta
            }else{
                notificacionServidor('warning',data.mensaje)
                dispatch({
                    type: ACTUALIZAR_DATA_ADMINISTRAR_USUARIOS,
                    payload: false
                })
                respuesta = data.respuesta
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
            respuesta = data.respuesta
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
        respuesta = false
    })

    return respuesta
}

export const EliminarDataAdministrarUsuariosReducer = (idUsuario, numeroPagina, txtBuscar) => async(dispatch, getState) => {

    let respuesta = true

    dispatch({
        type: ELIMINAR_DATA_ADMINISTRAR_USUARIOS,
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/eliminar-usuarios',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                idUsuario : idUsuario
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                notificacionServidor('success',data.mensaje)
                await dispatch(ObtenerDataTablaAdministrarUsuariosReducer(numeroPagina, txtBuscar))
                respuesta = data.respuesta
            }else{
                notificacionServidor('warning',data.mensaje)
                respuesta = data.respuesta
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
            respuesta = data.respuesta
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
        respuesta = false
    })

    return respuesta
}

export const ObtenerListaTiposUsuariosReducer = () => async (dispatch, getState) => {

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrador/mostrar/tipos-usuarios',
		{
			mode:'cors',
			method: 'POST',
			body: JSON.stringify({}),
			headers: headerFetch
      	}
    )
    .then(async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_TIPO_ADMINISTRAR_USUARIOS,
                payload : data.datos
            })
		}else{
        }
    }).catch((error)=> {
        notificacionServidor('error')
    })

}

export const LimpiarArrayDescargarReducer = async (facturassi) => {

    await facturassi[0]['data'].map((dato, posicion) => {
        facturassi[0]['data'][posicion].map((dat) => {
        dat.value = dat.value == null ?"" :dat.value
      })
    })
  
    return facturassi
}

export const CambiarEstadoUsuariosReducer = (boton, desabilitar, checkbox, fecha, contrasenia) => async (dispatch, getState) => {
    const estadoAnterior = getState().administrarUsuarios
    dispatch({
        type: ESTADO_ADMINISTRAR_USUARIOS,
        payload: {
            boton : boton !== null
                    ? boton 
                    : estadoAnterior.rex_estado_boton_usuarios,
            desabilitar : desabilitar !== null
                    ? desabilitar 
                    : estadoAnterior.rex_estado_desabilitar_usuarios,
            checkbox : checkbox !== null
                    ? checkbox 
                    : estadoAnterior.rex_estado_checkbox_usuarios,
            fecha : fecha !== null
                    ? fecha 
                    : estadoAnterior.rex_estado_fecha_usuarios,
            contrasenia : contrasenia !== null
                    ? contrasenia 
                    : estadoAnterior.rex_estado_contrasenia_usuarios,
        }
    })
}