import React, {useEffect, useRef, useState} from 'react'
import ReactExport from "react-data-export"
import { LoadingOutlined, ExclamationCircleOutlined, DeleteOutlined, NotificationOutlined } from '@ant-design/icons'
import { Button, Col, Pagination, Row, Space, Table, DatePicker, Select, notification, Modal, Spin, Tooltip } from 'antd'
import Search from 'antd/lib/input/Search'
import Column from 'antd/lib/table/Column'
import Title from 'antd/lib/typography/Title'
import BotonesRutas from '../../../Componentes/Administrar/BotonesRutas'
import {useDispatch, useSelector} from "react-redux"
import IdleTimer from 'react-idle-timer'
import '../../../Estilos/Rutas/Administrar/ControlArchivos/ControlArchivos.css'
import Moment from 'moment'
import 'moment/locale/es'
import locale from 'antd/es/date-picker/locale/ca_ES'
import {
    ObtenerDataTablaAdministrarControlArchivosReducer,
    ObtenerDataExcelAdministrarControlArchivosReducer,
    EliminarDataAdministrarControlArchivosReducer,
    ObtenerDataTiposCargasArchivosReducer,
    ObtenerUrlArchivoMicroServicioReducer,
} from '../../../Redux/Actions/Administrar/ControlArchivos/ControlArchivos'

const ControlArchivos = () => {
    
    const ExcelFile = ReactExport.ExcelFile
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
    const { RangePicker } = DatePicker
    const { Option } = Select
    const { confirm, info } = Modal
    const dispatch = useDispatch()

    const [numeroPagina, setNumeroPagina] = useState(1)
    const [txtBuscar, setTxtBuscar] = useState()
    const [tipoArchivo, setTipoArchivo] = useState()
    const [fechaInicio, setFechaInicio] = useState()
    const [fechaFin, setFechaFin] = useState()
    const [errorFecha, setErrorFecha] = useState("")
    const [captarOnchangeBuscar, setCaptarOnchangeBuscar] = useState(false)

    const {
        rex_data_tabla_control_archivos,
        rex_data_tipo_archivo_control_archivos,
        rex_data_excel_control_archivos,
        rex_spin_table_control_archivos
    } = useSelector(({administrarControlArchivos}) => administrarControlArchivos)

    useEffect(() => {
        dispatch(ObtenerDataTablaAdministrarControlArchivosReducer(numeroPagina))
        dispatch(ObtenerDataTiposCargasArchivosReducer())
    }, [])

    const notificacionPrecaucionExcel = (type) => {
        if(type === 'warning'){
            notification[type]({
                message: 'Exportación en Excel',
                description:
                    'Solo se puede descargar la información de los últimos 3 meses.',
              })
        }else if(type === 'error'){
            notification[type]({
                message: 'Exportación en Excel',
                description:
                    'Solo se permite descargar la información en un rango de 3 meses.',
            })
        }
    }

    const paginacionTabla = (paginateBuscar) => {
        setNumeroPagina(paginateBuscar)
        dispatch(ObtenerDataTablaAdministrarControlArchivosReducer(paginateBuscar, txtBuscar, tipoArchivo, fechaInicio, fechaFin))
    }

    const tipoValor = (selectBuscar) => {
        setTipoArchivo(selectBuscar)
        setNumeroPagina(1)
        dispatch(ObtenerDataTablaAdministrarControlArchivosReducer(1, txtBuscar, selectBuscar, fechaInicio, fechaFin))
    }

    const fechaValor = (rangerBuscar) => {
        const fInicio = rangerBuscar ? Moment(rangerBuscar[0]._d).format('YYYY-MM-DD') : ""
        const fFin = rangerBuscar ? Moment(rangerBuscar[1]._d).format('YYYY-MM-DD') : ""
        setFechaInicio(fInicio)
        setFechaFin(fFin)
        dispatch(ObtenerDataTablaAdministrarControlArchivosReducer(numeroPagina, txtBuscar, tipoArchivo, fInicio, fFin))
    }

    const exportarExcel = async () => {
        const fechaFinExcel = fechaFin ? Moment(fechaFin) : Moment(1)
        const fechaInicioExcel = fechaInicio ? Moment(fechaInicio) : Moment(1)
        const fechaRango = fechaFinExcel.diff(fechaInicioExcel, 'months')
        if(fechaRango <= 3){
            notificacionPrecaucionExcel('warning')
            await dispatch(ObtenerDataExcelAdministrarControlArchivosReducer(txtBuscar, tipoArchivo, fechaInicio, fechaFin))
            refBtnDescargar.current.click()
        }else if(fechaRango > 3){
            notificacionPrecaucionExcel('error')
            setErrorFecha("error-fecha")
        }
    }

    const refBtnDescargar = useRef(null)

    const iconoCarga = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    )

    const abrirModal = (eliminar, archivo) => {
        confirm({
            title: 'Eliminar Archivo Subido',
            icon: <ExclamationCircleOutlined />,
            content: `Deseas eliminar el archivo ${archivo.usuusuario}?`,
            okText: 'Eliminar',
            cancelText: 'Cancelar',
        async onOk() {
            await dispatch(
                EliminarDataAdministrarControlArchivosReducer(eliminar, numeroPagina, txtBuscar, tipoArchivo, fechaInicio, fechaFin)
            )
        },
            onCancel() {},
        })
    }

    const mensajeNotificacion = (carMensaje) => {
        return(
            <>
            <p>{carMensaje.MENSAJE}</p>
            {
                carMensaje.FECHA_NO_REGISTRADA
                &&  Array.isArray(carMensaje.FECHA_NO_REGISTRADA)
                    ?   carMensaje.FECHA_NO_REGISTRADA.length > 0
                        &&  <>
                            <p style={{marginBottom: '5px', fontWeight: 'bold'}}>Fecha No Registrada</p>
                            <ul>
                            {carMensaje.FECHA_NO_REGISTRADA.map((fnr, index) => 
                                <li key={index}>{fnr}</li>
                            )}
                            </ul>
                            </>
                    :   carMensaje.FECHA_NO_REGISTRADA
                        &&  <>
                            <p style={{marginBottom: '5px', fontWeight: 'bold'}}>Fecha No Registrada</p>
                            <p>{carMensaje.FECHA_NO_REGISTRADA}</p>
                            </>
            }
            {
                carMensaje.CLIENTES_NO_ENCONTRADOS
                &&  carMensaje.CLIENTES_NO_ENCONTRADOS.length > 0
                    &&  <>
                        <p style={{marginBottom: '5px', fontWeight: 'bold'}}>Clientes No Encontrados</p>
                        <ul>
                        {carMensaje.CLIENTES_NO_ENCONTRADOS.map((cne, index) => 
                            <li key={index}>{cne.codigo+" en la linea: "+cne.linea}</li>
                        )}
                        </ul>
                        </>
            }
            {
                carMensaje.PRODUCTOS_NO_ENCONTRADOS
                &&  carMensaje.PRODUCTOS_NO_ENCONTRADOS.length > 0
                    &&  <>
                        <p style={{marginBottom: '5px', fontWeight: 'bold'}}>Productos No Encontrados</p>
                        <ul>
                        {carMensaje.PRODUCTOS_NO_ENCONTRADOS.map((pne, index) => 
                            <li key={index}>{pne.codigo+" en la linea: "+pne.linea}</li>
                        )}
                        </ul>
                        </>
            }
            {
                carMensaje.SUBSIDIOS_NO_ENCONTRADOS
                &&  carMensaje.SUBSIDIOS_NO_ENCONTRADOS.length > 0
                    &&  <>
                        <p style={{marginBottom: '5px', fontWeight: 'bold'}}>Subsidios No Encontrados</p>
                        <ul>
                        {carMensaje.SUBSIDIOS_NO_ENCONTRADOS.map((sne, index) => 
                            <li key={index}>{sne.codigo+" en la linea: "+sne.linea}</li>
                        )}
                        </ul>
                        </>
            }
            {
                carMensaje.CLIENTES_SO_NO_ENCONTRADOS
                &&  carMensaje.CLIENTES_SO_NO_ENCONTRADOS.length > 0
                    &&  <>
                        <p style={{marginBottom: '5px', fontWeight: 'bold'}}>Clientes SO No Encontrados</p>
                        <ul>
                        {carMensaje.CLIENTES_SO_NO_ENCONTRADOS.map((csne, index) => 
                            <li key={index}>{csne.codigo+" en la linea: "+csne.linea}</li>
                        )}
                        </ul>
                        </>
            }
            </>
        )
    }
    
    const abrirModalNotificacion = (notificacion) => {
        const carMensaje = JSON.parse(notificacion.carmensaje)
        info({
            title: 'Notificacion de Archivo Subido',
            width: '500px',
            className: 'modalNotificacionInfo',
            content: notificacion.carmensaje
                        ?   mensajeNotificacion(carMensaje)
                        :   "No hay ningun mensaje",
            okText: 'Cerrar',
            onOk() {},
        })
    }

    return (
        <>
            <div 
                className="contenedorAdministrarModulo"
            >
                <Title 
                    className="title-controlArchivos"
                >
                    ADMINISTRADOR
                </Title>
                <Row 
                    className="row-button-controlArchivos"
                    style={{ 
                        rowGap:'25px',
                        padding: '0 15px 0 15px', 
                        marginTop: '25px',
                    }}
                >
                    <Col 
                        md={20}
                        xs={24}
                    >
                        <BotonesRutas 
                            ruta="control" 
                        />
                    </Col>
                    <Col 
                        md={{
                            span: 3,
                            offset: 1
                        }}
                        xs={24}
                        className="colExcelAdministrarControlArchivos"
                    >
                        <ExcelFile 
                            filename="Subsidios - Archivos Subidos 2022 Enero - 2022 Junio" 
                            element={
                                <button 
                                    ref={refBtnDescargar}
                                    style={{display: "none"}}
                                >
                                    Descargar
                                </button>
                                }
                            >
                            <ExcelSheet 
                                dataSet={rex_data_excel_control_archivos} 
                                name="Archivos Subidos" 
                            />
                        </ExcelFile>

                        <Button 
                            className="button-controlArchivos" 
                            onClick={exportarExcel}
                            shape="round"
                        >
                            Exportar
                        </Button>
                    </Col>
                </Row>
                <Row 
                    className="row-button-controlArchivos" 
                    style={{marginBottom:'25px', rowGap:'25px', marginTop: '5px',}}
                >
                    <Col 
                        xl={{
                            span: 8
                        }}
                        lg={{
                            span: 8,
                            order: 1
                        }}
                        md={11}
                        sm={{
                            span: 14,
                            order: 3
                        }}
                        xs={24}
                    >
                        <IdleTimer
                            onIdle={() => {
                                if(captarOnchangeBuscar){
                                    dispatch(
                                        ObtenerDataTablaAdministrarControlArchivosReducer(numeroPagina, txtBuscar, tipoArchivo, fechaInicio, fechaFin)
                                    )
                                    setCaptarOnchangeBuscar(false)
                                }
                            }}
                            events={['keydown']}
                            timeout={1000 * 1.5}
                        >
                            <Search
                                placeholder="Buscar"
                                allowClear
                                className="search-controlArchivos"
                                onChange={(e) => {
                                    setTxtBuscar(e.target.value)
                                    setNumeroPagina(1)
                                    setCaptarOnchangeBuscar(true)
                                }}
                            />
                        </IdleTimer>
                    </Col>
                    <Col 
                        xl={{
                            span: 5,
                            offset: 1
                        }}
                        lg={{
                            span: 5,
                            order: 2,
                            offset: 0
                        }}
                        md={11}
                        sm={{
                            span: 12,
                            order: 1
                        }}
                        xs={24}
                        className="colTipoControlArchivos"
                    >
                        <Select 
                            size="large" 
                            onChange={tipoValor} 
                            defaultValue="Seleccione el tipo de carga" 
                            className="tipoControlArchivo"
                        >
                            <Option>Seleccione el tipo de archivo</Option>
                            {
                                rex_data_tipo_archivo_control_archivos.map((data) => (
                                    <Option 
                                        key={data.tcaid} 
                                        value={data.tcaid}
                                    >
                                        {data.tcanombre}
                                    </Option>
                                ))
                            }
                        </Select>
                    </Col>
                    <Col 
                        xl={{
                            span: 6,
                        }}
                        lg={{
                            span: 6,
                            order: 3
                        }}
                        sm={{
                            span: 12,
                            order: 2
                        }}
                        xs={24}
                        style={{margin:'auto'}}
                    >
                        <Space 
                            direction="vertical"
                        >
                            <RangePicker 
                                locale={locale}
                                onChange={fechaValor} 
                                className={`fechaControlArchivos ${errorFecha ? errorFecha : ""}`}
                                placeholder={["Fecha Inicial", "Fecha Final"]}

                            />
                        </Space>
                    </Col>
                    <Col 
                        xl={{
                            span: 4,
                        }}
                        lg={{
                            span: 5,
                            offset: 0
                        }}
                        md={{
                            span: 6,
                            offset: 7
                        }}
                        sm={{
                            span: 10,
                            order: 4
                        }}
                        xs={24}
                        style={{
                            display:'flex', 
                            alignItems:'center', 
                            justifyContent:'end'
                        }}
                    >
                        <Pagination 
                            current={numeroPagina}
                            className="pagination-controlArchivo" 
                            showTotal={(total) => {
                                return(
                                    rex_data_tabla_control_archivos.from 
                                    ? rex_data_tabla_control_archivos.from + " - " + rex_data_tabla_control_archivos.to + " de " + total
                                    : <>0 - 0 de 0</>
                                )
                            }} 
                            size="small"
                            total={rex_data_tabla_control_archivos.total} 
                            onChange={paginacionTabla}
                        />
                    </Col>
                </Row>
                
                <Spin 
                    spinning={rex_spin_table_control_archivos} 
                    indicator={iconoCarga}
                >
                    <Table 
                        className="table-controlArchivos"
                        dataSource={rex_data_tabla_control_archivos.data} 
                        pagination={false}
                        tableLayout="auto"
                        scroll={{
                            x: 1300,
                        }}
                    >
                        <Column
                            title="Item"
                            dataIndex="index" 
                            key="index"
                            width={50}
                            render={(value, item, index) => (numeroPagina - 1) * 10 + (index + 1)}
                        />
                        <Column 
                            title="Tipo Carga" 
                            dataIndex="tcanombre" 
                            key="tcanombre" 
                            width={200}
                        />
                        <Column 
                            title="Nombre Archivo" 
                            dataIndex="carnombre" 
                            key="carnombre" 
                            width={450}
                            render={(carnombre, car) => {

                                return car.carobservacion == 'S3'
                                ?   <div
                                        onClick={async() => {
                                            const fileUrl = await dispatch(ObtenerUrlArchivoMicroServicioReducer(car.carurl))
                                            // console.log(fileUrl)
                                            if(fileUrl){
                                                const link = document.createElement('a')
                                                link.href = fileUrl
                                                link.download = car.carnombre
                                                link.click()
                                            }
                                        }}
                                        style={{
                                            display:'inline-block'
                                        }}
                                    >
                                    <a>{car.carnombre}</a>
                                </div>
                                : <a target="_blank" href={car.carurl}>{carnombre}</a>
                            }} 
                        />
                        <Column 
                            title="Extension" 
                            dataIndex="carextension" 
                            key="carextension" 
                            width={150}
                        />
                        <Column 
                            title="Usuario" 
                            dataIndex="usuusuario" 
                            key="usuusuario" 
                            width={300}
                        />
                        <Column 
                            title="Fecha de Subida" 
                            dataIndex="created_at" 
                            key="created_at"
                            width={200}
                            render={(created_at) => (
                                <>{Moment(created_at).utc().format('D MMMM YYYY HH:mm')}</>
                            )}
                        /> 
                        <Column 
                            title="Acciones" 
                            dataIndex="carid" 
                            key="carid" 
                            fixed="right"
                            responsive={['sm']}
                            render={(carid, car) => 
                                <div 
                                    style={{
                                        display: 'flex', 
                                        justifyContent: 'center',
                                        columnGap: '10px',
                                    }}
                                >
                                    <Tooltip title="Notificacion">
                                        <Button 
                                            type="primary"
                                            danger={
                                                car.carmensaje
                                                ? true
                                                : false
                                            }
                                            onClick={() => {
                                                abrirModalNotificacion(car)
                                            }}
                                        >
                                            <NotificationOutlined />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Eliminar">
                                        <Button 
                                            onClick={() => {
                                                abrirModal(carid, car)
                                            }}
                                            danger
                                            className="colorBotonEliminar"
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </Tooltip>
                                </div>
                            }
                        />
                        <Column 
                            title="Acciones" 
                            dataIndex="carid" 
                            key="carid" 
                            responsive={['xs']}
                            render={(carid, car) => 
                                <div 
                                    style={{
                                        display: 'flex', 
                                        justifyContent: 'center',
                                        columnGap: '10px',
                                    }}
                                >
                                    <Tooltip title="Notificacion">
                                        <Button 
                                            type="primary"
                                            danger={
                                                car.carmensaje
                                                ? true
                                                : false
                                            }
                                            onClick={() => {
                                                abrirModalNotificacion(car)
                                            }}
                                        >
                                            <NotificationOutlined />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Eliminar">
                                        <Button 
                                            onClick={() => {
                                                abrirModal(carid, car)
                                            }}
                                            danger
                                            className="colorBotonEliminar"
                                        >
                                            <DeleteOutlined />
                                        </Button>
                                    </Tooltip>
                                </div>
                            }
                        />  
                    </Table>
                </Spin>
            </div>
        </>
    )
}

export default ControlArchivos