// DESCARGA
export const SELECT_DOWNLOAD_DESCARGA = 'SELECT_DOWNLOAD_DESCARGA'
export const SELECT_LIST_DOWNLOAD_DESCARGA = 'SELECT_LIST_DOWNLOAD_DESCARGA'
export const FILTRO_DATA_TERRITORIO_NOTA_CREDITO = 'FILTRO_DATA_TERRITORIO_NOTA_CREDITO'
export const FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO = 'FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO'
export const DATA_SELL_OUT = 'DATA_SELL_OUT'
export const CHECK_DATA_ZONA = 'CHECK_DATA_ZONA'
export const CHECK_DATA_ZONA_TODO = 'CHECK_DATA_ZONA_TODO'
export const CHECK_DATA_TERRITORIO = 'CHECK_DATA_TERRITORIO'
export const CHECK_DATA_TERRITORIO_TODO = 'CHECK_DATA_TERRITORIO_TODO'
export const CHECK_DATA_DISTRIBUIDORA = 'CHECK_DATA_DISTRIBUIDORA'
export const CHECK_DATA_DISTRIBUIDORA_TODO = 'CHECK_DATA_DISTRIBUIDORA_TODO'
export const LIST_DATA_ZONA_NOTA_CREDITO = 'LIST_DATA_ZONA_NOTA_CREDITO'
export const LIST_DATA_TERRITORIO_NOTA_CREDITO = 'LIST_DATA_TERRITORIO_NOTA_CREDITO'