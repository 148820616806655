import config from '../../../../config'
import configMicroServices from '../../../../configMicroServices';
import { 
    OBTENER_DATA_TABLA_ADMINISTRAR_CONTROL_ARCHIVOS,
    OBTENER_DATA_TIPO_ARCHIVO_ADMINISTRAR_CONTROL_ARCHIVOS,
    OBTENER_DATA_EXCEL_ADMINISTRAR_CONTROL_ARCHIVOS,
    ELIMINAR_DATA_ADMINISTRAR_CONTROL_ARCHIVOS,
    SPIN_TABLE_ADMINISTRAR_CONTROL_ARCHIVOS,
} from "../../../../Constantes/Administrar/ControlArchivos/ControlArchivos"
import { estadoRequestReducer } from "../../EstadoRequest"
import { notification } from 'antd';

const notificacionServidor = (type, mensaje, errores) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning' && errores){
        notification['warning']({
            message: 'Advertencia',
            description:<>
                            {mensaje}<br/>
                            {errores.map((e) => <>- {e}<br/></>)}
                        </>,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: 'Error con el servidor. Vuelva a ingresar más tarde.',
        })
    }
}

export const ObtenerDataTablaAdministrarControlArchivosReducer = (numeroPagina, txtBuscar, tipoArchivo, fechaInicio, fechaFin) => async(dispatch, getState) => {
    
    dispatch({
        type: SPIN_TABLE_ADMINISTRAR_CONTROL_ARCHIVOS,
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/mostrar-archivos-subidos?pag='+numeroPagina,
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                txtBuscar : txtBuscar,
                tipoArchivo : tipoArchivo,
                fechaInicio : fechaInicio,
                fechaFin : fechaFin,
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_TABLA_ADMINISTRAR_CONTROL_ARCHIVOS,
                payload : data['datos']
            })
		}else{
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    });

    dispatch({
        type: SPIN_TABLE_ADMINISTRAR_CONTROL_ARCHIVOS,
        payload: false
    })

}

export const ObtenerUrlArchivoMicroServicioReducer = (carurl) => async(dispatch, getState) => {

    let url

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(configMicroServices.api_js+'file-control/download-file',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                req_name : carurl
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            url = data.url
		}else{
            notification.warning({
                message: 'Notificación',
                description: data.message,
                placement : 'topRight',
            })
        }
    })
    .catch((error)=> {
        console.log(error)
    });

    return url
}

export const ObtenerDataTiposCargasArchivosReducer = () => async(dispatch, getState) => {

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/mostrar-tipos-cargas-archivos',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_TIPO_ARCHIVO_ADMINISTRAR_CONTROL_ARCHIVOS,
                payload : data['datos']
            })
		}else{
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    });

}

export const ObtenerDataExcelAdministrarControlArchivosReducer = (txtBuscar, tipoArchivo, fechaInicio, fechaFin) => async(dispatch, getState) => {

    dispatch({
        type: OBTENER_DATA_EXCEL_ADMINISTRAR_CONTROL_ARCHIVOS,
        payload:[]
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/obtener-datos-excel',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                txtBuscar : txtBuscar, 
                tipoArchivo : tipoArchivo,
                fechaInicio : fechaInicio,
                fechaFin : fechaFin
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            let descargableBigData = []
            descargableBigData = await LimpiarArrayDescargarReducer(data.datos)
            dispatch({
                type: OBTENER_DATA_EXCEL_ADMINISTRAR_CONTROL_ARCHIVOS,
                payload : descargableBigData
            })
		}else{
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    });

    return true;

}

export const EliminarDataAdministrarControlArchivosReducer = (idArchivoSubido, numeroPagina, txtBuscar, tipoArchivo, fechaInicio, fechaFin) => async(dispatch, getState) => {

    dispatch({
        type: ELIMINAR_DATA_ADMINISTRAR_CONTROL_ARCHIVOS,
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/eliminar-archivos-subidos',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                idArchivoSubido : idArchivoSubido
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                notificacionServidor('success',data.mensaje)
                await dispatch(ObtenerDataTablaAdministrarControlArchivosReducer(numeroPagina, txtBuscar, tipoArchivo, fechaInicio, fechaFin))
            }else{
                notificacionServidor('warning',data.mensaje)
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    })
}

export const LimpiarArrayDescargarReducer = async (facturassi) => {

    await facturassi[0]['data'].map((dato, posicion) => {
        facturassi[0]['data'][posicion].map((dat) => {
        dat.value = dat.value == null ?"" :dat.value
      })
    })
  
    return facturassi
}