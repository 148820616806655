import { 
    OBTENER_DATA_TABLA_ADMINISTRAR_USUARIOS,
    OBTENER_DATA_TIPO_ADMINISTRAR_USUARIOS,
    OBTENER_DATA_EXCEL_ADMINISTRAR_USUARIOS,
    OBTENER_DATA_EXCEL_AUDITORIAS_ADMINISTRAR_USUARIOS,
    CREAR_DATA_ADMINISTRAR_USUARIOS,
    ACTUALIZAR_DATA_ADMINISTRAR_USUARIOS,
    ELIMINAR_DATA_ADMINISTRAR_USUARIOS,
    ESTADO_ADMINISTRAR_USUARIOS,
    SPIN_TABLE_ADMINISTRAR_USUARIOS,
} from "../../../../Constantes/Administrar/Usuarios/Usuarios";

const INIT_STATE = {
    rex_data_tabla_usuarios : [],
    rex_data_tipo_usuarios: [],
    rex_data_excel_usuarios: [],
    rex_data_excel_auditorias_usuarios: [],
    rex_data_crear_usuarios: [],
    rex_data_actualizar_usuarios: [],
    rex_data_eliminar_usuarios: [],
    rex_spin_table_usuarios: [],
    rex_estado_boton_usuarios: true,
    rex_estado_desabilitar_usuarios: false,
    rex_estado_checkbox_usuarios: false,
    rex_estado_fecha_usuarios: false,
    rex_estado_contrasenia_usuarios: true,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_TABLA_ADMINISTRAR_USUARIOS :
            return {
                ...state,
                rex_data_tabla_usuarios : action.payload
            }
        case OBTENER_DATA_TIPO_ADMINISTRAR_USUARIOS :
            return {
                ...state,
                rex_data_tipo_usuarios : action.payload
            }
        case OBTENER_DATA_EXCEL_ADMINISTRAR_USUARIOS :
            return {
                ...state,
                rex_data_excel_usuarios : action.payload
            }
        case OBTENER_DATA_EXCEL_AUDITORIAS_ADMINISTRAR_USUARIOS :
            return {
                ...state,
                rex_data_excel_auditorias_usuarios : action.payload
            }
        case CREAR_DATA_ADMINISTRAR_USUARIOS :
            return {
                ...state,
                rex_data_crear_usuarios : action.payload
            }
        case ACTUALIZAR_DATA_ADMINISTRAR_USUARIOS :
            return {
                ...state,
                rex_data_actualizar_usuarios : action.payload
            }
        case ELIMINAR_DATA_ADMINISTRAR_USUARIOS :
            return {
                ...state,
                rex_data_eliminar_usuarios : action.payload
            }
        case SPIN_TABLE_ADMINISTRAR_USUARIOS :
            return {
                ...state,
                rex_spin_table_usuarios : action.payload
            }
        case ESTADO_ADMINISTRAR_USUARIOS :
            return {
                ...state,
                rex_estado_boton_usuarios : action.payload.boton,
                rex_estado_desabilitar_usuarios : action.payload.desabilitar,
                rex_estado_checkbox_usuarios : action.payload.checkbox,
                rex_estado_fecha_usuarios : action.payload.fecha,
                rex_estado_contrasenia_usuarios : action.payload.contrasenia,
            }
        default:
            return state;
    }
}