import React, {useState} from 'react'
import '../../Estilos/Componentes/CargaArchivos/NotiCarga.css'
import IconoFlechaDerecha from '../../Assets/Imagenes/Iconos/flecha-derecha.svg'
import IconoCampanaAzul from '../../Assets/Imagenes/Iconos/campanaNotificacion.png'
import IconoCampana from '../../Assets/Imagenes/Iconos/CargaArchivos/campana.svg'
import IconoCerrarNaranja from '../../Assets/Imagenes/Iconos/cerrarNaranja.png'
import IconoFlechaIzquierdaNaranja from '../../Assets/Imagenes/Iconos/flechaIzquierdaNaranja.png'
import IconoMasAzul from '../../Assets/Imagenes/Iconos/masAzul.png'

const NotiCarga = (props) => {

    const [notificacionesCompleta, setNotificacionesCompleta] = useState(false)
    const ComunesTipoDisenio = props.ComunesTipoDisenio

    return (
        <div 
            id={
                notificacionesCompleta == true
                ?"Contenedor-Animacion-Notificaciones-Carga"
                :"Contenedor-Notificaciones-Carga"
            }
            className={
                ComunesTipoDisenio == "Light"
                ?"CFFFFFF"
                :"C242526"
            }
            // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            style={
                notificacionesCompleta == true
                ?ComunesTipoDisenio == "Light" 
                    ? {width:"100%", boxShadow: "-8px 4px 15px -5px #D8DFE9" } 
                    : {width:"100%", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}
                :ComunesTipoDisenio == "Light" 
                    ? {marginTop:'0px', boxShadow: "-8px 4px 15px -5px #D8DFE9"} 
                    : {marginTop:'0px', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}
            }
        >
            <div id="Contenedor-Titulo-Notificaciones-Carga" style={notificacionesCompleta == true?{justifyContent: "left", marginBottom:'5px'}:{}}>
                <img 
                    // style={notificacionesCompleta==true?{}:{}}
                    onClick={() => setNotificacionesCompleta(!notificacionesCompleta)}
                    id={
                        ComunesTipoDisenio == "Light" 
                        ?"Icono-Flecha-Notificaciones-Carga-Light"
                        :"Icono-Flecha-Notificaciones-Carga" 
                    }
                    src={IconoFlechaIzquierdaNaranja} 
                />
                <img onClick={() => console.log(props.notificaciones_cargaarchivos)}  id="Icono-Campana-Notificaciones-Carga" src={IconoCampanaAzul} />
                <span 
                    className={
                        ComunesTipoDisenio == "Light" 
                        ?"Wbold-S14-H19-C004FB8"
                        :"Wbold-S14-H19-Ce4e6eb"
                    }
                >Notificaciones</span>
            </div>

            <div id="Contenedor-Cuerpo-Notificaciones-Carga" style={notificacionesCompleta == true?{padding:'10px 0 0 0'}:{}}>
                {
                    props.notificaciones_cargaarchivos.map((not, posicion) => {
                        return (
                            not.MENSAJE
                            ?<div className="Notificaciones-Carga" style={notificacionesCompleta == true?{paddingLeft:'35px', paddingRight:'80px'}:{}}>
                                <div 
                                    className={
                                        ComunesTipoDisenio == "Light" 
                                        ?not.RESPUESTA == true
                                            ?"W600-S14-H19-C004FB8 "
                                            :"W600-S14-H19-C004FB8 COFF3742"
                                        :"W600-S14-H19-Ce4e6eb"
                                    }
                                    style={{paddingBottom:'5px'}}
                                >
                                    {/* Notificación {posicion+1} */}
                                    {not.TITULO}
                                </div>
                                
                                <div style={{display:'flex'}} >
                                    {
                                        not.MENSAJE
                                        ?<div 
                                            className={
                                                ComunesTipoDisenio == "Light" 
                                                ?not.RESPUESTA == true
                                                    ?"Wnormal-S14-H19-C706C64"
                                                    :"Wnormal-S14-H19-C706C64 COFF3742"
                                                :"Wnormal-S14-H19-Ce4e6eb"
                                            }

                                            style={{
                                                width:'90%',
                                                paddingRight:'10px'
                                            }}
                                        >

                                            <div className="content" dangerouslySetInnerHTML={{__html: not.MENSAJE}}></div>
                                            <br/><br/>
                                            {/* <div>Ver mas </div> */}

                                            {
                                                notificacionesCompleta == true
                                                ?<>
                                                    {/* FECHA NO REGISTRADA */}

                                                    {
                                                        not.FECHA_NO_REGISTRADA
                                                        ?<>
                                                            <b>{"Fecha no Registrada : "}</b><br/>
                                                            <li>{not.FECHA_NO_REGISTRADA}<br/></li>
                                                        </>
                                                        :null
                                                    }

                                                    {/* CLIENTES */}

                                                    {
                                                        not.CLIENTES_NO_ENCONTRADOS
                                                        ?not.CLIENTES_NO_ENCONTRADOS.length > 0
                                                        ?<>
                                                            {"CLIENTES_NO_ENCONTRADOS : "}<br/>
                                                            {
                                                                not.CLIENTES_NO_ENCONTRADOS.map((cliente) => {
                                                                    return (
                                                                        <>
                                                                            {cliente.codigo+" en la linea: "+cliente.linea}<br/>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    
                                                    {/* PRODUCTOS */}

                                                    {
                                                        not.PRODUCTOS_NO_ENCONTRADOS
                                                        ?not.PRODUCTOS_NO_ENCONTRADOS.length > 0
                                                        ?<>
                                                            <b>{"Productos no encontrados: "}</b><br/>
                                                            {
                                                                not.PRODUCTOS_NO_ENCONTRADOS.map((producto) => {
                                                                    return (
                                                                        <>
                                                                            <li>{producto.codigo+" en la linea: "+producto.linea}</li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :null
                                                        :null
                                                    }

                                                    {/* SUBSIDIOS_NO_ENCONTRADOS */}
                                                    {
                                                        not.SUBSIDIOS_NO_ENCONTRADOS
                                                        ?not.SUBSIDIOS_NO_ENCONTRADOS.length > 0
                                                        ?<>
                                                            <b>{"Subsidios no encontrados: "}</b><br/>
                                                            {
                                                                not.SUBSIDIOS_NO_ENCONTRADOS.map((subsidio) => {
                                                                    return (
                                                                        <>
                                                                            <li>{subsidio.codigo+" en la linea: "+subsidio.linea}</li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :null
                                                        :null
                                                    }

                                                    {/* CABECERAS INCORRECTAS */}
                                                    {
                                                        not.VALOR_NO_NUMERICO
                                                        ?not.VALOR_NO_NUMERICO.length > 0
                                                        ?<>
                                                            <b>{"Valores no numéricos: "}</b><br/>
                                                            {
                                                                not.VALOR_NO_NUMERICO.map((subsidio) => {
                                                                    return (
                                                                        <>
                                                                            <li>{subsidio.codigo}</li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :null
                                                        :null
                                                    }

                                                    {/* VALOR NO NUMÉRICO */}
                                                    {
                                                        not.CABECERAS_INCORRECTAS
                                                        ?not.CABECERAS_INCORRECTAS.length > 0
                                                        ?<>
                                                            <b>{"Cabeceras no encontradas: "}</b><br/>
                                                            {
                                                                not.CABECERAS_INCORRECTAS.map((subsidio) => {
                                                                    return (
                                                                        <>
                                                                            <li>{subsidio.codigo}</li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.MUP_VACIO
                                                        ?not.MUP_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna MUP: "}</b><br/>
                                                            <li>{"Encontrados en la linea: "+ not.MUP_VACIO}</li>
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.SKU_VACIO
                                                        ?not.SKU_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna SKU: "}</b><br/>
                                                            <li>{"Encontrados en la linea: "+ not.SKU_VACIO}</li>
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.DEST_RUC_SAP_VACIO
                                                        ?not.DEST_RUC_SAP_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna DEST + RUC + SAP: "}</b><br/>
                                                            <li>{"Encontrados en la linea: "+ not.DEST_RUC_SAP_VACIO}</li>                                                            
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.CANTIDAD_BULTOS_NEGATIVA
                                                        ?not.CANTIDAD_BULTOS_NEGATIVA.length > 0
                                                        ?<>
                                                            <b>{"Cantidad de bultos(Solicitados por DT) con valores negativos: "}</b><br/>
                                                            <li>{"Encontrados en la linea:"}{not.CANTIDAD_BULTOS_NEGATIVA.map(can => ` ${can.linea},`)}</li>                                                            
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.MONTO_RECONOCER_NEGATIVO
                                                        ?not.MONTO_RECONOCER_NEGATIVO.length > 0
                                                        ?<>
                                                            <b>{"Monto reconocer S/IGV con valores negativos: "}</b><br/>
                                                            <li>{"Encontrados en la linea:"}{not.MONTO_RECONOCER_NEGATIVO.map(mon => ` ${mon.linea},`)}</li>                                                            
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.CODIGO_SOLICITANTE_VACIO
                                                        ?not.CODIGO_SOLICITANTE_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna CODIGO SOLICITANTE: "}</b><br/>
                                                            <li>{"Encontrados en la linea: "+ not.CODIGO_SOLICITANTE_VACIO}</li>                                                            
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.SUB_CLIENTE_VACIO
                                                        ?not.SUB_CLIENTE_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna SUB-CLIENTE: "}</b><br/>
                                                        <li>{"Encontrados en la linea: "+ not.SUB_CLIENTE_VACIO}</li>                                                            
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.DESCRIPCION_VACIO
                                                        ?not.DESCRIPCION_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna DESCRIPCIÓN: "}</b><br/>
                                                            <li>{"Encontrados en la linea: "+ not.DESCRIPCION_VACIO}</li>                                                            

                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.PC_SAP_FINAL_VACIO
                                                        ?not.PC_SAP_FINAL_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna PC SAP FINAL: "}</b><br/>
                                                            <li>{"Encontrados en la linea: "+ not.PC_SAP_FINAL_VACIO}</li>                                                            
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.DSCTO_VACIO
                                                        ?not.DSCTO_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna DSCTO %: "}</b><br/>
                                                            <li>{"Encontrados en la linea: "+ not.DSCTO_VACIO}</li>                                                            
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.PC_SUBSIDIADO_VACIO
                                                        ?not.PC_SUBSIDIADO_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna PC SUBSIDIADO: "}</b><br/>
                                                            <li>{"Encontrados en la linea: "+ not.PC_SUBSIDIADO_VACIO}</li>
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.PVP_IGV_VACIO
                                                        ?not.PVP_IGV_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna PVP S/IGV: "}</b><br/>
                                                            <li>{"Encontrados en la linea: "+ not.PVP_IGV_VACIO}</li>
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                    {
                                                        not.DSCTO_SOLES_VACIO
                                                        ?not.DSCTO_SOLES_VACIO.length > 0
                                                        ?<>
                                                            <b>{"Campos vacíos en la columna DSCTO S/.: "}</b><br/>
                                                            <li>{"Encontrados en la linea: "+ not.DSCTO_SOLES_VACIO}</li>
                                                        </>
                                                        :null
                                                        :null
                                                    }

                                                    {/* CLIENTES_SO_NO_ENCONTRADOS */}
                                                    {
                                                        not.CLIENTES_SO_NO_ENCONTRADOS
                                                        ?not.CLIENTES_SO_NO_ENCONTRADOS.length > 0
                                                        ?<>
                                                            <b>{"Clientes SO no encontrados: "}</b><br/>
                                                            {
                                                                not.CLIENTES_SO_NO_ENCONTRADOS.map((subsidio) => {
                                                                    return (
                                                                        <>
                                                                            <li>{subsidio.codigo+" en la linea: "+subsidio.linea}</li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :null
                                                        :null
                                                    }

                                                    {/* NOTA_CREDITO_VALOR_POSITIVO */}
                                                    {
                                                        not.NOTA_CREDITO_VALOR_POSITIVO
                                                        ?not.NOTA_CREDITO_VALOR_POSITIVO.length > 0
                                                        ?<>
                                                            <b>{"Notas de créditos con valores positivos: "}</b><br/>
                                                            {
                                                                not.NOTA_CREDITO_VALOR_POSITIVO.map((subsidio) => {
                                                                    return (
                                                                        <>
                                                                            <li>{subsidio.codigo+" en la linea: "+subsidio.linea}</li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :null
                                                        :null
                                                    }

                                                    {/* FECHAS_NO_CORRESPONDE_MES */}
                                                    {
                                                        not.FECHAS_NO_CORRESPONDE_MES
                                                        ?not.FECHAS_NO_CORRESPONDE_MES.length > 0
                                                        ?<>
                                                            <b>{"Facturas no correspondientes al mes actual: "}</b><br/>
                                                            {
                                                                not.FECHAS_NO_CORRESPONDE_MES.map((subsidio) => {
                                                                    return (
                                                                        <>
                                                                            <li>{subsidio.codigo+" en la linea: "+subsidio.linea}</li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :null
                                                        :null
                                                    }
                                                </>
                                                :null
                                            }

                                        </div>
                                        :null
                                    }

                                    <div style={{width:'10%', }}>
                                        <img 
                                            style={{cursor:'pointer'}} 
                                            src={IconoCerrarNaranja} width="20px" 
                                            onClick={() => props.EliminarNotificacionReducer(posicion)}
                                        />
                                        <img 
                                            onClick={() => setNotificacionesCompleta(!notificacionesCompleta)}
                                            style={{cursor:'pointer', marginLeft:'-1px'}} src={IconoMasAzul} width="25px" 
                                        />
                                    </div>

                                </div>
                            </div>
                            :null
                        )
                    })
                }
            </div>
            {
                props.notificaciones_cargaarchivos.length > 0
                ?null
                :<div id="Contenedor-Cuerpo-Sin-Notificaciones-Carga">
                    <img id="Icono-Campana-Sin-Notificaciones-Carga" src={IconoCampana} /><br/>
                    <span className="Wnormal-S14-H19-Cacafb7">
                        No hay notificaciones recientes
                    </span>
                </div>
            }
        </div>
    )
}

export default NotiCarga
