import {
    CARGANDO_EDITAR_CONTRASENIA_PERFIL,
    CARGANDO_EDITAR_CUMPLEANIOS_PERFIL,
    CARGANDO_EDITAR_TELEFONO_PERFIL,
    CARGANDO_EDITAR_IMAGEN_PERFIL
} from '../../../Constantes/Perfil/Perfil'

const INIT_STATE = {
    cargando_editar_contrasenia : false,
    cargando_editar_cumpleanios : false,
    cargando_editar_telefono    : false,
    cargando_editar_perfil      : false,
    rex_prefijo_paises          : [
        {
            name:'Alemania',
            dial_code: "+49",
            icon: 'https://countryflagsapi.com/png/germany',
        },
        {
            name:'Argentina',
            dial_code: "+54",
            icon: 'https://countryflagsapi.com/png/argentina',
        },
        {
            name:'Austria',
            dial_code: "+43",
            icon: 'https://countryflagsapi.com/png/austria',
        },
        {
            name:'Bélgica',
            dial_code: "+32",
            icon: 'https://countryflagsapi.com/png/belgium',
        },
        {
            name:'Bolivia',
            dial_code: "+591",
            icon: 'https://countryflagsapi.com/png/bolivia',
        },
        {
            name:'Brasil',
            dial_code: "+55",
            icon: 'https://countryflagsapi.com/png/brazil',
        },
        {
            name:'Canada',
            dial_code: "+1",
            icon: 'https://countryflagsapi.com/png/canada',
        },
        {
            name:'Colombia',
            dial_code: "+57",
            icon: 'https://countryflagsapi.com/png/colombia',
        },
        {
            name:'Costa Rica',
            dial_code: "+506",
            icon: 'https://countryflagsapi.com/png/188',
        },
        {
            name:'Cuba',
            dial_code: "+53",
            icon: 'https://countryflagsapi.com/png/cuba',
        },
        {
            name:'Ecuador',
            dial_code: "+593",
            icon: 'https://countryflagsapi.com/png/ecuador',
        },
        {
            name:'España',
            dial_code: "+34",
            icon: 'https://countryflagsapi.com/png/spain',
        },
        {
            name:'Estados Unidos',
            dial_code: "+1",
            icon: 'https://countryflagsapi.com/png/840',
        },
        {
            name:'Finlandia',
            dial_code: "+358",
            icon: 'https://countryflagsapi.com/png/finland',
        },
        {
            name:'Francia',
            dial_code: "+33",
            icon: 'https://countryflagsapi.com/png/france',
        },
        {
            name:'Grecia',
            dial_code: "+30",
            icon: 'https://countryflagsapi.com/png/greece',
        },
        {
            name:'Guatemala',
            dial_code: "+502",
            icon: 'https://countryflagsapi.com/png/guatemala',
        },
        {
            name:'Holanda',
            dial_code: "+31",
            icon: 'https://countryflagsapi.com/png/netherlands',
        },
        {
            name:'Honduras',
            dial_code: "+504",
            icon: 'https://countryflagsapi.com/png/honduras',
        },
        {
            name:'Italia',
            dial_code: "+39",
            icon: 'https://countryflagsapi.com/png/italy',
        },
        {
            name:'Jamaica',
            dial_code: "+876",
            icon: 'https://countryflagsapi.com/png/jamaica',
        },
        {
            name:'Mexico',
            dial_code: "+52",
            icon: 'https://countryflagsapi.com/png/mexico',
        },
        {
            name:'Nicaragua',
            dial_code: "+505",
            icon: 'https://countryflagsapi.com/png/nicaragua',
        },
        {
            name:'Panamá',
            dial_code: "+507",
            icon: 'https://countryflagsapi.com/png/panama',
        },
        {
            name:'Paraguay',
            dial_code: "+595",
            icon: 'https://countryflagsapi.com/png/paraguay',
        },
        {
            name:'Perú',
            dial_code: "+51",
            icon: 'https://countryflagsapi.com/png/peru',
        },
        {
            name:'Portugal',
            dial_code: "+351",
            icon: 'https://countryflagsapi.com/png/portugal',
        },
        {
            name:'Puerto Rico',
            dial_code: "+1787",
            icon: 'https://countryflagsapi.com/png/630',
        },
        {
            name:'República Dominicana',
            dial_code: "+809",
            icon: 'https://countryflagsapi.com/png/214',
        },
        {
            name:'Reino Unido',
            dial_code: "+44",
            icon: 'https://countryflagsapi.com/png/826',
        },
        {
            name:'Rusia',
            dial_code: "+7",
            icon: 'https://countryflagsapi.com/png/643',
        },
        {
            name:'Suecia',
            dial_code: "+46",
            icon: 'https://countryflagsapi.com/png/sweden',
        },
        {
            name:'Suiza',
            dial_code: "+41",
            icon: 'https://countryflagsapi.com/png/switzerland',
        },
        {
            name:'Uruguay',
            dial_code: "+598",
            icon: 'https://countryflagsapi.com/png/uruguay',
        },
        {
            name:'Venezuela',
            dial_code: "+58",
            icon: 'https://countryflagsapi.com/png/venezuela',
        },
    ]
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        
        case CARGANDO_EDITAR_CONTRASENIA_PERFIL: {
            return {
                ...state,
                cargando_editar_contrasenia : action.payload
            }
        }
        case CARGANDO_EDITAR_CUMPLEANIOS_PERFIL: {
            return {
                ...state,
                cargando_editar_cumpleanios : action.payload
            }
        }
        case CARGANDO_EDITAR_TELEFONO_PERFIL: {
            return {
                ...state,
                cargando_editar_telefono : action.payload
            }
        }
        case CARGANDO_EDITAR_IMAGEN_PERFIL: {
            return {
                ...state,
                cargando_editar_perfil : action.payload
            }
        }
        default:
            return state;
    }
}
