import { FILTRO_DATA_TERRITORIO_NOTA_CREDITO, LIST_DATA_TERRITORIO_NOTA_CREDITO, LIST_DATA_ZONA_NOTA_CREDITO } from '../../../Constantes/Descarga/Descarga'
import {
    OBTENER_NOTAS_CREDITO_EXCEL,
    CARGANDO_OBTENER_NOTAS_CREDITO_EXCEL,
    CARGANDO_OBTENER_DATA_DISTRIBUIDORAS_NOTAS_CREDITO,
    OBTENER_DATA_DISTRIBUIDORAS_NOTAS_CREDITO
} from '../../../Constantes/NotaCredito/NotaCredito'
import config from '../../../config'
import { CargarDatosFiltradosNotaCreditoReducer } from '../Descarga/Descarga'
import { estadoRequestReducer } from "../EstadoRequest"
import { notification } from 'antd'

const notificacionServidor = (type, mensaje, errores) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning' && errores){
        notification['warning']({
            message: 'Advertencia',
            description: <>
                            {mensaje}<br/>
                            {errores.map((e) => <>- {e}<br/></>)}
                        </>,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: 'Error con el servidor. Vuelva a ingresar más tarde.',
        })
    }
}

export const ObtenerNotaCreditoReducer = (nuevasZonas, nuevosTerritorios, nuevosDistribuidores, mostrarDataZona, mostrarDataTerritorio, mostrarDataDistribuidora, anio, mes) => async (dispatch, getState) => {

    dispatch({
        type: CARGANDO_OBTENER_NOTAS_CREDITO_EXCEL,
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }

    if(config.produccion == true){
        headerFetch = {
            'Accept' : 'application/json',
            'content-type': 'application/json',
            'api_token': localStorage.getItem('usutoken'),
            'api-token': localStorage.getItem('usutoken'),
        }
    }

    let rutaDescargar = "/"

    let territoriosFormateado = nuevosTerritorios
    if(territoriosFormateado.length > 0){
        territoriosFormateado = nuevosTerritorios.map(obj => {
            const territorio = obj.data.split(" -")[0];
            return { data: territorio };
        });
    }

    await fetch(config.api+'modulo/nota-credito/generar/excel-nota-credito',
		{
			mode:'cors',
			method: 'POST',
			body: JSON.stringify({
                "anio"              : anio,
                "mes"               : mes,
                "filtrozonas"       : mostrarDataZona,
                "filtroterritorio"  : mostrarDataTerritorio,
                "filtrodistribuidor": mostrarDataDistribuidora,
                "zonas"             : nuevasZonas,
                "territorios"       : territoriosFormateado,
                "distribuidores"    : nuevosDistribuidores,
            }),
			headers: headerFetch
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {

		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            
            if(data.respuesta == true){
                rutaDescargar = config.api+"/"+data.urlDescargar
            }else{
            }
            if(data.excelVacio){
                if(data.archivoExcelNombre.length > 1){
                    notificacionServidor('warning', 'Los siguientes archivos no contienen data:', data.archivoExcelNombre)
                }else{
                    notificacionServidor('warning', `El archivo ${data.archivoExcelNombre} no contiene data.`)
                }
                
            }
			
		}else{
            
        }

    }).catch((error)=> {
        notificacionServidor('error')
    });


    dispatch({
        type: CARGANDO_OBTENER_NOTAS_CREDITO_EXCEL,
        payload: false
    })

    return rutaDescargar
}

export const ObtenerDataDistribuidorasReducer = (anio, mes) => async (dispatch, getState) => {

    dispatch({
        type: CARGANDO_OBTENER_DATA_DISTRIBUIDORAS_NOTAS_CREDITO,
        payload: true
    })

    const mesFiltroNC = mes ? mes.toString() : null
    const anioFiltroNC = anio ? anio.toString() : null

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }

    if(config.produccion == true){
        headerFetch = {
            'Accept' : 'application/json',
            'content-type': 'application/json',
            'api_token': localStorage.getItem('usutoken'),
            'api-token': localStorage.getItem('usutoken'),
        }
    }

    await fetch(config.api+'modulo/nota-credito/mostrar/data-distribuidores',
		{
			mode:'cors',
			method: 'POST',
            body: JSON.stringify({
                mesConsulta     : mesFiltroNC,
                anioConsulta    : anioFiltroNC,
            }),
			headers: headerFetch
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {

		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            
            if(data.respuesta == true){
                dispatch({
                    type: OBTENER_DATA_DISTRIBUIDORAS_NOTAS_CREDITO,
                    payload : data.data
                })
                dispatch({
                    type: LIST_DATA_ZONA_NOTA_CREDITO,
                    payload : data.zns
                })

                if(data.tvs.length > 0){
    
                    data.tvs.map(tv => {

                        const numberRepeat = data.tvs.filter(rtv => rtv.data == tv.data).length

                        tv['alias'] = numberRepeat > 1 ? `${tv.data} - ${tv.zona}` : tv.data
                    })                    
                    dispatch({
                        type: LIST_DATA_TERRITORIO_NOTA_CREDITO,
                        payload : data.tvs
                    })
                }
                dispatch(CargarDatosFiltradosNotaCreditoReducer())
            }
		}else{
            
        }

    }).catch((error)=> {
        console.log(error)
    });


    dispatch({
        type: CARGANDO_OBTENER_DATA_DISTRIBUIDORAS_NOTAS_CREDITO,
        payload: false
    })

}

export const CambiarDataDistribuidoresReducer = (data) => async (dispatch) => {

    dispatch({
        type: OBTENER_DATA_DISTRIBUIDORAS_NOTAS_CREDITO,
        payload : data
    })

}