import { 
    OBTENER_DATA_ADMINISTRAR_TIPOS_USUARIOS,
    OBTENER_FILTRO_ADMINISTRAR_TIPOS_USUARIOS,
    OBTENER_DATA_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
    OBTENER_FILTRO_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
    SPIN_CREAR_EDITAR_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
    ESTADO_SWITCH_PADRE_ADMINISTRAR_PERMISOS_TIPO_USUARIO,
    SPIN_ADMINISTRAR_TIPOS_USUARIOS,
    SPIN_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
} from "../../../../Constantes/Administrar/TiposUsuarios/TiposUsuarios";

const INIT_STATE = {
    rex_data_tipos_usuarios : [],
    rex_filtro_tipos_usuarios : [],
    rex_data_permisos_tipos_usuarios : [],
    rex_filtro_permisos_tipos_usuarios : [],
    rex_spin_crear_editar_permisos_tipos_usuarios: false,
    rex_estado_switch_padre_tipos_usuarios: [],
    rex_spin_tipos_usuarios: [],
    rex_spin_permisos_tipos_usuarios: [],
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_ADMINISTRAR_TIPOS_USUARIOS :
            return {
                ...state,
                rex_data_tipos_usuarios : action.payload
            }
        case OBTENER_FILTRO_ADMINISTRAR_TIPOS_USUARIOS :
            return {
                ...state,
                rex_filtro_tipos_usuarios : action.payload
            } 
        case OBTENER_DATA_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS :
            return {
                ...state,
                rex_data_permisos_tipos_usuarios : action.payload
            }
        case OBTENER_FILTRO_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS :
            return {
                ...state,
                rex_filtro_permisos_tipos_usuarios : action.payload
            }
        case SPIN_CREAR_EDITAR_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS :
            return {
                ...state,
                rex_spin_crear_editar_permisos_tipos_usuarios : action.payload
            }
        case ESTADO_SWITCH_PADRE_ADMINISTRAR_PERMISOS_TIPO_USUARIO :
            return {
                ...state,
                rex_estado_switch_padre_tipos_usuarios : action.payload
            }
        case SPIN_ADMINISTRAR_TIPOS_USUARIOS :
            return {
                ...state,
                rex_spin_tipos_usuarios : action.payload
            }
        case SPIN_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS :
            return {
                ...state,
                rex_spin_permisos_tipos_usuarios : action.payload
            }
        default:
            return state;
    }
}