import React, {useState} from 'react'
import { Spin, Table, Button, Modal, Tooltip, Select, Form, Input } from 'antd'
import { LoadingOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import Column from 'antd/lib/table/Column'
import Moment from 'moment'
import 'moment/locale/es'
import {
    ActualizarDataAdministrarPermisosReducer,
    EliminarDataAdministrarPermisosReducer,
} from '../../../Redux/Actions/Administrar/Permisos/Permisos'
import {useDispatch, useSelector} from "react-redux"
const TablaPermisos = (props) => {
    const pageTablePermisos = props.pageTablePermisos

    const { Option } = Select
    const [formEditar] = Form.useForm()
    const dispatch = useDispatch()

    const {
        rex_filtro_tabla_permisos,
        rex_data_tipos_permisos,
        rex_spin_permisos,
    } = useSelector(({administrarPermisos}) => administrarPermisos)

    const [estadoEditar, setEstadoEditar] = useState([])

    const iconoCarga = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    )

    const abrirModal = (eliminar, pempermisos) => {
        Modal.confirm({
            title: 'Eliminar Permiso',
            content: `Deseas eliminar el permiso ${pempermisos.pemnombre}?`,
            okText: 'Eliminar',
            cancelText: 'Cancelar',
            async onOk() {
                await dispatch(
                    EliminarDataAdministrarPermisosReducer(eliminar)
                )
            },
            onCancel() {
            },
        })
    }

    const enviarEditarFormulario = async (pemid) => {
        const datos = await formEditar.validateFields(["tppnombre"+pemid, "pemnombre"+pemid, "pemslug"+pemid, "pemruta"+pemid]);
        let respuesta = await dispatch(ActualizarDataAdministrarPermisosReducer(pemid, datos["tppnombre"+pemid], datos["pemnombre"+pemid], datos["pemslug"+pemid], datos["pemruta"+pemid]))
        if(respuesta){
            desactivarEditar(pemid)
        }
    }

    const verificarEditar = (record) => estadoEditar.find(e => e == record.pemid)

    const activarEditar = (record) => {
        let formulario = new Object()
        formulario[`tppnombre`+record.pemid] = record.tppid
        formulario[`pemnombre`+record.pemid] = record.pemnombre
        formulario[`pemslug`+record.pemid] = record.pemslug
        formulario[`pemruta`+record.pemid] = record.pemruta
        formEditar.setFieldsValue(formulario)
        const permisoId = estadoEditar.find(e => e == record.pemid)
        setEstadoEditar(
            permisoId 
            ?   [...estadoEditar]
            :   [record.pemid, ...estadoEditar]
        )
    }

    const desactivarEditar = (pemid) => {
        const resultado = estadoEditar.filter(e => e !== pemid)
        setEstadoEditar(resultado)
    }

    const FilaEditar = ({
        editing,
        dataIndex,
        valor,
        record,
        inputType,
        children,
        validacion,
        textoValidacion,
        ...restProps
      }) => {
        const inputNode = inputType === 'select' 
                            ?   <Select 
                                    className="selectAdministrarArchivo"
                                    defaultValue={valor}
                                >
                                    <Option value="" title="">Seleccione un tipo de permiso</Option>
                                    {
                                        rex_data_tipos_permisos.map((tper, index) => (
                                            <Option key={index} value={tper.tppid}>
                                                {tper.tppnombre}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            :   <Input defaultValue={valor} />
        return (
            <td {...restProps}>
                {editing 
                ?   (<Form.Item
                        className="inputPermisoCrear"
                        name={dataIndex}
                        style={{
                        margin: 0,
                        }}
                        rules={[
                        {
                            required: validacion,
                            message: textoValidacion,
                        },
                        ]}
                    >
                    {inputNode}
                    </Form.Item>) 
                :   ( children )
                }
            </td>
        )
    }

    return(
        <Spin 
            spinning={rex_spin_permisos} 
            indicator={iconoCarga}
        >
            <Form
                form={formEditar}
                autoComplete="off"
                onFinish={false}
                onValuesChange={false}
            >
            <Table 
                className="table-administrarUsuarios tableAdministrarPermisos"
                dataSource={rex_filtro_tabla_permisos}
                scroll={{
                    x: 1700,
                }}
                components={{
                    body: {
                        cell: FilaEditar,
                    },
                }}
                pagination={{
                    defaultCurrent: pageTablePermisos,
                    current: pageTablePermisos,
                }}
            >
                <Column 
                    title="Item"
                    dataIndex="index" 
                    key="index"
                    fixed="left"
                    width={45}
                    render={(value, item, index) => (pageTablePermisos - 1) * 10 + (index + 1)}
                />
                <Column 
                    title="Tipos de Permisos" 
                    dataIndex="tppnombre" 
                    key="tppnombre"
                    width={200}
                    editable={true}
                    onCell={(record) => ({
                        record,
                        inputType: "select",
                        dataIndex: "tppnombre"+record.pemid,
                        valor: record.tppid,
                        validacion: true,
                        textoValidacion: "Por favor ingresar un tipo de permiso",
                        editing: verificarEditar(record),
                    })}
                /> 
                <Column 
                    title="Permisos" 
                    dataIndex="pemnombre" 
                    key="pemnombre" 
                    width={230}
                    editable={true}
                    onCell={(record) => ({
                        record,
                        inputType: "input",
                        dataIndex: "pemnombre"+record.pemid,
                        valor: record.pemnombre,
                        validacion: true,
                        textoValidacion: "Por favor ingresar un permiso",
                        editing: verificarEditar(record),
                    })}
                /> 
                <Column 
                    title="Slug" 
                    dataIndex="pemslug" 
                    key="pemslug" 
                    width={280}
                    editable={true}
                    onCell={(record) => ({
                        record,
                        inputType: "input",
                        dataIndex: "pemslug"+record.pemid,
                        valor: record.pemslug,
                        validacion: false,
                        textoValidacion: "",
                        editing: verificarEditar(record),
                    })}
                /> 
                <Column 
                    title="Ruta" 
                    dataIndex="pemruta" 
                    key="pemruta" 
                    width={130}
                    editable={true}
                    onCell={(record) => ({
                        record,
                        inputType: "input",
                        dataIndex: "pemruta"+record.pemid,
                        valor: record.pemruta,
                        validacion: false,
                        textoValidacion: "",
                        editing: verificarEditar(record),
                    })}
                /> 
                <Column 
                    title="Fecha de Creación" 
                    dataIndex="created_at" 
                    key="created_at"
                    width={130}
                    render={(created_at) => (
                        <>{Moment(created_at).format('D MMMM YYYY')}</>
                    )}
                />
                <Column 
                    title="Acciones" 
                    dataIndex="pemid" 
                    key="pemid"
                    fixed="right"
                    width={90}
                    render={(pemid, pempermisos) => {
                        const editarEstadoBoton = verificarEditar(pempermisos);
                        return editarEstadoBoton ? (
                            <div 
                                style={{
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    gap: '10px'
                                }}
                            >
                                <Tooltip title="Guardar">
                                    <Button 
                                        onClick={() => enviarEditarFormulario(pempermisos.pemid)}
                                        shape="circle"
                                    >
                                        <CheckOutlined />
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Cancelar">
                                    <Button 
                                        onClick={() => desactivarEditar(pempermisos.pemid)}
                                        shape="circle" 
                                        danger
                                    >
                                        <CloseOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    gap: '10px'
                                }}
                            >
                                <Tooltip title="Editar">
                                    <Button 
                                        onClick={() => activarEditar(pempermisos)}
                                    >
                                        <EditOutlined />
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Eliminar">
                                    <Button 
                                        onClick={() => {
                                            abrirModal(pemid, pempermisos)
                                        }}
                                        danger
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                        )}
                    }
                />
            </Table>
            </Form>
        </Spin>
    )
}

export default TablaPermisos