import { 
    OBTENER_DATA_TABLA_ADMINISTRAR_CONTROL_ARCHIVOS,
    OBTENER_DATA_TIPO_ARCHIVO_ADMINISTRAR_CONTROL_ARCHIVOS,
    OBTENER_DATA_EXCEL_ADMINISTRAR_CONTROL_ARCHIVOS,
    ELIMINAR_DATA_ADMINISTRAR_CONTROL_ARCHIVOS,
    SPIN_TABLE_ADMINISTRAR_CONTROL_ARCHIVOS
} from "../../../../Constantes/Administrar/ControlArchivos/ControlArchivos";

const INIT_STATE = {
    rex_data_tabla_control_archivos : [],
    rex_data_tipo_archivo_control_archivos: [],
    rex_data_excel_control_archivos: [],
    rex_data_eliminar_control_archivos: [],
    rex_spin_table_control_archivos: []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_DATA_TABLA_ADMINISTRAR_CONTROL_ARCHIVOS :
            return {
                ...state,
                rex_data_tabla_control_archivos : action.payload
            }
        case OBTENER_DATA_TIPO_ARCHIVO_ADMINISTRAR_CONTROL_ARCHIVOS :
            return {
                ...state,
                rex_data_tipo_archivo_control_archivos : action.payload
            }
        case OBTENER_DATA_EXCEL_ADMINISTRAR_CONTROL_ARCHIVOS :
            return {
                ...state,
                rex_data_excel_control_archivos : action.payload
            }
        case ELIMINAR_DATA_ADMINISTRAR_CONTROL_ARCHIVOS :
            return {
                ...state,
                rex_data_eliminar_control_archivos : action.payload
            }
        case SPIN_TABLE_ADMINISTRAR_CONTROL_ARCHIVOS :
            return {
                ...state,
                rex_spin_table_control_archivos : action.payload
            }
        default:
            return state;
    }
}