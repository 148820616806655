import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import BotonesRutas from '../../../Componentes/Administrar/BotonesRutas'
import '../../../Estilos/Rutas/Administrar/Paises/Paises.css'
import { Button, Col, Pagination, Row, notification, Form } from 'antd'
import {
    PlusCircleOutlined,
} from '@ant-design/icons'
import Search from 'antd/lib/input/Search'
import Title from 'antd/lib/typography/Title'
import {
    ObtenerDataAdministrarPaisesReducer,
    ObtenerListaFiltroPaisesReducer,
} from '../../../Redux/Actions/Administrar/Paises/Paises'
import TablaLista from '../../../Componentes/Administrar/Paises/TablaLista'
import TablaAcciones from '../../../Componentes/Administrar/Paises/TablaAcciones'

const Paises = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const {
        rex_filtro_tabla_paises,
        rex_spin_paises,
    } = useSelector(({administrarPaises}) => administrarPaises)

    useEffect(() => {
        dispatch(ObtenerDataAdministrarPaisesReducer())
    }, [])

    const [idPais, setIdPais] = useState(null)
    const [pageTableTiposUsuarios, setPageTableTiposUsuarios] = useState(1)
    const [activoSwitch, setActivoSwitch] = useState(true)
    const [imageUrl, setImageUrl] = useState()

    const notificacionPrecaucion = (type, message) => {
        const key = `open${Date.now()}`
        if(type === 'warning'){
            notification[type]({
                message: 'Advertencia',
                description: message,
            })
        }else if(type === 'crear'){
            notification['warning']({
                message: 'Advertencia',
                description: message,
                btn: (
                    <Button 
                        type="primary" 
                        onClick={() => {
                            vaciarFormulario()
                            notification.close(key)
                        }}
                    >
                        Confirmar
                    </Button>
                ),
                key,
            })
        }else if(type === 'error'){
            notification[type]({
                message: 'Error',
                description: 'Error al eliminar el usuario.',
            })
        }
    }

    const vaciarFormulario = () => {
        form.setFieldsValue({
            nombre: '',
            prefijo: '',
        })
        setImageUrl()
        setActivoSwitch(true)
        setIdPais(null)
    }

    const seleccionarFila = (record) => {
        return {
            onClick: event => {
                form.setFieldsValue({
                    nombre: record.painombre,
                    prefijo: record.paiprefijo,
                })
                setIdPais(record.paiid)
                setActivoSwitch(record.estid == 1 ? true : false)
                setImageUrl(record.paiicono)
            }
        }
    }

    const crearUsuario = () => {
        if(idPais){
            notificacionPrecaucion('crear', "No se guardarán los cambios realizados, si desea continuar con la opción de crear.")
        }
    }

    return(
        <>
            <div 
                className="contenedorAdministrarModulo"
            >
                <Title 
                    className="title-controlArchivos"
                >
                    ADMINISTRADOR
                </Title>
                <Row 
                    className="row-button-controlArchivos"
                    style={{ 
                        rowGap:'25px',
                        padding: '0 15px 0 15px', 
                        marginTop: '25px',
                        marginBottom:'25px',
                    }}
                >
                    <Col 
                        xl={14}
                        lg={18}
                        md={20}
                        sm={24}
                        xs={{
                            span: 24,
                            order:1
                        }}
                        style={{
                            margin: 'auto 0',
                        }}
                    >
                        <BotonesRutas 
                            ruta="pais" 
                        />
                    </Col>
                    <Col 
                        xl={{
                            span: 7,
                            offset: 0,
                            order: 2
                        }}
                        lg={10}
                        md={{
                            span: 12,
                            order: 3
                        }}
                        sm={16}
                        xs={{
                            span: 24,
                            order: 2
                        }}
                        style={{
                            display:'flex', 
                            alignItems:'center',
                        }}
                    >
                        <Search
                            placeholder="Buscar"
                            allowClear
                            className="search-controlArchivos"
                            onChange={(e) => dispatch(ObtenerListaFiltroPaisesReducer(e.target.value))}
                        />
                    </Col>
                    <Col 
                        xl={{
                            span: 3,
                            order: 3,
                            offset: 0
                        }}
                        lg={{
                            span: 4,
                            offset: 10
                        }}
                        md={{
                            span: 5,
                            offset: 7
                        }}
                        sm={{
                            span: 8,
                            offset: 16
                        }}
                        xs={{
                            span: 24,
                            order: 4,
                        }}
                        style={{
                            display:'flex', 
                            alignItems:'center', 
                            justifyContent:'end'
                        }}
                    >
                        <Pagination  
                            size="small"
                            defaultCurrent={pageTableTiposUsuarios}
                            current={pageTableTiposUsuarios}
                            total={rex_filtro_tabla_paises.length}
                            showTotal={(total, range) => {
                                return(
                                    rex_filtro_tabla_paises
                                    ? range[0] + " - " + range[1] + " de " + total
                                    : <>0 - 0 de 0</>
                                )
                            }} 
                            className="pagination-controlArchivo"
                            onChange={(page) => {
                                setPageTableTiposUsuarios(page)
                            }}
                        />
                    </Col>
                    <Col 
                        xl={{
                            span: 3,
                            order: 4,
                            offset: 21
                        }}
                        lg={{
                            span: 3,
                            offset: 3
                        }} 
                        md={{
                            span: 4,
                            order: 2,
                            offset: 0
                        }}
                        sm={{
                            span: 4,
                            offset: 4
                        }}
                        xs={{
                            span: 24,
                            order: 3
                        }}
                        className="colCrearAdministrarUsuarios"
                    >
                        <Button 
                            className="button-administrarUsuario" 
                            shape="round"
                            onClick={crearUsuario}
                        >
                            Crear
                            <PlusCircleOutlined 
                                className="iconButton-administrarUsuario"
                            />
                        </Button>
                    </Col>
                </Row>
                <Row style={{rowGap:'40px'}}>
                    <Col
                        lg={12}
                        sm={24}
                    >
                        <TablaLista 
                            rex_spin_paises={rex_spin_paises}
                            rex_filtro_tabla_paises={rex_filtro_tabla_paises}
                            seleccionarFila={seleccionarFila}
                            vaciarFormulario={vaciarFormulario}
                            pageTableTiposUsuarios={pageTableTiposUsuarios}
                        />
                    </Col>
                    <Col
                        lg={12}
                        sm={24}
                    >
                        <TablaAcciones 
                            form={form}
                            notificacionPrecaucion={notificacionPrecaucion}
                            vaciarFormulario={vaciarFormulario}
                            activoSwitch={activoSwitch}
                            setActivoSwitch={setActivoSwitch}
                            imageUrl={imageUrl}
                            setImageUrl={setImageUrl}
                            idPais={idPais}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Paises