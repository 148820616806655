import React, {useRef} from "react";
import IconoSoporte from '../../../Assets/Imagenes/Login/Iconos/whastapplogo.png'

const ChatWithUs = (props) => {
    const styles = props.styles
    const ref_redirect = useRef(null)
    return (
        <>
            <div
                style={styles}
                className='Consultar-Soporte-Intrologin'
                onClick={() => [
                    ref_redirect.current.click()
                ]}
            >
                <div className='Nube-Contactar-Soporte' >
                    Chat with us
                </div>

                <div >
                    <img className='Icono-Soporte' src={IconoSoporte} />
                </div>
            </div>
            <a
                ref={ref_redirect} 
                href="https://api.whatsapp.com/send?phone=51951331671" target="_blank" style={{display:'none'}}></a>
        </>
    )
}

export default ChatWithUs;