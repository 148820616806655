import config from '../../../../config'
import { 
    OBTENER_DATA_ADMINISTRAR_TIPOS_USUARIOS,
    OBTENER_FILTRO_ADMINISTRAR_TIPOS_USUARIOS,
    OBTENER_DATA_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
    OBTENER_FILTRO_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
    SPIN_CREAR_EDITAR_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
    SPIN_ADMINISTRAR_TIPOS_USUARIOS,
    SPIN_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
} from "../../../../Constantes/Administrar/TiposUsuarios/TiposUsuarios"
import { estadoRequestReducer } from "../../EstadoRequest"
import { notification } from 'antd'

const notificacionServidor = (type, mensaje, errores) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning' && errores){
        notification['warning']({
            message: 'Advertencia',
            description:<>
                            {mensaje}<br/>
                            {errores.map((e) => <>- {e}<br/></>)}
                        </>,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: 'Error con el servidor. Vuelva a ingresar más tarde.',
        })
    }
}

export const ObtenerDataAdministrarTiposUsuariosReducer = () => async (dispatch, getState) => {

    dispatch({
        type: SPIN_ADMINISTRAR_TIPOS_USUARIOS,
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrador/mostrar/tipos-usuarios',
		{
			mode:'cors',
			method: 'POST',
			body: JSON.stringify({}),
			headers: headerFetch
      	}
    )
    .then(async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_ADMINISTRAR_TIPOS_USUARIOS,
                payload : data.datos
            })
            dispatch(ObtenerListaFiltroTiposUsuariosReducer(null))
            dispatch({
                type: SPIN_ADMINISTRAR_TIPOS_USUARIOS,
                payload: false
            })
		}else{
        }
    }).catch((error)=> {
        notificacionServidor('error')
    })

}

export const ObtenerDataAdministrarPermisosTiposUsuariosReducer = (tipoAccion, tpunombre) => async(dispatch, getState) => {
    
    dispatch({
        type: SPIN_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/mostrar-permisos-tipos-usuarios',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                tipoAccion: tipoAccion,
                tpunombre: tpunombre,
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
                payload : data.datos
            })
            dispatch({
                type: SPIN_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
                payload: false
            })
            dispatch(ObtenerListaFiltroPermisosTiposUsuariosReducer(null))
		}else{
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    })

}

export const CrearDataAdministrarTiposUsuariosReducer = (nombreTipoUsuario, permisos) => async(dispatch, getState) => {

    dispatch({
        type: SPIN_CREAR_EDITAR_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
        payload : true
    })

    let respuesta
    let mensaje = null

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/crear-tipos-usuarios',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                nombreTipoUsuario: nombreTipoUsuario,
                permisos: permisos,
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                respuesta = true
                mensaje = data.mensaje
            }else{
                notificacionServidor('warning', data.mensaje)
                respuesta = false
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
            respuesta = false
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    })

    dispatch({
        type: SPIN_CREAR_EDITAR_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
        payload : false
    })

    return (
        {respuesta: respuesta, mensaje: mensaje}
    )

}

export const ActualizarDataAdministrarTiposUsuariosReducer = (idTipoUsuario, nombreTipoUsuario, permisos) => async(dispatch, getState) => {

    let respuesta
    let mensaje = null

    dispatch({
        type: SPIN_CREAR_EDITAR_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
        payload : true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/actualizar-tipos-usuarios',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                idTipoUsuario: idTipoUsuario,
                nombreTipoUsuario: nombreTipoUsuario,
                permisos: permisos,
            }),
      	}
    )
    .then(async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                respuesta = true
                mensaje = data.mensaje
            }else{
                notificacionServidor('warning', data.mensaje)
                respuesta = false
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
            respuesta = false
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    })

    dispatch({
        type: SPIN_CREAR_EDITAR_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
        payload : false
    })

    return (
        {respuesta: respuesta, mensaje: mensaje}
    )
}

export const EliminarDataAdministrarTiposUsuariosReducer = (idTipoUsuario) => async(dispatch, getState) => {

    let respuesta = true

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/eliminar-tipos-usuarios',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                idTipoUsuario: idTipoUsuario
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                respuesta = false
                notificacionServidor('success', data.mensaje)
                dispatch(ObtenerDataAdministrarTiposUsuariosReducer())
            }else{
                notificacionServidor('warning', data.mensaje)
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    })

    return respuesta
}

export const CambiarEstadoPadrePermisosTipoUsuarioReducer = (tppid, estado, buscar) => async (dispatch, getState) => {

    let permisosTipoUsuario = getState().administrarTiposUsuarios.rex_data_permisos_tipos_usuarios

    permisosTipoUsuario.map((p, indexp) => 
        p.permisos.filter((tpptipospermisos, index) => {
            if(p.tppid == tppid){
                p['seleccionado'] = estado
                p.permisos[index]['seleccionado'] = estado
            }
        })
    )

    dispatch({
        type: OBTENER_DATA_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
        payload: permisosTipoUsuario,
    })

    dispatch(ObtenerListaFiltroPermisosTiposUsuariosReducer(buscar))

}

export const CambiarEstadoPermisosTipoUsuarioReducer = (pemid, estado, tppid, buscar) => async (dispatch, getState) => {

    let permisosTipoUsuario = getState().administrarTiposUsuarios.rex_data_permisos_tipos_usuarios

    permisosTipoUsuario.map((p) => 
        p.permisos.filter((tpptipospermisos, index) => {
            if(tpptipospermisos.pemid == pemid){
                p.permisos[index]['seleccionado'] = estado
            }
        })
    )

    dispatch({
        type: OBTENER_DATA_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
        payload: permisosTipoUsuario,
    })

    dispatch(CambiarEstadoPadreSwitchPermisosTipoUsuarioReducer(tppid, buscar))

}

export const CambiarEstadoPadreSwitchPermisosTipoUsuarioReducer = (tppid, buscar) => async (dispatch, getState) => {
    let permisosTipoUsuario = getState().administrarTiposUsuarios.rex_data_permisos_tipos_usuarios

    const { permisos } = permisosTipoUsuario.find(p => p.tppid == tppid)
    const permisoSeleccionado = permisos.filter(p => p.seleccionado == true)
    if(permisoSeleccionado.length == permisos.length){
        permisosTipoUsuario.map((p) => {
            if(p.tppid == tppid){
                p['seleccionado'] = true
            }
        })
    }else{
        permisosTipoUsuario.map((p) => {
            if(p.tppid == tppid){
                p['seleccionado'] = false
            }
        })
    }

    dispatch({
        type: OBTENER_DATA_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
        payload: permisosTipoUsuario,
    })

    dispatch(ObtenerListaFiltroPermisosTiposUsuariosReducer(buscar))
}

export const ObtenerListaFiltroTiposUsuariosReducer = (txtBuscar) => async (dispatch, getState) => {
    if(txtBuscar){
        const data = getState().administrarTiposUsuarios.rex_data_tipos_usuarios.filter((tipos) => tipos.tpunombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase()))

        dispatch({
            type: OBTENER_FILTRO_ADMINISTRAR_TIPOS_USUARIOS,
            payload: data
        })
    }else{
        dispatch({
            type: OBTENER_FILTRO_ADMINISTRAR_TIPOS_USUARIOS,
            payload: getState().administrarTiposUsuarios.rex_data_tipos_usuarios
        })
    }
}

export const ObtenerListaFiltroPermisosTiposUsuariosReducer = (txtBuscar) => async (dispatch, getState) => {
    if(txtBuscar){
        const data = getState().administrarTiposUsuarios.rex_data_permisos_tipos_usuarios.map((tupem) => 
            tupem.permisos.find((pemfind) => pemfind.pemnombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())) ||
            tupem.tppnombre.toLocaleLowerCase().includes(txtBuscar.toLocaleLowerCase())
            ?  ({
                    permisos: 
                    tupem.permisos.find((pemfind) => pemfind.pemnombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())) 
                    ?   tupem.permisos.filter((pem) => 
                            pem.pemnombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
                                && ({pemid: pem.pemid, pemnombre: pem.pemnombre, seleccionado: pem.seleccionado, tppid: pem.tppid})
                        )
                    :   tupem.permisos,
                    seleccionado: tupem.seleccionado,
                    tppid: tupem.tppid,
                    tppnombre: tupem.tppnombre,
                })
            :   null
        )
        const dataFiltrada = data.filter((datosF) => datosF !== null)
        dispatch({
            type: OBTENER_FILTRO_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
            payload: dataFiltrada
        })
    }else{
        dispatch({
            type: OBTENER_FILTRO_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS,
            payload: getState().administrarTiposUsuarios.rex_data_permisos_tipos_usuarios
        })
    }
}