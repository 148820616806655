import React, {useState, useEffect} from 'react'
import IconoLogo from '../../Assets/Imagenes/Logos/logo192.png'
import IconoMariposa from '../../Assets/Imagenes/Iconos/Login/Flying-money.gif'
import { Modal, Spin, notification, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import { LoginReducer } from "../../Redux/Actions/Login/Login"
import NuevoLogov2 from '../../Assets/Imagenes/Login/Logo_new_2.png'

const LoginNuevo = () => {

    const dispatch = useDispatch();

    const [cargandoLogin, setCargandoLogin] = useState(false)
    const [txtUsuario, setTxtUsuario] = useState("")
    const [txtContrasenia, setTxtContrasenia] = useState("")

    const enviarDatosLogin = async (values) =>  {
        // console.log(values)
        setCargandoLogin(true)
        let login = await dispatch(LoginReducer(values))
        if(login.respuesta === true){
            // addToast(login.mensaje, { appearance: 'success', autoDismiss: true});
        }else{
            // addToast(login.mensaje, { appearance: 'error', autoDismiss: true});
        }
        setCargandoLogin(false)
    };

    const [animacionInicial, setanimacionInicial] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setanimacionInicial(false)
        }, 14500);
    }, [])

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                textAlign: "-webkit-center",
                placeContent: "center",
                position:'relative'
            }}
        >


            {/* <div className={ animacionInicial == true ? 'Log-Banner Banner0' : 'Log-Banner Ocultar'} style={{top:"0"}}>
                <p>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span>
                </p>
            </div>
            <div className='Log-Banner Banner' style={{top:"0"}}>
                <p>
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span> 
                </p>
            </div>
            <div className='Log-Banner Banner Banner2' style={{top:"0", marginRight:"7px"}}>
                <p>
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span> 
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-ConRelleno'>SUBSIDIOS</span>
                    <span className='Log-Banner-Letras-SinRelleno'>SUBSIDIOS</span>
                </p>
            </div> */}

            <div className="iconoLoginEfecto1">
                <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                />
            </div>
            
            <div className="iconoLoginEfecto2">
                <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                />
            </div>

            <div className="iconoLoginEfecto3">
                <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                />
            </div>

            <div className="iconoLoginEfecto4">
                <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                />
            </div>

            <div className="iconoLoginEfecto5">
                <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                />
            </div>

            <div className="iconoLoginEfecto6">
                <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                />
            </div>

            <div className="iconoLoginEfecto7">
                <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                />
            </div>

{/*             
            <img src={IconoLogo} />
            <img src={IconoMariposaColor} /> */}



            <div>
                
                <div style={{position:'relative'}}>
                    <img 
                        src={NuevoLogov2} 
                        className="Icono-Subsidios-Login-Nuevo"
                    />
                </div>

                <div 
                    className='W700-S20-H27-C004FB8'
                    style={{marginBottom:'20px', position:'relative'}}
                >
                    ¡Bienvenido!
                </div>
                
                <div
                    className='Input-Recuperar-Contrasenia'
                >
                    <input 
                        className="inputCorreoSubsidios"
                        placeholder='Correo electrónico Softys' 
                        onChange={(e) => {
                            setTxtUsuario(e.target.value)
                        }}
                        id="email"
                    />
                </div>

                <div
                    className='Input-Recuperar-Contrasenia'
                    style={{display: 'flex', marginLeft: '8px', marginRight: '8px'}}
                >
                    <Input.Password 
                        className="inputPasswordSubsidios"
                        onChange={(e) => {
                            setTxtContrasenia(e.target.value)
                        }}
                        placeholder="Clave de Subsidios" 
                    />
                </div>
                <Link to="/recuperar-contrasenia">
                    <div 
                        className='W400-S14-H19-CFF8023' 
                        style={{marginBottom:'20px', textDecorationLine: "underline", position: 'relative'}}
                    >
                        ¿Olvidaste o bloqueaste tu contraseña?
                    </div>
                </Link>
                
                <Spin 
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} 
                    spinning={cargandoLogin}
                >
                    <div 
                        className='Btn-Solicitar-RecuperarC'
                        onClick={async () => {
                            enviarDatosLogin(
                                {
                                    "usuario" : txtUsuario,
                                    "contrasenia" : txtContrasenia
                                }
                            )
                        }}
                    >
                        <div className='W700-S18-H24-C004FB8'>
                            Iniciar Sesión
                        </div>
                    </div>
                </Spin>

            </div>



            
        </div>
    )
}

export default LoginNuevo