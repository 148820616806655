import React, {useState, useRef} from 'react'
import { Row, Col, Button, notification, Modal } from 'antd'
import BtnDescarga from '../../Componentes/Descargar/BtnDescarga'
import ModalNotaCredito from '../../Componentes/Descargar/ModalNotaCredito'
import ModalSell from '../../Componentes/Descargar/ModalSell'
import {
    SelectDownloadDescargarReducer, 
    AccederDescargaNotaCreditoReducer, 
    AccederDescargaSellReducer
} from '../../Redux/Actions/Descarga/Descarga'
import {
    ObtenerDescargaSubsidiosSiReducer
} from '../../Redux/Actions/SubsidiosSi/SubsidiosSi'
import {
    ObtenerLinkHistoricoSubsidiosSIVentas
} from '../../Redux/Actions/SubsidiosSi/SubsidiosSi'
import {useDispatch, useSelector} from "react-redux"
import {
    ArrowDownOutlined,
} from '@ant-design/icons'
import FiltroFechaXmesXanio from '../../Componentes/Elementos/FiltroFechaXmesXanio/FiltroFechaXmesXanio'
import ModalFiltrosSo from '../../Componentes/SubsidiosSo/ModalFiltrosSo'
import ReactExport from 'react-data-export'
import FiltroFechaTop from '../../Componentes/Top/FiltroFechaTop'
import ChatWithUs from '../../Componentes/Elementos/Chat/ChatWithUs'
import Moment from 'moment'
import 'moment/locale/es'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const Descarga = () => {

    const dispatch = useDispatch()

    const {
        list_downloads_descarga,
        download_selected_descarga,
        rex_data_sell_out,
    } = useSelector(({descarga}) => descarga)

    const {
        cargando_descargable_subsidiosso
    } = useSelector(({subsidiosSo}) => subsidiosSo)

    const {
        data_descarga_subsidiossi,
        cargando_descarga,
        cargando_subsidiossi_ventas
    } = useSelector(({subsidiosSi}) => subsidiosSi)

    const {rex_obtener_fecha} = useSelector(({login}) => login);

    const {
        ComunesFechaInicio,
        ComunesAnioTxtIncio,
        ComunesMesTxtInicio,
        ComunesAnioTxtFinal,
        ComunesMesTxtFinal,
        ComunesFechaFinal,
    } = useSelector(({comunes}) => comunes)

    const [mostrarModalFiltroColumnasDescargable, setMostrarModalFiltroColumnasDescargable] = useState(false)
    const [linkDescargarSubVentas, setLinkDescargarSubVentas] = useState(true)
    const [abrirModalNotaCredito, setAbrirModalNotaCredito] = useState(false)
    const [abrirModalNoDataNotaCredito, setAbrirModalNoDataNotaCredito] = useState(false)
    const [abrirModalNoDataSell, setAbrirModalNoDataSell] = useState(false)
    const [modalMensajeSell, setModalMensajeSell] = useState("")

    const [fechaInicioSell, setFechaInicioSell] = useState(null)
    const [fechaFinSell, setFechaFinSell] = useState(null)
    const [anioSeleccionado, setAnioSeleccionado] = useState("")
    const [mesSeleccionado, setMesSeleccionado] = useState("")
    const [mesNumSeleccionado, setMesNumSeleccionado] = useState(null)
    const [cargandoBtnDescargar, setCargandoBtnDescargar] = useState(false)

    const notificacionDescarga = (type, $message) => {
        if(type === 'warning'){
            notification[type]({
                message: 'Precaución',
                description: $message,
            })
        }else if(type === 'error'){
            notification[type]({
                message: 'Error',
                description: 'Error.',
            })
        }
    }
    
    let inputFileSIRef = useRef(null)
    const inputDescargaLinkSubsidiosVentas = useRef(null)

    return (
        <div
            style={{
                paddingLeft:'60px',
                paddingRight:'60px',
                paddingBottom:'60px',
                marginBottom:'420px'
            }}
        >

            <Row style={{marginBottom:'20px'}}>
                <Col xl={24}>
                    <div 
                        style={{
                            background: "#EDF0FA", height:'45px', marginLeft:'-60px', marginRight:'-60px',
                            paddingLeft:'60px',
                            paddingRight:'60px',
                        }}
                    >
                        <Row>
                            <Col 
                                xl={4}
                                className={"Wbold-S20-H27-C004FB8 Contenedor-Cabecera-Status-Home"}
                                style={{marginRight:'20px', justifyContent: "left"}}
                            >
                                {"Descargar"}
                            </Col> 
                        </Row>
                    </div>

                </Col>
            </Row>

            <div>
                <div className='W600-S14-H19-CC4C4C4'>
                    Filtro aplicado: {download_selected_descarga} {">"} Descargar
                </div>
                <Row style={{marginTop:'20px', marginBottom:'10px'}}>
                    {
                        download_selected_descarga == "Nota de Crédito"
                        ?   <Col xl={12}>
                                <FiltroFechaXmesXanio 
                                    anioSeleccionado = {anioSeleccionado}
                                    mesSeleccionado = {mesSeleccionado}
                                    mesNumSeleccionado={mesNumSeleccionado}
                                    setAnioSeleccionado = {setAnioSeleccionado}
                                    setMesSeleccionado = {setMesSeleccionado}
                                    setMesNumSeleccionado={setMesNumSeleccionado}
                                />
                            </Col>
                        :download_selected_descarga == "Histórico de Subsidios"
                        ?null
                        :<>
                            <Col 
                                xl={5} 
                                style={{display:'flex', alignItems: "center",}}
                                className="Wbold-S13-H17-C004FB8"
                            >
                                <FiltroFechaTop 
                                    texto = {"Fecha Inicio"}
                                    setFechaInicioSell={setFechaInicioSell}
                                />
                            </Col>
                            <Col 
                                xl={5} 
                                style={{display:'flex', alignItems: "center" }}
                                className="Wbold-S13-H17-C004FB8"
                            >
                                <FiltroFechaTop 
                                    texto = {"Fecha Fin"}
                                    setFechaFinSell={setFechaFinSell}
                                />
                            </Col>
                        </>
                    }
                </Row>
                <div>
                    <Row>
                        <Col xl={20} style={{display:'flex', flexWrap: "wrap"}}>
                            {
                                list_downloads_descarga.map((list, pos) => {
                                    return (
                                        <BtnDescarga 
                                            title = {list.download}
                                            seleccionado = {list.selected}
                                            click = {() => dispatch(SelectDownloadDescargarReducer(pos))}
                                        />
                                    )
                                })
                            }
                        </Col>
                        <Col xl={4}> 
                            <Button 
                                style={{marginTop:'10px'}}
                                className='Btn-Download-Descargar W700-S13-H17-CFF8023'
                                onClick={async () => {
                                    if(download_selected_descarga == "Subsidios Sell Out"){
                                        let fechaEnvioOut = fechaInicioSell ? fechaInicioSell : Moment(ComunesFechaInicio).format('YYYY-MM')
                                        let respuestaSellOut = await dispatch(AccederDescargaSellReducer(fechaEnvioOut, "sellOut"))
                                        setModalMensajeSell("Sell Out")
                                        if(respuestaSellOut){
                                            setMostrarModalFiltroColumnasDescargable(true)
                                        }else{
                                            setAbrirModalNoDataSell(true)
                                        }
                                    }else if(download_selected_descarga == "Subsidios Sell In"){
                                        let fechaEnvioIn = fechaInicioSell ? fechaInicioSell : Moment(ComunesFechaInicio).format('YYYY-MM')
                                        let respuestaSellIn = await dispatch(AccederDescargaSellReducer(fechaEnvioIn, "sellIn"))
                                        setModalMensajeSell("Sell In")
                                        if(respuestaSellIn){
                                            await dispatch(ObtenerDescargaSubsidiosSiReducer())
                                            inputFileSIRef.current.click();
                                        }else{
                                            setAbrirModalNoDataSell(true)
                                        }
                                    }else if(download_selected_descarga == "Nota de Crédito"){
                                        let respuesta = await dispatch(AccederDescargaNotaCreditoReducer(anioSeleccionado, mesSeleccionado))
                                        if(respuesta){
                                            setAbrirModalNotaCredito(true)
                                        }else{
                                            setAbrirModalNoDataNotaCredito(true)
                                        }
                                    }else if(download_selected_descarga == "Histórico de Subsidios"){
                                        setCargandoBtnDescargar(true)
                                        let linkDescargar = await dispatch(ObtenerLinkHistoricoSubsidiosSIVentas())
                                        await setLinkDescargarSubVentas(linkDescargar)
                                        await setTimeout( async () => {  
                                            setCargandoBtnDescargar(false)
                                            inputDescargaLinkSubsidiosVentas.current.click()
                                        }, 1000)
                                    }
                                }}
                                loading={cargandoBtnDescargar || cargando_descargable_subsidiosso || cargando_descarga || cargando_subsidiossi_ventas}
                            >
                                <div>Descarga</div>
                                <div className='Icon-Download-Descargar'>
                                    <ArrowDownOutlined />
                                </div>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            
            <ModalFiltrosSo 
                mostrar = {mostrarModalFiltroColumnasDescargable}
                setmostrar = {(e) => setMostrarModalFiltroColumnasDescargable(e)}
            />

            {/* DESCARGAR SUBSIDIOS SI */}

            <ExcelFile 
                filename="Subsidios Si"
                element={
                    <div 
                        style={{display:'none'}}
                        ref={inputFileSIRef}
                    >
                        Click
                    </div>
                }>
                <ExcelSheet 
                    dataSet={data_descarga_subsidiossi} 
                    name="Subsidios Si"
                />
            </ExcelFile>

            {/* HISTORICO SUBSIDIOS SI */}
            <a 
                href={linkDescargarSubVentas}
                download
                ref={inputDescargaLinkSubsidiosVentas}
                style={{
                    display:'none'
                }}
            >click</a>

            <ChatWithUs />
            
            <ModalNotaCredito 
                abrirModalNotaCredito={abrirModalNotaCredito}
                setAbrirModalNotaCredito={setAbrirModalNotaCredito}
                notificacionDescarga={notificacionDescarga}
                anioSeleccionado={anioSeleccionado}
                mesSeleccionado={mesSeleccionado}
                dispatch={dispatch}
            />


            <Modal
                width={380}
                centered
                className="modalNotaCreditoDescarga sinData"
                title="Sin Información"
                closable={false}
                visible={abrirModalNoDataNotaCredito}
                footer={[
                    <Button 
                        className="btnNotaCreditoDescarga"
                        onClick={() => setAbrirModalNoDataNotaCredito(!abrirModalNoDataNotaCredito)}
                    >
                        Listo
                    </Button>
                ]}
            >
                <div style={{padding: '0 35px', textAlign: 'center'}}>
                    No hay informacion de Nota de Crédito del <br/>mes seleccionado porque no se encuentra<br/>actualizado el Sell In
                </div>
            </Modal>

            <ModalSell 
                abrirModalNoDataSell={abrirModalNoDataSell}
                setAbrirModalNoDataSell={setAbrirModalNoDataSell}
                rex_data_sell_out={rex_data_sell_out}
                modalMensajeSell={modalMensajeSell}
            />

        </div>
    )
}

export default Descarga