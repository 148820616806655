
export const OBTENER_SUBSIDIOS_SO = 'OBTENER_SUBSIDIOS_SO'
export const OBTEMER_DESCARGABLE_SUBSIDIOS_SO = 'OBTEMER_DESCARGABLE_SUBSIDIOS_SO'
export const OBTENER_FILTROS_SUBSIDIOS_SO = 'OBTENER_FILTROS_SUBSIDIOS_SO'
export const SELECCIONAR_CLIENTE_FILTRO_SUBSIDIOS_SO = 'SELECCIONAR_CLIENTE_FILTRO_SUBSIDIOS_SO'
export const CARGANDO_DATA_SUBSIDIOS_SO = 'CARGANDO_DATA_SUBSIDIOS_SO'
export const CARGANDO_DESCARGABLE_SUBSIDIOS_SO = 'CARGANDO_DESCARGABLE_SUBSIDIOS_SO'


export const SELECCIONAR_PRODUCTO_FILTRO_SUBSIDIOS_SO = "SELECCIONAR_PRODUCTO_FILTRO_SUBSIDIOS_SO"
export const SELECCIONAR_CATEGORIA_FILTRO_SUBSIDIOS_SO = "SELECCIONAR_CATEGORIA_FILTRO_SUBSIDIOS_SO"
export const SELECCIONAR_TERRITORIO_FILTRO_SUBSIDIOS_SO = "SELECCIONAR_TERRITORIO_FILTRO_SUBSIDIOS_SO"
export const SELECCIONAR_ZONA_FILTRO_SUBSIDIOS_SO = "SELECCIONAR_ZONA_FILTRO_SUBSIDIOS_SO"
export const CARGANDO_ARCHIVO_EXCEPCIONES_SO = "CARGANDO_ARCHIVO_EXCEPCIONES_SO"
export const FECHA_ACTUALIZACION_SO = "FECHA_ACTUALIZACION_SO"
