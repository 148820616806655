import React, {useEffect, useState, useRef} from 'react'
import { Button, Col, Row, Table, Form, Input, Switch } from 'antd'
import Column from 'antd/lib/table/Column'
import {useDispatch, useSelector} from "react-redux"
import {
    CameraOutlined,
    GlobalOutlined,
} from '@ant-design/icons'
import {
    CrearDataAdministrarPaisesReducer,
    ActualizarDataAdministrarPaisesReducer,
} from '../../../Redux/Actions/Administrar/Paises/Paises'

const TablaAcciones = (props) => {
    const activoSwitch = props.activoSwitch
    const imageUrl = props.imageUrl
    const notificacionPrecaucion = props.notificacionPrecaucion
    const setImageUrl = props.setImageUrl
    const idPais = props.idPais
    const vaciarFormulario = props.vaciarFormulario
    const setActivoSwitch = props.setActivoSwitch
    const form = props.form

    const dispatch = useDispatch()

    const {
        rex_spin_acciones_paises,
    } = useSelector(({administrarPaises}) => administrarPaises)

    const refBtnImagen = useRef(null)

    const formato = (type) => {
        if(type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg' || type === 'image/gif' || type === 'image/svg'){
            return true
        }else{
            return false
        }
    }

    const imgChangePerfil = (file) => {
        if(file){
            const errorType = formato(file.type)
            if(!errorType){
                notificacionPrecaucion('warning','Solo debes cargar archivos JPG/PNG/JPEG,GIF,SVG')
                return false
            }
            const fileSize = file.size / 1024 / 1024 < 2
            if (!fileSize) {
                notificacionPrecaucion('warning','El archivo debe ser menor a 2MB')
                return false
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function() {
                const base64 = reader.result
                setImageUrl(base64)
            }
        }
    }

    const enviarFormularioPais = async (values) => {
        if(idPais){
            let respuesta = await dispatch(ActualizarDataAdministrarPaisesReducer(
                idPais,
                values.nombre, 
                values.prefijo,
                activoSwitch ? 1 : 2,
                imageUrl,
            ))
            if(respuesta){
                vaciarFormulario()
            }
        }else{
            if(imageUrl){
                let respuesta = await dispatch(CrearDataAdministrarPaisesReducer(
                    values.nombre, 
                    values.prefijo,
                    activoSwitch ? 1 : 2,
                    imageUrl,
                ))
                if(respuesta){
                    vaciarFormulario()
                }
            }else{
                notificacionPrecaucion('warning','Por favor ingresar una imagen')
            }
        }
    }

    const dataSource = [{}]

    return(
        <Table 
            className="table-administrarUsuarios administrarPaises"
            dataSource={dataSource} 
            pagination={false}
        >
            <Column 
                title={(
                    <div
                        className="table-formularioAdministrarUsuario"
                    >
                        <GlobalOutlined style={{ fontSize: '20px' }} />
                        <p>Pais</p>
                    </div>
                )}
                className="table-thformulario"
                dataIndex="form" 
                key="form" 
                render={() => (
                    <Form
                        form={form}
                        initialValues={{
                            nombre: '',
                            prefijo: '',
                        }}
                        autoComplete="off"
                        onFinish={enviarFormularioPais}
                        onValuesChange={false}
                        className="formularioEnvioModalPaises"
                    >
                        <Row>
                            <Col 
                                sm={16}
                                xs={24}
                                style={{
                                    marginTop: '10px',
                                }}
                            >
                                <Form.Item 
                                    labelCol={{
                                        md: {span: 9},
                                        sm: {span: 8},
                                        xs: {span: 9},
                                    }}
                                    labelAlign="left"
                                    wrapperCol={{
                                        md: {span: 12},
                                        sm: {span: 14},
                                        xs: {span: 15},
                                    }} 
                                    name="nombre"
                                    className="form-labelPaises"
                                    label="Nombre"
                                    
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Por favor ingrese un nombre',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item 
                                    labelCol={{
                                        md: {span: 9},
                                        sm: {span: 8},
                                        xs: {span: 9},
                                    }}
                                    labelAlign="left"
                                    wrapperCol={{
                                        md: {span: 12},
                                        sm: {span: 14},
                                        xs: {span: 15},
                                    }} 
                                    name="prefijo"
                                    className="form-labelPaises"
                                    label="Prefijo"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Por favor ingrese un prefijo',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    labelCol={{
                                        md: {span: 9},
                                        sm: {span: 8},
                                        xs: {span: 9},
                                    }}
                                    labelAlign="left"
                                    wrapperCol={{
                                        md: {span: 12},
                                        sm: {span: 14},
                                        xs: {span: 15},
                                    }} 
                                    className="form-labelPaises switchLabelPaises"
                                    style={{margin: 0}}
                                    label={
                                        <p className="textoSwitchLabelPaises">
                                            Estados
                                        </p>
                                    }
                                >
                                    <p style={{ marginBottom:'0' }}>
                                        {activoSwitch ? "Activo" : "Inactivo"}
                                    </p>
                                    <Switch 
                                        defaultChecked={activoSwitch}
                                        checked={activoSwitch}
                                        className="form-switchAdministrarUsuario" 
                                        onChange={(e) => setActivoSwitch(e)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col 
                                sm={7}
                                xs={24} 
                                className="colAdministrarImagen"
                            >
                                <div 
                                    className="containerImagePerfil" 
                                >
                                    <figure 
                                        className="imagePicturePerfil" 
                                        onClick={() => refBtnImagen.current.click()}
                                    >
                                        <input 
                                            type="file" 
                                            ref={refBtnImagen} 
                                            className="inputImagePerfil" 
                                            onChange={(e) => imgChangePerfil(e.target.files[0])}
                                        />
                                        {
                                            imageUrl
                                            ?   <img
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                    }}
                                                    src={imageUrl}
                                                />
                                            :   <CameraOutlined 
                                                    style={{
                                                        fontSize:'60px', 
                                                        color:'#004FB8'
                                                    }} 
                                                />
                                        }
                                    </figure>
                                </div>
                            </Col>
                            <Col xs={1}></Col>
                            <Col 
                                xs={23}
                                style={{
                                    display: 'flex', 
                                    justifyContent: 'end', 
                                    columnGap: '15px',
                                    margin: '25px 0 8px 0',
                                }}
                            >
                                <Form.Item style={{marginBottom: '0'}}>
                                    <Button
                                        htmlType="submit" 
                                        type="primary"
                                        shape="round"
                                        loading={rex_spin_acciones_paises}
                                    >
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                )} 
            />
        </Table>
    )
}

export default TablaAcciones