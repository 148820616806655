import config from '../../../config'
import {
    CARGANDO_EDITAR_CONTRASENIA_PERFIL,
    CARGANDO_EDITAR_CUMPLEANIOS_PERFIL,
    CARGANDO_EDITAR_TELEFONO_PERFIL,
    CARGANDO_EDITAR_IMAGEN_PERFIL
} from '../../../Constantes/Perfil/Perfil'
import { estadoRequestReducer } from "../EstadoRequest"
import { notification } from 'antd';
import {LoginReducer} from '../Login/Login'

const notificacionServidor = (type, mensaje, errores) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning' && errores){
        notification['warning']({
            message: 'Advertencia',
            description:<>
                            {mensaje}<br/>
                            {errores.map((e) => <>- {e}<br/></>)}
                        </>,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: 'Error con el servidor. Vuelva a ingresar más tarde.',
        })
    }
}

export const EditarCamposPerfilReducer = (
    campoEditar,
    contraseniaActual,
    contrasenia,
    txtContraseniaConfirmar,
    cumpleanios,
    telefono,
    prefijo,
) => async (dispatch, getState) => {

    let editarCampo = false

    if(campoEditar == 1){
        dispatch({
            type: CARGANDO_EDITAR_CONTRASENIA_PERFIL,
            payload : true
        })
    }else if(campoEditar == 2){
        dispatch({
            type: CARGANDO_EDITAR_CUMPLEANIOS_PERFIL,
            payload : true
        })
    }else if(campoEditar == 3){
        dispatch({
            type: CARGANDO_EDITAR_TELEFONO_PERFIL,
            payload : true
        })
    }

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    if(config.produccion == true){
        headerFetch = {
            'Accept' : 'application/json',
            'content-type': 'application/json',
            'api_token': localStorage.getItem('usutoken'),
            'api-token': localStorage.getItem('usutoken'),
        }
    }

    await fetch(config.api+'modulo/perfil/editar',
		{
			mode:'cors',
			method: 'POST',
			body: JSON.stringify({
                campoEditar : campoEditar,
                contraseniaActual : contraseniaActual,
                contrasenia : contrasenia,
                contrasenia_confirmation : txtContraseniaConfirmar,
                cumpleanios : cumpleanios,
                telefono    : telefono,
                prefijo    : prefijo,
            }),
			headers: headerFetch
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {

		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
			if(data.respuesta == true){
                notificacionServidor('success',data.mensaje)
                if(campoEditar == 1){
                    await dispatch(LoginReducer({usuario: localStorage.getItem('usuario'), contrasenia: contrasenia}))
                }
                editarCampo = true;
            }else{
                notificacionServidor('warning',data.mensaje)
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
        }
    }).catch((error)=> {
        notificacionServidor('error')
    });

    if(editarCampo == true){
        if(campoEditar == 2 || campoEditar == 3){
            await dispatch(LoginReducer({usuario: localStorage.getItem('usuario'), contrasenia: localStorage.getItem('contrasenia')}))
        }
    }

    if(campoEditar == 1){
        dispatch({
            type: CARGANDO_EDITAR_CONTRASENIA_PERFIL,
            payload : false
        })
    }else if(campoEditar == 2){
        dispatch({
            type: CARGANDO_EDITAR_CUMPLEANIOS_PERFIL,
            payload : false
        })
    }else if(campoEditar == 3){
        dispatch({
            type: CARGANDO_EDITAR_TELEFONO_PERFIL,
            payload : false
        })
    }

    return editarCampo
}

export const EditarImagenUsuarioPerfilReducer = (imagen) => async (dispatch, getState) => {

    dispatch({
        type: CARGANDO_EDITAR_IMAGEN_PERFIL,
        payload: true
    })

    let respuesta = false

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    if(config.produccion == true){
        headerFetch = {
            'Accept' : 'application/json',
            'content-type': 'application/json',
            'api_token': localStorage.getItem('usutoken'),
            'api-token': localStorage.getItem('usutoken'),
        }
    }

    await fetch(config.api+'modulo/perfil/editar/imagen',
		{
			mode:'cors',
			method: 'POST',
			body: JSON.stringify({
                'imagen' : imagen
            }),
			headers: headerFetch
		}
	)
	.then( async res => {
        
        await dispatch(estadoRequestReducer(res.status))
		return res.json()
		
	})
	.then(async data => {
        console.log(data)
        const estadoRequest = getState().estadoRequest.init_request

		if(estadoRequest == true){

            if(data.respuesta == true){
                notificacionServidor('success',data.mensaje)
                await dispatch(LoginReducer({usuario: localStorage.getItem('usuario'), contrasenia: localStorage.getItem('contrasenia')}))
                respuesta = true

            }else{
                notificacionServidor('warning', data.mensaje)
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
        }
	}).catch((error)=> {
        notificacionServidor('error')
	})

    dispatch({
        type: CARGANDO_EDITAR_IMAGEN_PERFIL,
        payload: false
    })

    return respuesta
    
}