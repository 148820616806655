import React, {useEffect, useState, useRef} from 'react'
import ReactExport from "react-data-export"
import { Button, Col, Pagination, Row, DatePicker, notification, Form } from 'antd'
import { 
    PlusCircleOutlined,
} from '@ant-design/icons'
import Search from 'antd/lib/input/Search'
import Title from 'antd/lib/typography/Title'
import BotonesRutas from '../../../Componentes/Administrar/BotonesRutas'
import Moment from 'moment'
import 'moment/locale/es'
import locale from 'antd/es/date-picker/locale/ca_ES'
import {useDispatch, useSelector} from "react-redux"
import IdleTimer from 'react-idle-timer'
import '../../../Estilos/Rutas/Administrar/Usuarios/Usuarios.css'
import {
    ObtenerDataTablaAdministrarUsuariosReducer,
    ObtenerDataExcelUsuariosAdministrarUsuariosReducer,
    ObtenerDataExcelAuditoriasAdministrarUsuariosReducer,
    ObtenerListaTiposUsuariosReducer,
    CambiarEstadoUsuariosReducer,
} from '../../../Redux/Actions/Administrar/Usuarios/Usuarios'
import {
    ObtenerDataAdministrarPaisesReducer,
} from '../../../Redux/Actions/Administrar/Paises/Paises'
import TablaLista from '../../../Componentes/Administrar/Usuarios/TablaLista'
import TablaAcciones from '../../../Componentes/Administrar/Usuarios/TablaAcciones'

const Usuarios = () => {
    const ExcelFile = ReactExport.ExcelFile
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
    const dispatch = useDispatch()

    const [numeroPagina, setNumeroPagina] = useState(1)
    const [txtBuscar, setTxtBuscar] = useState()
    const [idEditarUsuario, setIdEditarUsuario] = useState()
    const [activoSwitch, setActivoSwitch] = useState(1)
    const [captarOnchangeBuscar, setCaptarOnchangeBuscar] = useState(false)
    const [fechaIngresoLogin, setFechaIngresoLogin] = useState(Moment().format('YYYY-MM'))

    const [form] = Form.useForm()

    const {
        rex_data_tabla_usuarios,
        rex_data_excel_usuarios,
        rex_data_excel_auditorias_usuarios,
    } = useSelector(({administrarUsuarios}) => administrarUsuarios)

    useEffect(() => {
        dispatch(ObtenerDataTablaAdministrarUsuariosReducer(numeroPagina))
        dispatch(ObtenerListaTiposUsuariosReducer())
        dispatch(ObtenerDataAdministrarPaisesReducer())
        dispatch(CambiarEstadoUsuariosReducer(true, false, false, false, true))
    }, [])

    const refBtnDescargar = useRef(null)
    const refBtnDescargarAuditoria = useRef(null)

    const exportarExcel = async () => {
        await dispatch(ObtenerDataExcelUsuariosAdministrarUsuariosReducer())
        refBtnDescargar.current.click()
    }

    const exportarAuditoria = async () => {
        let respuesta = await dispatch(ObtenerDataExcelAuditoriasAdministrarUsuariosReducer(fechaIngresoLogin))
        if(respuesta){
            refBtnDescargarAuditoria.current.click()
        }
    }

    const vaciarFormulario = () => {
        form.setFieldsValue({
            nombre: '',
            apellido: '',
            correoPersonal: '',
            correoCorporativo: '',
            contrasenia: '',
            celular: '',
            tipoUsuario: '',
            fechaInicio: '',
            fechaFin: '',
            pais: '',
            correoBienvenida: false,
        })
        setActivoSwitch(1)
    }

    const notificacionPrecaucionUsuario = (type, mensaje) => {
        const key = `open${Date.now()}`
        const btn = (
            <Button 
                type="primary" 
                onClick={() => {
                    vaciarFormulario()
                    dispatch(CambiarEstadoUsuariosReducer(true, false, false, null, true))
                    notification.close(key)
                }}
            >
              Confirmar
            </Button>
        )
        if(type === 'warning'){
            notification[type]({
                message: 'Crear Usuario',
                description:
                    'No se guardarán los cambios realizados, si desea continuar con la opción de crear.',
                btn,
                key,
            })
        }else if(type === 'warning'){
            notification['warning']({
                message: 'Advertencia',
                description: mensaje,
            })
        }else if(type === 'error'){
            notification[type]({
                message: 'Error',
                description: 'Error al eliminar el usuario.',
            })
        }
    }

    const seleccionarFila = (record) => {
        return {
            onClick: event => {
                form.setFieldsValue({
                    nombre: record.pernombre,
                    apellido: record.perapellidopaterno+' '+record.perapellidomaterno,
                    correoPersonal: record.usucorreo,
                    correoCorporativo: record.usuusuario,
                    celular: record.pernumero,
                    tipoUsuario: record.tpuid,
                    fechaInicio: record.usufechainicio ? Moment(record.usufechainicio) : '',
                    fechaFin: record.usufechafin ? Moment(record.usufechafin) : '',
                    pais: record.paiid,
                    contrasenia: '',
                    correoBienvenida: false,
                })
                setActivoSwitch(record.estid)
                setIdEditarUsuario(record.usuid)
                dispatch(CambiarEstadoUsuariosReducer(false, true, true, Moment(record.usufechainicio), false))
            }
        }
    }

    const paginacionTabla = (paginateBuscar) => {
        setNumeroPagina(paginateBuscar)
        dispatch(ObtenerDataTablaAdministrarUsuariosReducer(paginateBuscar, txtBuscar))
    }

    const crearUsuario = () => {
        if(idEditarUsuario){
            notificacionPrecaucionUsuario('warning')
            setIdEditarUsuario()
        }
    }

    return(
        <>
            <div 
                className="contenedorAdministrarModulo"
            >
                <Title 
                    className="title-controlArchivos"
                >
                    ADMINISTRADOR
                </Title>
                <Row 
                    className="row-button-controlArchivos"
                    gutter={[10, 10]}
                    style={{ 
                        rowGap:'25px',
                        padding: '0 15px 0 15px', 
                        marginTop: '25px',
                        marginBottom:'25px',
                    }}
                >
                    <Col 
                        xl={12}
                        lg={18}
                        md={18}
                        sm={24}
                        xs={{
                            span: 24,
                            order:1
                        }}
                        style={{
                            margin: 'auto 0',
                        }}
                    >
                        <BotonesRutas 
                            ruta="usuario" 
                        />
                    </Col>
                    <Col 
                        xl={{
                            span: 7,
                            offset: 0,
                            order: 2,
                        }}
                        lg={10}
                        md={{
                            span: 12,
                            order: 6,
                        }}
                        sm={16}
                        xs={{
                            span: 24,
                            order: 5,
                        }}
                        style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                        }}
                    >
                        <IdleTimer
                            onIdle={() => {
                                if(captarOnchangeBuscar){
                                    dispatch(
                                        ObtenerDataTablaAdministrarUsuariosReducer(numeroPagina, txtBuscar)
                                    )
                                    setCaptarOnchangeBuscar(false)
                                }
                            }}
                            events={['keydown']}
                            timeout={1000 * 1.5}
                        >
                            <Search
                                placeholder="Buscar"
                                allowClear
                                className="search-controlArchivos"
                                onChange={(e) => {
                                    setTxtBuscar(e.target.value)
                                    setNumeroPagina(1)
                                    setCaptarOnchangeBuscar(true)
                                }}
                            />
                        </IdleTimer>
                    </Col>
                    <Col 
                        xl={{
                            span: 3,
                            order: 3,
                            offset: 0,
                        }}
                        lg={{
                            span: 4,
                            offset: 10,
                        }}
                        md={{
                            span: 5,
                            offset: 7,
                        }}
                        sm={{
                            span: 8,
                            offset: 16,
                        }}
                        xs={{
                            span: 24,
                            order: 7,
                        }}
                        style={{
                            display:'flex', 
                            alignItems:'center', 
                            justifyContent:'end'
                        }}
                    >
                        <Pagination 
                            current={numeroPagina}
                            className="pagination-controlArchivo" 
                            showTotal={(total) => {
                                return(
                                    rex_data_tabla_usuarios.from 
                                    ? rex_data_tabla_usuarios.from + " - " + rex_data_tabla_usuarios.to + " de " + total
                                    : <>0 - 0 de 0</>
                                )
                            }} 
                            size="small"
                            total={rex_data_tabla_usuarios.total} 
                            onChange={paginacionTabla}
                        />
                    </Col>
                    <Col 
                        xl={{
                            span: 2,
                            order: 4,
                            offset: 0
                        }}
                        lg={{
                            span: 3,
                            offset: 3,
                        }} 
                        md={{
                            span: 4,
                            order: 2,
                            offset: 2,
                        }}
                        sm={{
                            span: 4,
                            offset: 4
                        }}
                        xs={{
                            span: 24,
                            order: 6,
                        }}
                        className="colCrearAdministrarUsuarios"
                    >
                        <Button 
                            className="button-administrarUsuario" 
                            shape="round"
                            onClick={crearUsuario}
                        >
                            Crear
                            <PlusCircleOutlined 
                                className="iconButton-administrarUsuario"
                            />
                        </Button>
                    </Col>
                    <Col 
                        className="colAdministrarUsuariosAuditoria"
                        xl={{
                            span: 11,
                            order: 6,
                            offset: 9,
                        }}
                        lg={{
                            span: 14,
                            offset: 5,
                        }}
                        md={{
                            span: 18,
                        }}
                        sm={{
                            span: 24,
                            order: 4,
                        }}
                        xs={{
                            span: 24,
                            order: 3,
                            offset: 0,
                        }}
                    >
                        <DatePicker
                            onChange={(e) => {
                                e 
                                ? setFechaIngresoLogin(Moment(e._d).format('YYYY-MM'))
                                : setFechaIngresoLogin(Moment().format('YYYY-MM'))
                            }}
                            placeholder="Seleccione una fecha"
                            locale={locale} 
                            style={{
                                width: '220px',
                            }}
                            picker="month"
                            className="fechaAdministrarAuditoria"
                        />

                        <ExcelFile 
                            filename="Subsidios - Ingreso a subsidios" 
                            element={
                                <button 
                                    ref={refBtnDescargarAuditoria}
                                    style={{display: "none"}}
                                >
                                    Descargar
                                </button>
                                }
                            >
                            <ExcelSheet 
                                dataSet={rex_data_excel_auditorias_usuarios} 
                                name="Registro de ingresos a subsidios" 
                            />
                        </ExcelFile>

                        <Button 
                            className="button-controlArchivos" 
                            onClick={exportarAuditoria}
                            shape="round"
                        >
                            Exportar ingresos a Subsidios
                        </Button>
                    </Col>
                    <Col 
                        className="colAdministrarVista"
                        xl={{
                            span: 4,
                            order: 7,
                        }}
                        lg={{
                            span: 5,
                        }}
                        md={{
                            span: 6,
                            order: 5,
                        }}
                        sm={24}
                        xs={{
                            span: 24,
                            order: 2,
                        }}
                    >
                        <ExcelFile 
                            filename="Subsidios - Usuarios" 
                            element={
                                <button 
                                    ref={refBtnDescargar}
                                    style={{display: "none"}}
                                >
                                    Descargar
                                </button>
                                }
                            >
                            <ExcelSheet 
                                dataSet={rex_data_excel_usuarios} 
                                name="Usuarios de Subsidios" 
                            />
                        </ExcelFile>

                        <Button 
                            className="button-controlArchivos" 
                            onClick={exportarExcel}
                            shape="round"
                        >
                            Exportar Usuarios
                        </Button>
                    </Col>
                </Row>
                <Row style={{rowGap:'40px'}}>
                    <Col
                        lg={12}
                        sm={24}
                    >
                        <TablaLista 
                            vaciarFormulario={vaciarFormulario}
                            seleccionarFila={seleccionarFila}
                            numeroPagina={numeroPagina}
                            txtBuscar={txtBuscar}
                        />
                    </Col>
                    <Col
                        lg={12}
                        sm={24}
                    >
                        <TablaAcciones 
                            form={form}
                            vaciarFormulario={vaciarFormulario}
                            activoSwitch={activoSwitch}
                            setActivoSwitch={setActivoSwitch}
                            idEditarUsuario={idEditarUsuario}
                            setIdEditarUsuario={setIdEditarUsuario}
                            numeroPagina={numeroPagina}
                            txtBuscar={txtBuscar}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Usuarios