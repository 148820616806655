import React, {useState, useRef, useEffect} from 'react'
import '../../Estilos/Perfil/Perfil.css'
import { Row, Col, Modal, Form, Image, notification, DatePicker, Button, Select, Input, Search } from 'antd'
import { CameraOutlined, GlobalOutlined } from '@ant-design/icons'
import IconoCargo from '../../Assets/Imagenes/Iconos/Perfil/iconoCargo.png'
import IconoContrasenia from '../../Assets/Imagenes/Iconos/Perfil/iconoContrasenia.png'
import IconoUsuarioLight from '../../Assets/Imagenes/Iconos/iconoUsuarioLight.svg'
import IconoCorreo from '../../Assets/Imagenes/Iconos/Perfil/iconoCorreo.png'
import IconoCumpleanios from '../../Assets/Imagenes/Iconos/Perfil/iconoCumpleanios.png'
import IconoTelefono from '../../Assets/Imagenes/Iconos/Perfil/iconoTelefono.png'
import IconoUsuario from '../../Assets/Imagenes/Iconos/Perfil/iconoUsuario.png'
import IconoEditar from '../../Assets/Imagenes/Iconos/Perfil/iconoEditar.png'
import {useDispatch, useSelector} from "react-redux";
import {
    EditarCamposPerfilReducer,
    EditarImagenUsuarioPerfilReducer
} from '../../Redux/Actions/Perfil/Perfil'
import {
    ObtenerDataAdministrarPaisesReducer,
    ObtenerListaFiltroPaisesReducer,
} from '../../Redux/Actions/Administrar/Paises/Paises'
import locale from 'antd/es/date-picker/locale/ca_ES'
import Moment from 'moment'
import 'moment/locale/es'


const Perfil = () => {

    const notificacionMensaje = (type, mensaje) => {
        if(type === 'success'){
            notification['success']({
                message: 'Éxito',
                description: mensaje,
            })
        }else if(type === 'warning'){
            notification['warning']({
                message: 'Advertencia',
                description: mensaje,
            })
        }else if(type === 'error'){
            notification['error']({
                message: 'Error',
                description: mensaje,
            })
        }
    }

    const { Option } = Select
    const { Search } = Input
    const dispatch = useDispatch()
    const refBtnImagen = useRef(null)

    const {
        LoginUsuario
    } = useSelector(({login}) => login)

    const {
        cargando_editar_contrasenia,
        cargando_editar_cumpleanios,
        cargando_editar_telefono,
        cargando_editar_perfil,
    } = useSelector(({perfil}) => perfil)

    const [editarContrasenia, setEditarContrasenia] = useState(false)
    const [editarCumpleanios, setEditarCumpleanios] = useState(false)
    const [editarTelefono, setEditarTelefono] = useState(false)

    const [txtContraseniaActual, setTxtContraseniaActual] = useState("")
    const [txtContraseniaNueva, setTxtContraseniaNueva] = useState("")
    const [txtContraseniaConfirmar, setTxtContraseniaConfirmar] = useState("")

    const [txtCumpleanios, setTxtCumpleanios] = useState("")
    const [txtTelefono, setTxtTelefono] = useState("")

    const [validacionTelefono, setValidacionTelefono] = useState(true)

    const [abrirModalImagen, setAbrirModalImagen] = useState(false)
    const [prefijoValor, setPrefijoValor] = useState(null)
    const [prefijoTelefono, setPrefijoTelefono] = useState(null)
    const [imageUrl, setImageUrl] = useState()

    const {
        rex_filtro_tabla_paises,
    } = useSelector(({administrarPaises}) => administrarPaises)

    useEffect(() => {
        dispatch(ObtenerDataAdministrarPaisesReducer())
    }, [])

    const EditarCampo = async (editarCampo) => {
        if(editarCampo == 1){

            if(txtContraseniaNueva == txtContraseniaConfirmar){
                let rpta = await dispatch(EditarCamposPerfilReducer(
                    editarCampo,
                    txtContraseniaActual,
                    txtContraseniaNueva,
                    txtContraseniaConfirmar,
                    null,null,null
                ))

                if(rpta == true){
                    setEditarContrasenia(false)
                }

            }else{
                notificacionMensaje('warning', "Lo sentimos las contraseñas deben ser las mismas")
            }

        }else if(editarCampo == 2){

            let rpta = await dispatch(EditarCamposPerfilReducer(
                editarCampo,
                null,
                null,
                null,
                txtCumpleanios,null, null
            ))

            if(rpta == true){
                setEditarCumpleanios(false)
            }

        }else if(editarCampo == 3){
            if(txtTelefono){
                if(validacionTelefono){
                    if(prefijoValor){
                        let rpta = await dispatch(EditarCamposPerfilReducer(
                            editarCampo,
                            null,
                            null,
                            null,
                            null,txtTelefono,prefijoValor
                        ))
                        if(rpta == true){
                            setEditarTelefono(false)
                        }
                    }else{
                        notificacionMensaje('warning', "Por favor seleccionar un pais")
                    }
                }else{
                    notificacionMensaje('warning', "Lo sentimos el teléfono solo debe contener caracteres numéricos")
                }
            }else{
                notificacionMensaje('warning', "Por favor ingresar un numero telefonico")
            }
        }
    }

    const SubirFoto = async (imagen) => {
        if(imagen){
            let respuesta = await dispatch(EditarImagenUsuarioPerfilReducer(imagen))
            if(respuesta){
                setAbrirModalImagen(false)
            }
        }else{
            notificacionMensaje('warning', "Por favor ingresar una imagen")
        }
    }

    const formato = (type) => {
        if(type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg' || type === 'image/gif' || type === 'image/svg'){
            return true
        }else{
            return false
        }
    }

    const imgChangePerfil = (file) => {
        if(file){
            const errorType = formato(file.type)
            if(!errorType){
                notificacionMensaje('warning','Solo debes cargar archivos JPG/PNG/JPEG,GIF,SVG')
                return false
            }
            const fileSize = file.size / 1024 / 1024 < 2
            if (!fileSize) {
                notificacionMensaje('warning','El archivo debe ser menor a 2MB')
                return false
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function() {
                const base64 = reader.result
                setImageUrl(base64)
            }
        }
    }

    const guardarTelefono = (e) => {
        setTxtTelefono(e)
        if(!isNaN(e)){
            setValidacionTelefono(true)
        }else{
            setValidacionTelefono(false)
        }
    }

    const beforeTelefono = (
        <div style={{display:'flex', alignItems:'start'}}>
            <Form.Item name="prefix" noStyle>
                <Select
                    labelInValue
                    placeholder=""
                    className="selectPrefijoUsuarioPerfil"
                    defaultValue={{
                        value: LoginUsuario.paiid 
                        ? LoginUsuario.paiid
                        : ""
                    }}
                    onChange={(value, option) => {
                        setPrefijoValor(value.value)
                        setPrefijoTelefono(option.title)
                        if(txtTelefono == ""){
                            setTxtTelefono(LoginUsuario.pernumero)
                        }
                    }}
                    dropdownMatchSelectWidth={200}
                    dropdownRender={(menu) => (
                        <>
                            <Search
                                placeholder="Buscar"
                                allowClear
                                style={{
                                    padding: '5px 10px',
                                }}
                                onChange={(e) => dispatch(ObtenerListaFiltroPaisesReducer(e.target.value))}
                            />
                            {menu}
                        </>
                    )}
                >
                    {
                        rex_filtro_tabla_paises.length
                        ?   <Option value="" title="">
                                <GlobalOutlined style={{fontSize: '20px', marginRight: '10px', verticalAlign: 'sub',}} />
                                Ingrese un pais
                            </Option>
                        :   null
                    }
                    {   rex_filtro_tabla_paises.map((p, index) => 
                        <Option key={index} value={p.paiid} title={p.paiprefijo}>
                            <img 
                                src={p.paiicono} 
                                style={{width:'20px', marginRight:'10px', verticalAlign: 'baseline',}} 
                            />
                            {`${p.painombre} (${p.paiprefijo})`}
                        </Option>
                        )
                    }
                </Select>
            </Form.Item>
            <Input 
                value={
                    prefijoTelefono || prefijoTelefono == ""
                    ? prefijoTelefono
                    : LoginUsuario.paiprefijo
                        ? LoginUsuario.paiprefijo
                        : ""
                }
                className="inputPrefijoUsuarioPerfil"
                disabled 
            />
        </div>
    )

    return (
        <div style={{position:'relative'}}>
           
            <div id="Banner-Perfil">
                <div style={{position:'absolute', width:'100%', height:'100%', textAlign: "-webkit-center"}}>
                    <div id="Contenedor-Imagen-Perfil">
                        {
                            LoginUsuario.usuimagen || IconoUsuarioLight
                            ?   <Image
                                    src={LoginUsuario.usuimagen ? `${LoginUsuario.usuimagen}` : IconoUsuarioLight}
                                    style={{
                                        height: '100%',
                                        backgroundColor: LoginUsuario.usuimagen
                                            ?   ''
                                            :   '#ff811e'
                                    }}
                                />
                            :   null
                        }
                        <div id="Contenedor-Camara-Imagen-Perfil">
                            <Button 
                                id="Icono-Camara-Perfil"
                                className="iconoImagenPerfil"
                                onClick={() => setAbrirModalImagen(true)} 
                                icon={<CameraOutlined />} 
                            />
                        </div>
                    </div>
                
                    <div className="Wbold-S15-H20-C004FB8">
                        {LoginUsuario.pernombrecompleto}
                    </div>
                    <div className="Wnormal-S13-H17-C004FB8">
                        {LoginUsuario.tpunombre}
                    </div>
                </div>
            </div>

            <div id="Contenedor-Principal-Informacion-Basica-Perfil">
                
                <div id="Contenedor-Informacion-Basica-Perfil" >

                    <div className="Wbold-S15-H20-C004FB8 tituloUsuarioPerfil">Información básica</div>

                    <div id="Card-Informacion-Basica-Perfil">
                        <Row className="Fila-Tabla-Perfil">
                            <Col className="Wbold-s13-17-C004fb8" sm={12} xs={24}>
                                <img src={IconoUsuario} className="Iconos-Perfil" />
                                Nombre
                            </Col>
                            <Col
                                style={{alignSelf: "center"}}
                                className="Wnormal-S13-H17-C706C64" sm={12} xs={24}
                            >
                                {LoginUsuario.pernombrecompleto}
                            </Col>
                        </Row>
                        <Row className="Fila-Tabla-Perfil">
                            <Col className="Wbold-s13-17-C004fb8" sm={12} xs={24}>
                                <img src={IconoCorreo} className="Iconos-Perfil" />
                                Correo electrónico
                            </Col>
                            <Col
                                style={{alignSelf: "center"}}
                                className="Wnormal-S13-H17-C706C64" 
                                sm={12} xs={24}
                            >
                                {LoginUsuario.usucorreo}
                            </Col>
                        </Row>
                        <Row className="Fila-Tabla-Perfil">
                            <Col className="Wbold-s13-17-C004fb8" sm={12} xs={24}>
                                <img src={IconoCargo} className="Iconos-Perfil" />
                                Cargo
                            </Col>
                            <Col
                                style={{alignSelf: "center"}}
                                className="Wnormal-S13-H17-C706C64" 
                                sm={12} xs={24}
                            >
                                {LoginUsuario.tpunombre}
                            </Col>
                        </Row>
                        <Row className={editarContrasenia == true?"Fila-Tabla-Editar-Perfil":"Fila-Tabla-Perfil"}>
                            <Col className="Wbold-s13-17-C004fb8" sm={12} xs={24}>
                                <img src={IconoContrasenia} className="Iconos-Perfil" />
                                Contraseña
                                {
                                    editarContrasenia == true
                                    ?<div 
                                        className="Wnormal-S13-H17-C706C64" 
                                        style={{marginLeft:'25px', marginRight:'25px'}} 
                                    >
                                        Su contraseña tiene que tener mayúsculas o minúsculas pero no espaccios en blanco
                                    </div>
                                    :null
                                }
                            </Col>
                            <Col 
                                className="Wnormal-S13-H17-C1EC0ED" sm={12} xs={24} 
                                style={editarContrasenia == true ?{alignItems: "center"} :{display:'flex', alignItems: "center"}}
                                
                            >
                                {
                                    editarContrasenia == true
                                    ?<>
                                        <div className="W600-S13-H17-C1876F2">* Contraseña actual</div>
                                        <Input.Password 
                                            onChange={(e) => setTxtContraseniaActual(e.target.value)}
                                            className="contraseniaUsuarioPerfil"
                                        />
                                        <div className="W600-S13-H17-C1876F2">* Nueva contraseña</div>
                                        <Input.Password 
                                            onChange={(e) => setTxtContraseniaNueva(e.target.value)}
                                            className="contraseniaUsuarioPerfil"
                                        />
                                        <div className="W600-S13-H17-C1876F2">* Confirmar nueva contraseña</div>
                                        <Input.Password 
                                            onChange={(e) => setTxtContraseniaConfirmar(e.target.value)}
                                            className="contraseniaUsuarioPerfil"
                                        />
                                        <div style={{display:'flex', justifyContent: "right", marginTop:'10px'}}>
                                            <Button 
                                                loading={cargando_editar_contrasenia}
                                                onClick={() => EditarCampo(1)}
                                                id="Btn-Guardar-Editar-Perfil"
                                                disabled={
                                                    !txtContraseniaActual && !txtContraseniaNueva &&
                                                    !txtContraseniaConfirmar
                                                    ? true 
                                                    : false
                                                }
                                                className={
                                                    !txtContraseniaActual && !txtContraseniaNueva &&
                                                    !txtContraseniaConfirmar
                                                    ? 'desabilitarPerfil' 
                                                    : ''
                                                }
                                            >
                                                    <span className="W600-S13-H17-C004FB8">Guardar</span>
                                            </Button>
                                            <div
                                                onClick={() => setEditarContrasenia(false)}
                                                id="Btn-Cancelar-Editar-Perfil" 
                                                className="W600-S13-H17-C004FB8" >Cancelar</div>
                                        </div>
                                        
                                    </>
                                    :<>
                                        <div style={{width:'100%'}}>Cambiar contraseña</div>
                                        <div style={{width:'100%', textAlign: "-webkit-right"}}>
                                            <img
                                                onClick={() => setEditarContrasenia(true)} 
                                                src={IconoEditar} className="Iconos-Editar-Perfil" />
                                        </div>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row className={editarCumpleanios == true?"Fila-Tabla-Editar-Perfil":"Fila-Tabla-Perfil"}>
                            <Col 
                                className="Wbold-s13-17-C004fb8" sm={12} xs={24}
                            >
                                <img src={IconoCumpleanios} className="Iconos-Perfil" />
                                Cumpleaños
                            </Col>
                            <Col 
                                className="Wnormal-S13-H17-C706C64" sm={12} xs={24} 
                                style={
                                    editarCumpleanios == true
                                    ?{}
                                    :{display:'flex', alignItems: "center"}
                                }
                            >

                                {
                                    editarCumpleanios == true
                                    ?<>
                                        <DatePicker 
                                            locale={locale}
                                            defaultValue={
                                                LoginUsuario.percumpleanios
                                                ? Moment(LoginUsuario.percumpleanios)
                                                : null
                                            }
                                            placeholder="Cumpleaños"
                                            onChange={
                                                (e) => setTxtCumpleanios(
                                                    e 
                                                    ? Moment(e._d).format("YYYY/MM/DD")
                                                    : ""
                                                )
                                            } 
                                            className="cumpleaniosUsuarioPerfil" format='DD/MM/YYYY' 

                                        />
                                        <div style={{display:'flex', justifyContent: "right", marginTop:'10px'}}>
                                            <Button 
                                                id="Btn-Guardar-Editar-Perfil" 
                                                loading={cargando_editar_cumpleanios}
                                                onClick={() => EditarCampo(2)}
                                                disabled={!txtCumpleanios ? true : false}
                                                className={!txtCumpleanios ? 'desabilitarPerfil' : ''}
                                            >
                                                <span className="W600-S13-H17-C004FB8"
                                                >Guardar</span>
                                            </Button>
                                            <div
                                                onClick={() => setEditarCumpleanios(false)}
                                                id="Btn-Cancelar-Editar-Perfil" 
                                                className="W600-S13-H17-C004FB8"
                                            >
                                                Cancelar
                                            </div>
                                        </div>
                                    </>
                                    :<>
                                        <div style={{width:'100%'}}>
                                            {
                                                LoginUsuario.percumpleanios
                                                    ?   Moment(LoginUsuario.percumpleanios).format('DD/MM/YYYY')
                                                    :   "Día/Mes/Año"
                                            }
                                        </div>
                                        <div style={{width:'100%', textAlign: "-webkit-right"}}>
                                            <img 
                                                onClick={() => setEditarCumpleanios(true)}
                                                src={IconoEditar} className="Iconos-Editar-Perfil" 
                                            />
                                        </div>

                                    </>
                                }
                            </Col>
                        </Row>
                        <Row className={editarTelefono == true?"Fila-Tabla-Editar-Perfil":"Fila-Tabla-Perfil"}>
                            <Col className="Wbold-s13-17-C004fb8" sm={12} xs={24}>
                                <img src={IconoTelefono} className="Iconos-Perfil" />
                                Teléfono
                            </Col>
                            <Col 
                                className="Wnormal-S13-H17-C706C64" sm={12} xs={24} 
                                style={
                                    editarTelefono == true
                                    ?{}
                                    :{display:'flex', alignItems: "center"}
                                }
                                
                            >
                                {
                                    editarTelefono == true
                                    ?<>
                                        <Input
                                            addonBefore={
                                                beforeTelefono
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                            onChange={(e) => {
                                                guardarTelefono(e.target.value)
                                                if(prefijoValor == null){
                                                    setPrefijoValor(LoginUsuario.paiid)
                                                }
                                            }}
                                            className="telefonoUsuarioPerfil"
                                            defaultValue={
                                                LoginUsuario.pernumero
                                                ? LoginUsuario.pernumero
                                                : ""
                                            }
                                        />
                                        <div style={{display:'flex', justifyContent: "right", marginTop:'10px'}}>
                                            <Button 
                                                id="Btn-Guardar-Editar-Perfil" 
                                                loading={cargando_editar_telefono}
                                                onClick={() => EditarCampo(3)}
                                                disabled={
                                                    !txtTelefono &&
                                                    !prefijoValor
                                                    ? true : false
                                                }
                                                className={
                                                    !txtTelefono &&
                                                    !prefijoValor
                                                    ? 'desabilitarPerfil' : ''
                                                }
                                            >
                                                <span className="W600-S13-H17-C004FB8">Guardar</span>
                                            </Button>
                                            <div
                                                onClick={() => {
                                                    setPrefijoTelefono(null)
                                                    setEditarTelefono(false)
                                                }}
                                                id="Btn-Cancelar-Editar-Perfil" 
                                                className="W600-S13-H17-C004FB8" >Cancelar</div>
                                        </div>
                                    </>
                                    :<>
                                        <div style={{width:'100%'}}>
                                            {
                                                LoginUsuario.pernumero
                                                    ? LoginUsuario.pernumero
                                                    : null
                                            }
                                        </div>
                                        <div style={{width:'100%', textAlign: "-webkit-right"}}>
                                            <img 
                                                onClick={() => setEditarTelefono(true)}
                                                src={IconoEditar} className="Iconos-Editar-Perfil" 
                                            />
                                        </div>
                                    </>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Modal 
                title="Actualizar foto de Perfil" 
                className="modalSubirImagenPerfil" 
                visible={abrirModalImagen} 
                onCancel={() => setAbrirModalImagen(false)}
                footer={[
                    <Button 
                        onClick={() => setAbrirModalImagen(false)} className="btnCancelSubirImagenPerfil"
                    >
                        Cancelar
                    </Button>,
                    <Button
                        className="btnSubirImagenPerfil"
                        type="primary"
                        onClick={() => SubirFoto(imageUrl)} 
                    >
                        + Subir Foto
                    </Button>,
                ]}
            >
                <div className="containerImagePerfil">
                    <figure className="imagePicturePerfil" onClick={() => refBtnImagen.current.click()}>
                        <input 
                            type="file" 
                            ref={refBtnImagen} 
                            className="inputImagePerfil" 
                            onChange={(e) => imgChangePerfil(e.target.files[0])}
                        />
                        {
                            imageUrl
                            ?   <img
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                    }}
                                    src={imageUrl}
                                />
                            :   <CameraOutlined 
                                    style={{
                                        fontSize:'60px', 
                                        color:'#004FB8'
                                    }} 
                                />
                        }
                    </figure>
                </div>
            </Modal>
        </div>
    )
}

export default Perfil