import { 
    SELECT_DOWNLOAD_DESCARGA,
    SELECT_LIST_DOWNLOAD_DESCARGA,
    FILTRO_DATA_TERRITORIO_NOTA_CREDITO,
    FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO,
    DATA_SELL_OUT,
    CHECK_DATA_ZONA,
    CHECK_DATA_ZONA_TODO,
    CHECK_DATA_TERRITORIO,
    CHECK_DATA_TERRITORIO_TODO,
    CHECK_DATA_DISTRIBUIDORA,
    CHECK_DATA_DISTRIBUIDORA_TODO,
} from "../../../Constantes/Descarga/Descarga"
import config from '../../../config'
import { estadoRequestReducer } from "../EstadoRequest"
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: 'Error con el servidor. Vuelva a ingresar más tarde.',
        })
    }
}

export const SelectDownloadDescargarReducer = (index) => async (dispatch, getState) => {

    let {
        list_downloads_descarga,
        download_selected_descarga
    } = getState().descarga

    list_downloads_descarga.map((list, pos) => {
        list_downloads_descarga[pos]['selected'] = false
    })

    list_downloads_descarga[index]['selected'] = true
    download_selected_descarga = list_downloads_descarga[index]['download']

    dispatch({
        type: SELECT_LIST_DOWNLOAD_DESCARGA,
        payload: list_downloads_descarga
    })

    dispatch({
        type: SELECT_DOWNLOAD_DESCARGA,
        payload: download_selected_descarga
    })

}

export const CargarDatosFiltradosNotaCreditoReducer = () => async (dispatch, getState) => {
    const territoriosSeleccionados = getState().descarga.list_data_territorio_nota_credito
    const distribuidorasSeleccionados = getState().notaCredito.data_distribuidoras_nota_credito
    dispatch({
        type: FILTRO_DATA_TERRITORIO_NOTA_CREDITO,
        payload: territoriosSeleccionados
    })
    dispatch({
        type: FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO,
        payload: distribuidorasSeleccionados
    })
}

export const AccederDescargaNotaCreditoReducer = (anio, mes) => async (dispatch, getState) => {

    let respuesta;

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }

    if(config.produccion == true){
        headerFetch = {
            'Accept' : 'application/json',
            'content-type': 'application/json',
            'api_token': localStorage.getItem('usutoken'),
            'api-token': localStorage.getItem('usutoken'),
        }
    }

    await fetch(config.api+'modulo/descarga/nota-credito',
		{
			mode:'cors',
			method: 'POST',
			body: JSON.stringify({
                "anio" : anio,
                "mes" : mes,
            }),
			headers: headerFetch
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta == true){
                respuesta = data.respuesta;
            }else{
                respuesta = data.respuesta;
            }
		}else{
        }
    }).catch((error)=> {
        notificacionServidor('error')
    })

    return respuesta
}

export const AccederDescargaSellReducer = (fechaInicio, sell) => async (dispatch, getState) => {

    let respuesta;

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }

    if(config.produccion == true){
        headerFetch = {
            'Accept' : 'application/json',
            'content-type': 'application/json',
            'api_token': localStorage.getItem('usutoken'),
            'api-token': localStorage.getItem('usutoken'),
        }
    }

    await fetch(config.api+'modulo/descarga/sell',
		{
			mode:'cors',
			method: 'POST',
			body: JSON.stringify({
                "fechaInicio" : fechaInicio,
                "sell" : sell,
            }),
			headers: headerFetch
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta == true){
                respuesta = data.respuesta
            }else{
                respuesta = data.respuesta
                dispatch({
                    type: DATA_SELL_OUT,
                    payload: data.sellActivados
                })
            }
		}else{
        }
    }).catch((error)=> {
        notificacionServidor('error')
    })

    return respuesta
}

export const OnCheckDataZonaReducer = (data) => async (dispatch, getState) => {
    const dataZonaTodoGuardar = data.length === getState().descarga.list_data_zona_nota_credito.length
    dispatch({
        type: CHECK_DATA_ZONA,
        payload: data
    })
    dispatch({
        type: CHECK_DATA_ZONA_TODO,
        payload: dataZonaTodoGuardar
    })
    dispatch(filtrarDataZonaReducer(data, dataZonaTodoGuardar))
}

export const OnCheckDataZonaTodoReducer = (data) => async (dispatch, getState) => {
    const dataZonaGuardar = data.target.checked ? getState().descarga.list_data_zona_nota_credito.map(zona => zona.clizona) : []
    dispatch({
        type: CHECK_DATA_ZONA,
        payload: dataZonaGuardar
    })
    dispatch({
        type: CHECK_DATA_ZONA_TODO,
        payload: data.target.checked
    })
    dispatch(filtrarDataZonaReducer(dataZonaGuardar, data.target.checked))
}

export const OnCheckDataTerritorioReducer = (data) => async (dispatch, getState) => {
    dispatch({
        type: CHECK_DATA_TERRITORIO,
        payload: data
    })
    dispatch({
        type: CHECK_DATA_TERRITORIO_TODO,
        payload: data.length === getState().descarga.rex_data_filtro_territorio_nota_credito.length
    })
    dispatch(filtrarDataTerritorioReducer(data))
}

export const OnCheckDataTerritorioTodoReducer = (data) => async (dispatch, getState) => {
    dispatch({
        type: CHECK_DATA_TERRITORIO,
        payload: data.target.checked ? getState().descarga.rex_data_filtro_territorio_nota_credito.map(territorio => territorio.data) : []
    })
    dispatch({
        type: CHECK_DATA_TERRITORIO_TODO,
        payload: data.target.checked
    })
    dispatch(filtrarDataTerritorioReducer(data.target.checked))
}

export const OnCheckDataDistribuidoraReducer = (data) => async (dispatch, getState) => {
    dispatch({
        type: CHECK_DATA_DISTRIBUIDORA,
        payload: data
    })
    dispatch({
        type: CHECK_DATA_DISTRIBUIDORA_TODO,
        payload: data.length === getState().descarga.rex_data_filtro_distribuidora_nota_credito.length
    })
}

export const OnCheckDataDistribuidoraTodoReducer = (data) => async (dispatch, getState) => {
    dispatch({
        type: CHECK_DATA_DISTRIBUIDORA,
        payload: data.target.checked ? getState().descarga.rex_data_filtro_distribuidora_nota_credito.map(distribuidora => distribuidora.clisuchml) : []
    })
    dispatch({
        type: CHECK_DATA_DISTRIBUIDORA_TODO,
        payload: data.target.checked
    })
}

export const filtrarDataZonaReducer = (zona, todo) => async (dispatch, getState) => {
    const territoriosSeleccionados = getState().descarga.list_data_territorio_nota_credito
    const distribuidorasSeleccionados = getState().notaCredito.data_distribuidoras_nota_credito
    const checkedDataTerritorio = getState().descarga.rex_check_data_territorio
    const checkedDataTerritorioTodo = getState().descarga.rex_check_data_territorio_todo
    const checkedDataDistribuidora = getState().descarga.rex_check_data_distribuidora

    if(zona.length > 0 || todo == true){
        const checkFiltroTerritorio = territoriosSeleccionados.filter(
            dataT => dataT.zona == zona.find(z => z == dataT.zona)
        )
        const checkFiltroDistribuidora = distribuidorasSeleccionados.filter(
            dataD => dataD.clizona == zona.find(z => z == dataD.clizona)
        )

        dispatch({
            type: FILTRO_DATA_TERRITORIO_NOTA_CREDITO,
            payload: checkFiltroTerritorio
        })

        const checkboxDT = checkedDataTerritorio === []
            ? []
            : checkedDataTerritorio.filter(checkDT => checkFiltroTerritorio.find(checkFT => checkFT.data == checkDT))
        if(checkboxDT.length > 0){
            dispatch(filtrarDataTerritorioReducer(checkboxDT))
            dispatch({
                type: CHECK_DATA_TERRITORIO,
                payload: checkedDataTerritorioTodo 
                        ? getState().descarga.rex_data_filtro_territorio_nota_credito.map(territorio => territorio.data) 
                        : checkboxDT
            })
        }else{
            dispatch({
                type: CHECK_DATA_TERRITORIO,
                payload: checkedDataTerritorioTodo 
                ? getState().descarga.rex_data_filtro_territorio_nota_credito.map(territorio => territorio.data) 
                : []
            })
            dispatch({
                type: FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO,
                payload: checkFiltroDistribuidora
            })
        }

        const checkboxDD = checkedDataDistribuidora.filter(checkDD => checkFiltroDistribuidora.find(checkFT => checkFT.clisuchml == checkDD))

        if(checkboxDD.length > 0){
            dispatch({
                type: CHECK_DATA_DISTRIBUIDORA,
                payload: checkboxDD
            })
        }else{
            dispatch({
                type: CHECK_DATA_DISTRIBUIDORA,
                payload: checkboxDD
            })
        }

    }else if(checkedDataTerritorio.length > 0){
        filtrarDataTerritorioReducer(checkedDataTerritorio)
        dispatch({
            type: FILTRO_DATA_TERRITORIO_NOTA_CREDITO,
            payload: territoriosSeleccionados
        })
        dispatch({
            type: CHECK_DATA_TERRITORIO_TODO,
            payload: false
        })
        dispatch({
            type: CHECK_DATA_DISTRIBUIDORA_TODO,
            payload: false
        })
    }else{
        dispatch({
            type: FILTRO_DATA_TERRITORIO_NOTA_CREDITO,
            payload: territoriosSeleccionados
        })
        dispatch({
            type: FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO,
            payload: distribuidorasSeleccionados
        })
        dispatch({
            type: CHECK_DATA_TERRITORIO_TODO,
            payload: false
        })
        dispatch({
            type: CHECK_DATA_DISTRIBUIDORA_TODO,
            payload: false
        })
    }
}

export const filtrarDataTerritorioReducer = (territorio) => async (dispatch, getState) => {
    const distribuidorasSeleccionados = getState().notaCredito.data_distribuidoras_nota_credito
    const checkedDataZona = getState().descarga.rex_check_data_zona
    const checkedDataZonaTodo = getState().descarga.rex_check_data_zona_todo
    const checkedDataDistribuidora = getState().descarga.rex_check_data_distribuidora

    if(territorio.length > 0){
        const checkFiltroDistribuidora = distribuidorasSeleccionados.filter(
            dataD => dataD.clitv == territorio.find(t => t == dataD.clitv)
        )

        dispatch({
            type: FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO,
            payload: checkFiltroDistribuidora
        })

        const checkboxDD = checkedDataDistribuidora.filter(checkDD => checkFiltroDistribuidora.find(checkFT => checkFT.clisuchml == checkDD))
        if(checkboxDD.length > 0){
            dispatch({
                type: CHECK_DATA_DISTRIBUIDORA,
                payload: checkboxDD
            })
        }else{
            dispatch({
                type: CHECK_DATA_DISTRIBUIDORA,
                payload: []
            })
        }

    }else if(checkedDataZona.length > 0){
        dispatch(filtrarDataZonaReducer(checkedDataZona, checkedDataZonaTodo))
        dispatch({
            type: CHECK_DATA_DISTRIBUIDORA_TODO,
            payload: false
        })
    }else{
        dispatch({
            type: FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO,
            payload: distribuidorasSeleccionados
        })
        dispatch({
            type: CHECK_DATA_DISTRIBUIDORA_TODO,
            payload: false
        })
    }
}

export const CancelarModalReducer = () => async (dispatch, getState) => {
    const territoriosSeleccionados = getState().descarga.list_data_territorio_nota_credito
    const distribuidorasSeleccionados = getState().notaCredito.data_distribuidoras_nota_credito
    dispatch({
        type: FILTRO_DATA_TERRITORIO_NOTA_CREDITO,
        payload: territoriosSeleccionados
    })
    dispatch({
        type: FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO,
        payload: distribuidorasSeleccionados
    })
    dispatch({
        type: CHECK_DATA_ZONA,
        payload: []
    })
    dispatch({
        type: CHECK_DATA_ZONA_TODO,
        payload: false
    })
    dispatch({
        type: CHECK_DATA_TERRITORIO,
        payload: []
    })
    dispatch({
        type: CHECK_DATA_TERRITORIO_TODO,
        payload: false
    })
    dispatch({
        type: CHECK_DATA_DISTRIBUIDORA,
        payload: []
    })
    dispatch({
        type: CHECK_DATA_DISTRIBUIDORA_TODO,
        payload: false
    })
}