export const OBTENER_DATA_ADMINISTRAR_TIPOS_USUARIOS = "OBTENER_DATA_ADMINISTRAR_TIPOS_USUARIOS"

export const OBTENER_FILTRO_ADMINISTRAR_TIPOS_USUARIOS = "OBTENER_FILTRO_ADMINISTRAR_TIPOS_USUARIOS"

export const OBTENER_DATA_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS = "OBTENER_DATA_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS"

export const OBTENER_FILTRO_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS = "OBTENER_FILTRO_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS"

export const SPIN_CREAR_EDITAR_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS = "SPIN_CREAR_EDITAR_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS"

export const ACTUALIZAR_DATA_ADMINISTRAR_TIPOS_USUARIOS = "ACTUALIZAR_DATA_ADMINISTRAR_TIPOS_USUARIOS"

export const ESTADO_SWITCH_PADRE_ADMINISTRAR_PERMISOS_TIPO_USUARIO = "ESTADO_SWITCH_PADRE_ADMINISTRAR_PERMISOS_TIPO_USUARIO"

export const SPIN_ADMINISTRAR_TIPOS_USUARIOS = "SPIN_ADMINISTRAR_TIPOS_USUARIOS"

export const SPIN_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS = "SPIN_ADMINISTRAR_PERMISOS_TIPOS_USUARIOS"