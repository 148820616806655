import { Modal, Spin, notification   } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import IconoMariposa from '../../Assets/Imagenes/Iconos/Login/mariposa.png'
import React, {useState} from 'react'
import {useDispatch} from "react-redux";
import {
    RecuperarContraseniaReducer
} from '../../Redux/Actions/Login/Login'
import {Link} from "react-router-dom";

const RecueperarC = () => {

    const dispatch = useDispatch();

    const [txtinputcorreo, setTxtinputcorreo] = useState("")
    const [envioCorreo, setEnvioCorreo] = useState(false)
    const [reenvioCorreo, setReenvioCorreo] = useState(false)
    const [cargandoBtn, setCargandoBtn] = useState(false)

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                textAlign: "-webkit-center",
                placeContent: "center",
                position:'relative'
            }}
        >
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            <div
                style={{
                    position:'absolute',
                    left: "38px",
                    top: "142.34px",
                    transform: "rotate(-7.49deg)"
                }}
            >
                {/* <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                /> */}
            </div>
            

            <div
                style={{
                    position:'absolute',
                    left: "424px",
                    top: "33.34px",
                    transform: "rotate(-7.49deg)"
                }}
            >
                {/* <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                /> */}
            </div>

            <div
                style={{
                    position:'absolute',
                    left: "894px",
                    top: "160.34px",
                    transform: "rotate(-7.49deg)"
                }}
            >
                {/* <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                /> */}
            </div>

            <div
                style={{
                    position:'absolute',
                    left: "1269.59px",
                    top: "74px",
                    transform: "rotate(9.25deg)"
                }}
            >
                {/* <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                /> */}
            </div>

            <div
                style={{
                    position:'absolute',
                    left: "360.59px",
                    top: "343px",
                    transform: "rotate(9.25deg)"
                }}
            >
                {/* <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                /> */}
            </div>

            <div
                style={{
                    position:'absolute',
                    left: "102px",
                    top: "539.34px",
                    transform: "rotate(-7.49deg)"
                }}
            >
                {/* <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                /> */}
            </div>

            <div
                style={{
                    position:'absolute',
                    left: "1128.59px",
                    top: "445px",
                    transform: "rotate(9.25deg)"
                }}
            >
                {/* <img 
                    src={IconoMariposa} 
                    className="Icono-Mariposa"
                /> */}
            </div>

            

















            {
                envioCorreo == true
                ?<div>
                    <div
                        className='W700-S20-H27-C004FB8'
                        style={{marginBottom:'20px'}}
                    >
                        Su solicitud fue enviada a su correo con éxito 
                    </div>
                    <div
                        className='W600-S16-H21-CBCBCBC'
                        style={{marginBottom:'20px'}}
                    >
                        Si no has recibido el email de confirmación, puedes 
                        <Spin 
                            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} 
                            spinning={cargandoBtn}
                        >
                            <span 
                                style={{cursor:'pointer', paddingLeft:'10px'}}
                                className='W700-S20-H27-C004FB8'
                                onClick={async () => {
                                    await setCargandoBtn(true)
                                    let recuperar = await dispatch(RecuperarContraseniaReducer({
                                        "correo": txtinputcorreo
                                    }))
                                    await setCargandoBtn(false)
                                    // setReenvioCorreo(true)
                                    notification.success({
                                        message: `Notificación`,
                                        description: 'El correo se reenvio satisfactoriamente',
                                        placement:'topRight'
                                    });
                                }}
                            >reenviarlo.</span>
                        </Spin>
                    </div>

                    <Link to="/login">
                        <div
                            className='Btn-Solicitar-RecuperarC'
                        >
                            <Link to="/login">
                                <div className='W700-S18-H24-C004FB8'>
                                    Regresar a Iniciar Sesión
                                </div>
                            </Link>
                        </div>
                    </Link>
                </div>
                :<div>
                    <div 
                        className='W700-S20-H27-C004FB8'
                        style={{marginBottom:'20px'}}
                    >
                        Restablecer contraseña
                    </div>
                    <div 
                        className='W600-S16-H21-CBCBCBC'
                        style={{marginBottom:'20px'}}
                    >Si no conoces tu contraseña actual, puedes <br/>solicitarla</div>
                    <div
                        className='Input-Recuperar-Contrasenia'
                    >
                        <input 
                            placeholder='Correo electrónico Softys' 
                            onChange={(e) => {
                                setTxtinputcorreo(e.target.value)
                            }}
                        />
                    </div>
                    <Link to="/login">
                        <div 
                            className='W400-S14-H19-CFF8023' 
                            style={{marginBottom:'20px'}}
                        >
                            Iniciar Sesión
                        </div>
                    </Link>
                    
                    <Spin 
                        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} 
                        spinning={cargandoBtn}
                    >
                        <div 
                            className='Btn-Solicitar-RecuperarC'
                            onClick={async () => {
                                await setCargandoBtn(true)
                                let recuperar = await dispatch(RecuperarContraseniaReducer({
                                    "correo": txtinputcorreo
                                }))
                                await setCargandoBtn(false)
                                setEnvioCorreo(true)
                            }}
                        >
                            <div className='W700-S18-H24-C004FB8'>
                                Solicitar
                            </div>
                        </div>
                    </Spin>

                </div>
            }

            <Modal
                title={null}
                footer={null}
                closeIcon={<div></div>}
                visible={reenvioCorreo}
                centered={true}
            >
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        placeContent: "center",
                    }}
                >
                    <div>
                        <div
                            className='W700-S20-H27-C004FB8'
                        >
                            El correo se acaba de reenviar satisfactoriamente
                        </div>
                        <div
                            className='Btn-Solicitar-RecuperarC'
                            onClick={() => {
                                setReenvioCorreo(false)
                            }}
                        >
                            <div className='W700-S18-H24-CFFFFFF'>
                                Aceptar
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>

        </div>
    )
}

export default RecueperarC