import config from '../../../../config'
import { 
    OBTENER_DATA_TABLA_ADMINISTRAR_PERMISOS,
    OBTENER_FILTRO_TABLA_ADMINISTRAR_PERMISOS,
    OBTENER_ADMINISTRAR_TIPOS_PERMISOS,
    SPIN_ACCIONES_ADMINISTRAR_PERMISOS,
    SPIN_TABLE_ADMINISTRAR_PERMISOS,
} from "../../../../Constantes/Administrar/Permisos/Permisos";
import { estadoRequestReducer } from "../../EstadoRequest"
import { notification } from 'antd'

const notificacionServidor = (type, mensaje, errores) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning' && errores){
        notification['warning']({
            message: 'Advertencia',
            description:<>
                            {mensaje}<br/>
                            {errores.map((e) => <>- {e}<br/></>)}
                        </>,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: 'Error con el servidor. Vuelva a ingresar más tarde.',
        })
    }
}

export const ObtenerDataAdministrarPermisosReducer = (idTiposPermisos) => async (dispatch, getState) => {

    dispatch({
        type: SPIN_TABLE_ADMINISTRAR_PERMISOS,
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/mostrar-permisos',
		{
			mode:'cors',
			method: 'POST',
			body: JSON.stringify({
                idTiposPermisos: idTiposPermisos
            }),
			headers: headerFetch
      	}
    )
    .then(async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_TABLA_ADMINISTRAR_PERMISOS,
                payload : data.datos
            })
            dispatch(ObtenerListaFiltroPermisosReducer(null))
            dispatch({
                type: SPIN_TABLE_ADMINISTRAR_PERMISOS,
                payload: false
            })
		}else{
        }
    }).catch((error)=> {
        notificacionServidor('error')
    })

}

export const ObtenerDataAdministrarTiposPermisosReducer = () => async (dispatch, getState) => {

    dispatch({
        type: SPIN_TABLE_ADMINISTRAR_PERMISOS,
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/mostrar-tipos-permisos',
		{
			mode:'cors',
			method: 'POST',
			body: JSON.stringify({
            }),
			headers: headerFetch
      	}
    )
    .then(async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            dispatch({
                type: OBTENER_ADMINISTRAR_TIPOS_PERMISOS,
                payload : data.datos
            })
            dispatch({
                type: SPIN_TABLE_ADMINISTRAR_PERMISOS,
                payload: false
            })
		}else{
        }
    }).catch((error)=> {
        notificacionServidor('error')
    })
}

export const CrearDataAdministrarPermisosReducer = (tipoPermiso, nombrePermiso, slugPermiso, rutaPermiso) => async(dispatch, getState) => {

    dispatch({
        type: SPIN_ACCIONES_ADMINISTRAR_PERMISOS,
        payload: true
    })

    let respuesta

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/crear-permisos',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                tipoPermiso: tipoPermiso,
                nombrePermiso: nombrePermiso,
                slugPermiso: slugPermiso,
                rutaPermiso: rutaPermiso,
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                respuesta = true
                notificacionServidor('success', data.mensaje)
                dispatch(ObtenerDataAdministrarPermisosReducer())
            }else{
                notificacionServidor('warning', data.mensaje)
                respuesta = false
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
            respuesta = false
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    })

    dispatch({
        type: SPIN_ACCIONES_ADMINISTRAR_PERMISOS,
        payload: false
    })

    return respuesta

}

export const ActualizarDataAdministrarPermisosReducer = (idPermiso, tipoPermiso, nombrePermiso, slugPermiso, rutaPermiso) => async(dispatch, getState) => {

    let respuesta

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/actualizar-permisos',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                idPermiso: idPermiso,
                tipoPermiso: tipoPermiso,
                nombrePermiso: nombrePermiso,
                slugPermiso: slugPermiso,
                rutaPermiso: rutaPermiso,
            }),
      	}
    )
    .then(async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                respuesta = true
                notificacionServidor('success', data.mensaje)
                dispatch(CambiarDataTablaAdministrarPermisosReducer(idPermiso, tipoPermiso, nombrePermiso, slugPermiso, rutaPermiso))
            }else{
                notificacionServidor('warning', data.mensaje)
                respuesta = false
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
            respuesta = false
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    })

    return respuesta
}

export const EliminarDataAdministrarPermisosReducer = (idPermiso) => async(dispatch, getState) => {

    let respuesta = true

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
        'api_token': localStorage.getItem('usutoken'),
        'api-token': localStorage.getItem('usutoken'),
    }

    await fetch(config.api+'modulo/administrar/eliminar-permisos',
		{
			mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                idPermiso: idPermiso
            }),
      	}
    )
    .then( async res => {
		await dispatch(estadoRequestReducer(res.status))
		return res.json()
    })
    .then(async data => {
		const estadoRequest = getState().estadoRequest.init_request
		if(estadoRequest === true){
            if(data.respuesta){
                respuesta = false
                notificacionServidor('success', data.mensaje)
                dispatch(ObtenerDataAdministrarPermisosReducer())
            }else{
                notificacionServidor('warning', data.mensaje)
            }
		}else{
            notificacionServidor('warning', data.mensaje, data.errores)
        }
    })
    .catch((error)=> {
        notificacionServidor('error')
    })

    return respuesta
}

export const CambiarDataTablaAdministrarPermisosReducer = (pemid, tppid, pemnombre, pemslug, pemruta) => async (dispatch, getState) => {
    let permisosUsuarios = getState().administrarPermisos.rex_data_tabla_permisos
    let permisos = getState().administrarPermisos.rex_data_tipos_permisos
    permisosUsuarios.map((pem) => {
        if(pem.pemid == pemid){
            pem['tppid'] = tppid
            pem['pemnombre'] = pemnombre
            pem['pemslug'] = pemslug
            pem['pemruta'] = pemruta
            permisos.filter((p) => {
                if(p['tppid'] == pem['tppid']){
                    pem['tppnombre'] = p.tppnombre
                }
            })
        }
    })
}

export const ObtenerListaFiltroPermisosReducer = (txtBuscar) => async (dispatch, getState) => {
    if(txtBuscar){
        const data = getState().administrarPermisos.rex_data_tabla_permisos.filter((permiso) => 
            permiso.pemnombre.toLowerCase().includes(txtBuscar.toLocaleLowerCase())
        )
        dispatch({
            type: OBTENER_FILTRO_TABLA_ADMINISTRAR_PERMISOS,
            payload: data
        })
    }else{
        dispatch({
            type: OBTENER_FILTRO_TABLA_ADMINISTRAR_PERMISOS,
            payload: getState().administrarPermisos.rex_data_tabla_permisos
        })
    }
}