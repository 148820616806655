import React, {useState} from 'react'
import { Button, Modal, Select, Form, Input } from 'antd'
import {
    CrearDataAdministrarPermisosReducer,
} from '../../../Redux/Actions/Administrar/Permisos/Permisos'
import {useDispatch, useSelector} from "react-redux"

const CrearModalPermisos = (props) => {
    const abrirModalCrear = props.abrirModalCrear
    const setAbrirModalCrear =props.setAbrirModalCrear

    const { Option } = Select
    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const {
        rex_data_tipos_permisos,
        rex_spin_acciones_permisos,
    } = useSelector(({administrarPermisos}) => administrarPermisos)

    const limpiarFormulario = () => {
        form.setFieldsValue({
            tipoPermiso: '',
            permiso: '',
            slug: '',
            ruta: '',
        })
    }

    const enviarFormulario = async (datos) => {
        const {tipoPermiso, permiso, slug, ruta} = datos
        let respuesta = await dispatch(CrearDataAdministrarPermisosReducer(tipoPermiso, permiso, slug, ruta))
        if(respuesta){
            setAbrirModalCrear(false)
            limpiarFormulario()
        }
    }

    return(
        <Modal 
            className="modalPermisoCrear"
            visible={abrirModalCrear}
            closable={false}
            onCancel={() => {
                setAbrirModalCrear(false)
                limpiarFormulario()
            }}
            title={<div style={{textAlign: 'center'}}>Nuevo Permiso</div>}
            footer={false}
        >
            <Form
                form={form}
                initialValues={{
                    tipoPermiso: '',
                    permiso: '',
                    slug: '',
                    ruta: '',
                }}
                autoComplete="off"
                onFinish={enviarFormulario}
                onValuesChange={false}
            >
                <Form.Item 
                    labelCol={{
                        md: {span: 9},
                        sm: {span: 8},
                        xs: {span: 9},
                    }}
                    labelAlign="left"
                    wrapperCol={{
                        md: {span: 15},
                        sm: {span: 15},
                        xs: {span: 15},
                    }} 
                    name="tipoPermiso"
                    className="form-labelAdministrarUsuario"
                    label="Tipos de Permisos"
                    
                    rules={[
                        {
                        required: true,
                        message: 'Por favor ingrese un tipo de permiso',
                        },
                    ]}
                >
                    <Select 
                        className="selectAdministrarArchivo" 
                        defaultValue="Seleccione un tipo de permiso"
                    >
                        <Option value="" title="">Seleccione un tipo de permiso</Option>
                        {
                            rex_data_tipos_permisos.map((tper, index) => (
                                <Option key={index} value={tper.tppid}>
                                    {tper.tppnombre}
                                </Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item 
                    labelCol={{
                        md: {span: 9},
                        sm: {span: 8},
                        xs: {span: 9},
                    }}
                    labelAlign="left"
                    wrapperCol={{
                        md: {span: 15},
                        sm: {span: 15},
                        xs: {span: 15},
                    }} 
                    name="permiso"
                    className="inputPermisoCrear"
                    label="Permiso"
                    
                    rules={[
                        {
                        required: true,
                        message: 'Por favor ingrese un permiso',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    labelCol={{
                        md: {span: 9},
                        sm: {span: 8},
                        xs: {span: 9},
                    }}
                    labelAlign="left"
                    wrapperCol={{
                        md: {span: 15},
                        sm: {span: 15},
                        xs: {span: 15},
                    }} 
                    name="slug"
                    className="inputPermisoCrear"
                    label="Slug"
                >
                    <Input />
                </Form.Item>
                <Form.Item 
                    labelCol={{
                        md: {span: 9},
                        sm: {span: 8},
                        xs: {span: 9},
                    }}
                    labelAlign="left"
                    wrapperCol={{
                        md: {span: 15},
                        sm: {span: 15},
                        xs: {span: 15},
                    }} 
                    name="ruta"
                    className="inputPermisoCrear"
                    label="Ruta"
                >
                    <Input />
                </Form.Item>
                <div 
                    className="contenedorBtnModalCrear"
                >
                    <Button
                        shape="round"
                        onClick={() => {
                            setAbrirModalCrear(false)
                            limpiarFormulario()
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        loading={rex_spin_acciones_permisos}
                        type="primary" 
                        htmlType="submit" 
                        shape="round"
                    >
                        Guardar
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default CrearModalPermisos