import React, {useEffect, useState} from 'react'
import { Col, Row, Pagination, Button, Select } from 'antd'
import { PlusCircleOutlined} from '@ant-design/icons'
import Title from 'antd/lib/typography/Title'
import Search from 'antd/lib/input/Search'
import BotonesRutas from '../../../Componentes/Administrar/BotonesRutas'
import {
    ObtenerDataAdministrarPermisosReducer,
    ObtenerDataAdministrarTiposPermisosReducer,
    ObtenerListaFiltroPermisosReducer,
} from '../../../Redux/Actions/Administrar/Permisos/Permisos'
import {useDispatch, useSelector} from "react-redux"
import CrearModalPermisos from '../../../Componentes/Administrar/Permisos/CrearModalPermisos'
import TablaPermisos from '../../../Componentes/Administrar/Permisos/TablaPermisos'
import '../../../Estilos/Rutas/Administrar/Permisos/Permisos.css'

const Permisos = () => {
    
    const { Option } = Select
    const dispatch = useDispatch()

    const {
        rex_filtro_tabla_permisos,
        rex_data_tipos_permisos,
    } = useSelector(({administrarPermisos}) => administrarPermisos)
    
    useEffect(() => {
        dispatch(ObtenerDataAdministrarPermisosReducer())
        dispatch(ObtenerDataAdministrarTiposPermisosReducer())
    }, [])
    
    const [pageTablePermisos, setPageTablePermisos] = useState(1)
    const [abrirModalCrear, setAbrirModalCrear] = useState(false)

    return(
        <>
            <div 
                className="contenedorAdministrarModulo"
            >
                <Title 
                    className="title-controlArchivos"
                >
                    ADMINISTRADOR
                </Title>
                <Row 
                    className="row-button-controlArchivos"
                    style={{ 
                        rowGap:'25px',
                        padding: '0 15px 0 15px', 
                        marginTop: '25px',
                        marginBottom:'25px',
                    }}
                >
                    <Col 
                        xl={12}
                        lg={18}
                        md={18}
                        sm={24}
                        xs={{
                            span: 24,
                            order:1
                        }}
                        style={{
                            margin: 'auto 0',
                        }}
                    >
                        <BotonesRutas 
                            ruta="permiso" 
                        />
                    </Col>
                    <Col 
                        xl={{
                            span: 7,
                            offset: 0,
                            order: 2,
                        }}
                        lg={10}
                        md={{
                            span: 12,
                            order: 3,
                        }}
                        sm={16}
                        xs={{
                            span: 24,
                            order: 2,
                        }}
                        style={{
                            marginTop: 'auto',
                            marginBottom: 'auto',
                        }}
                    >
                        <Search
                            placeholder="Buscar"
                            allowClear
                            className="search-controlArchivos"
                            onChange={(e) => {
                                dispatch(ObtenerListaFiltroPermisosReducer(e.target.value))
                                setPageTablePermisos(1)
                            }}
                        />
                    </Col>
                    <Col 
                        xl={{
                            span: 3,
                            order: 3,
                            offset: 0,
                        }}
                        lg={{
                            span: 4,
                            offset: 2,
                        }}
                        md={{
                            span: 5,
                            offset: 19,
                        }}
                        sm={{
                            span: 8,
                            offset: 2,
                        }}
                        xs={{
                            span: 24,
                            order: 5,
                        }}
                        style={{
                            display:'flex', 
                            alignItems:'center', 
                            justifyContent:'end'
                        }}
                    >
                        <Pagination  
                            size="small"
                            defaultCurrent={pageTablePermisos}
                            current={pageTablePermisos}
                            total={rex_filtro_tabla_permisos.length}
                            showTotal={(total, range) => {
                                return(
                                    rex_filtro_tabla_permisos
                                    ? range[0] + " - " + range[1] + " de " + total
                                    : <>0 - 0 de 0</>
                                )
                            }} 
                            className="pagination-controlArchivo"
                            onChange={(page) => {
                                setPageTablePermisos(page)
                            }}
                        />
                    </Col>
                    <Col 
                        xl={{
                            span: 2,
                            order: 4,
                            offset: 0
                        }}
                        lg={{
                            span: 3,
                            offset: 3,
                        }} 
                        md={{
                            span: 4,
                            order: 2,
                            offset: 2,
                        }}
                        sm={{
                            span: 4,
                            offset: 4
                        }}
                        xs={{
                            span: 24,
                            order: 4,
                        }}
                        className="colCrearAdministrarUsuarios"
                    >
                        <Button 
                            className="button-administrarUsuario" 
                            shape="round"
                            onClick={() => setAbrirModalCrear(true)}
                        >
                            Crear
                            <PlusCircleOutlined 
                                className="iconButton-administrarUsuario"
                            />
                        </Button>
                    </Col>
                    <Col
                        xl={{
                            span: 6,
                            order: 5,
                        }}
                        lg={{
                            span: 8,
                        }}
                        md={{
                            span: 12,
                        }}
                        sm={{
                            span: 14,
                            order: 4,
                        }}
                        xs={{
                            span: 24,
                            order: 3,
                            offset: 0,
                        }}
                    >
                        <Select 
                            size="large" 
                            onChange={(selectBuscar) => {
                                dispatch(ObtenerDataAdministrarPermisosReducer(selectBuscar))
                                setPageTablePermisos(1)
                            }} 
                            defaultValue="Seleccione el tipo de permiso" 
                            className="tipoControlArchivo"
                            style={{width: '100%'}}
                        >
                            <Option>Seleccione el tipo de permiso</Option>
                            {
                                rex_data_tipos_permisos.map((tper, index) => (
                                    <Option 
                                        key={index} 
                                        value={tper.tppid}
                                    >
                                        {tper.tppnombre}
                                    </Option>
                                ))
                            }
                        </Select>
                    </Col>
                </Row>

                <TablaPermisos 
                    pageTablePermisos={pageTablePermisos}
                />
                
                <CrearModalPermisos 
                    abrirModalCrear={abrirModalCrear}
                    setAbrirModalCrear={setAbrirModalCrear}
                />
            </div>
        </>
    )
}

export default Permisos