import React, {useEffect, useState} from 'react'
import { Col, Row, Spin, Card, Button, Modal, Tooltip } from 'antd'
import { Link } from "react-router-dom";
import { PlusCircleOutlined, LoadingOutlined, DeleteOutlined  } from '@ant-design/icons'
import Search from 'antd/lib/input/Search'
import Title from 'antd/lib/typography/Title'
import BotonesRutas from '../../../Componentes/Administrar/BotonesRutas'
import '../../../Estilos/Rutas/Administrar/TiposUsuarios/TiposUsuarios.css'
import {
    ObtenerDataAdministrarTiposUsuariosReducer,
    ObtenerDataAdministrarPermisosTiposUsuariosReducer,
    ObtenerListaFiltroTiposUsuariosReducer,
    EliminarDataAdministrarTiposUsuariosReducer,
} from '../../../Redux/Actions/Administrar/TiposUsuarios/TiposUsuarios'
import {useDispatch, useSelector} from "react-redux"
import AdministrarIcono1 from '../../../Assets/Imagenes/Iconos/Administrar/Administrador.png'

const TiposUsuarios = () => {

    const { Meta } = Card
    const dispatch = useDispatch()

    const {
        rex_filtro_tipos_usuarios,
        rex_spin_tipos_usuarios,
    } = useSelector(({administrarTiposUsuarios}) => administrarTiposUsuarios)

    useEffect(() => {
        dispatch(ObtenerDataAdministrarTiposUsuariosReducer())
    }, [])

    const eliminar = (tpuid, tpunombre) => {
        Modal.confirm({
            title: 'Eliminar tipo de usuario',
            content: `Deseas eliminar el tipo de usuario ${tpunombre}?`,
            okText: 'Eliminar',
            cancelText: 'Cancelar',
            async onOk(){
                await dispatch(EliminarDataAdministrarTiposUsuariosReducer(tpuid))
            },
            onCancel(){},
        });
      };

    const iconoCarga = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    )

    return(
        <>
            <div 
                className="contenedorAdministrarModulo"
            >
                <Title 
                    className="title-controlArchivos"
                >
                    ADMINISTRADOR
                </Title>
                <Row 
                    className="row-button-controlArchivos"
                    style={{ 
                        rowGap:'25px',
                        padding: '0 15px 0 15px', 
                        marginTop: '25px',
                    }}
                >
                    <Col 
                        xl={11}
                        lg={14}
                        md={24}
                        xs={24}
                    >
                        <BotonesRutas 
                            ruta="tipo" 
                        />
                    </Col>
                    <Col 
                        xl={13}
                        lg={{
                            span: 10,
                            offset: 0,
                        }}
                        md={{
                            span: 12,
                            offset: 12,
                        }}
                        sm={{
                            span: 14,
                            offset: 10,
                        }}
                        xs={24}
                    >
                        <Search
                            placeholder="Buscar"
                            allowClear
                            className="search-controlArchivos tipoUsuario"
                            onChange={(e) => {
                                dispatch(ObtenerListaFiltroTiposUsuariosReducer(e.target.value))
                            }}
                        />
                    </Col>
                </Row>
                <Spin 
                    spinning={rex_spin_tipos_usuarios} 
                    indicator={iconoCarga}
                >
                    <Row 
                        gutter={16} 
                        style={{
                            marginTop: '25px', 
                            rowGap: '25px', 
                            paddingLeft: '15px', 
                            paddingRight: '15px'
                        }}>
                        <Col 
                            className="gutter-row" 
                            xl={4}
                            lg={6}
                            md={8}
                            sm={{
                                span: 12,
                                offset: 0,
                            }}
                            xs={{
                                span: 20,
                                offset: 2,
                            }}
                        >
                            <Link 
                                to={{
                                    pathname: "/administrar/tipos-usuarios/permisos/",
                                }} 
                                onClick={() => dispatch(ObtenerDataAdministrarPermisosTiposUsuariosReducer("crear",null))}
                            >
                                <Card 
                                    className="tarjetaAdministrarNuevo efectoRebote"
                                    bordered={false}
                                > 
                                    <PlusCircleOutlined 
                                        className="tarjetaAdministrarNuevoIcono" 
                                    />
                                    <h3 
                                        className="tarjetaAdministrarNuevoTexto"
                                    >
                                        Crear Nuevo <br/>Tipo de Usuario
                                    </h3>
                                </Card>
                            </Link>
                        </Col>
                        {rex_filtro_tipos_usuarios.map((tipos, index) => 
                            <Col 
                                className="gutter-row" 
                                xl={4}
                                lg={6}
                                md={8}
                                sm={{
                                    span: 12,
                                    offset: 0,
                                }}
                                xs={{
                                    span: 20,
                                    offset: 2,
                                }}
                                key={index}
                            >
                                <Card
                                    className="tarjetaAdministrar efectoRebote"
                                    cover={
                                        <div className="tarjetaAdministrarBoxImg">
                                            <img alt="example" src={AdministrarIcono1} className="tarjetaAdministrarImg" />
                                        </div>
                                    }
                                >
                                    <Meta 
                                        style={{textAlign: 'center'}}
                                        title={tipos.tpunombre} 
                                        description={
                                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                <Link 
                                                    to={{
                                                        pathname: "/administrar/tipos-usuarios/permisos/"+tipos.tpunombre,
                                                    }} 
                                                    onClick={() => dispatch(ObtenerDataAdministrarPermisosTiposUsuariosReducer("editar", tipos.tpunombre))}
                                                >
                                                    <p style={{marginBottom: '0', padding: '5px 10px' ,cursor: 'pointer'}}>
                                                        Ver Perfil
                                                    </p>
                                                </Link>
                                                <Tooltip title="Eliminar">
                                                    <Button 
                                                        onClick={() => eliminar(tipos.tpuid, tipos.tpunombre)} 
                                                        danger
                                                        className="colorBotonEliminar"
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        } 
                                    />
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Spin>
            </div>
        </>
    )
}

export default TiposUsuarios