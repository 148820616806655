import React, {useState} from 'react'
import { Button, Modal } from 'antd'
import { Link } from "react-router-dom";
import StatusBotonSell from '../../Assets/Imagenes/Iconos/Descarga/Status.png'

const ModalNotaCredito = (props) => {
    const {
        abrirModalNoDataSell,
        rex_data_sell_out,
        modalMensajeSell,
        setAbrirModalNoDataSell,
    } = props
    
    const [abrirModalVerMas, setAbrirModalVerMas] = useState(false)

    return (
        <Modal
            width={420}
            centered
            className="modalNotaCreditoDescarga sinDataSell"
            title="Sin Información"
            visible={abrirModalNoDataSell}
            onCancel={() => {
                setAbrirModalNoDataSell(false)
                setAbrirModalVerMas(false)
            }}
            footer={[
                <Link to="/sistema">
                    <Button className="btnNotaCreditoDescarga">
                        <img src={StatusBotonSell} width={30} />
                        ir a status
                    </Button>
                </Link>
            ]}
        >
            {
                modalMensajeSell == "Sell Out"
                    ?<div className="sellCuerpo">
                        <p className="sellInfoTexto">No hay informacion en el <br/>mes seleccionado porque no se encuentra<br/>actualizado el proceso de Subsidio Sell Out</p>
                        <div 
                            className="sellMasDetalles" 
                            style={abrirModalVerMas ? {marginBottom: '6px'} : {}}
                        >
                            <span 
                                onClick={() => setAbrirModalVerMas(!abrirModalVerMas)}
                            >
                                ver {abrirModalVerMas ? 'menos' : 'más'} detalles
                            </span>
                        </div>
                        <div 
                            className="sellMasDetallesLista"
                            style={abrirModalVerMas ? {}: {display: 'none'}}
                        >
                            <span className="sellDescripcion">
                                Para realizar la descarga se tiene que cargar las siguientes <br/>bases de Información de Sell Out:
                            </span>
                            <ul>
                                {/* <li>
                                    Sell Out (Efectivo) 
                                    {rex_data_sell_out.find(so => so == "Sell Out (Efectivo)") 
                                    ? <span className="colorSellActualizado"> (actualizado)</span> 
                                    : <span className="colorSellPendiente"> (pendiente)</span>}
                                </li> */}
                                <li>
                                    Subsidio Aprobado (Plantilla) 
                                    {rex_data_sell_out.find(so => so == "Subsidio Aprobado (Plantilla)") 
                                    ? <span className="colorSellActualizado"> (actualizado)</span> 
                                    : <span className="colorSellPendiente"> (pendiente)</span>}
                                </li>
                                <li>
                                    Subsidio Reconocido {rex_data_sell_out.find(so => so == "Subsidio Reconocido") 
                                    ? <span className="colorSellActualizado"> (actualizado)</span> 
                                    : <span className="colorSellPendiente"> (pendiente)</span>}
                                </li>                 
                            </ul>
                        </div>
                    </div>
                    :<div className="sellCuerpo">
                        <p className="sellInfoTexto">No hay informacion en el <br/>mes seleccionado porque no se encuentra<br/>actualizado el proceso de Subsidio Sell In</p>
                        <div 
                            className="sellMasDetalles"
                            style={abrirModalVerMas ? {marginBottom: '6px'} : {}}
                        >
                            <span 
                                onClick={() => setAbrirModalVerMas(!abrirModalVerMas)}
                            >
                                ver {abrirModalVerMas ? 'menos' : 'más'} detalles
                            </span>
                        </div>
                        <div 
                            className="sellMasDetallesLista"
                            style={abrirModalVerMas ? {}: {display: 'none'}}
                        >
                            <span className="sellDescripcion">
                                Para realizar la descarga se tiene que cargar las siguientes <br/>bases de Información de Sell In:
                            </span>
                            <ul>
                                <li>
                                    Sell In (Factura Efectiva) 
                                    {rex_data_sell_out.find(so => so == "Sell In (Factura Efectiva)") 
                                    ? <span className="colorSellActualizado"> (actualizado)</span> 
                                    : <span className="colorSellPendiente"> (pendiente)</span>}
                                </li>
                                <li>
                                    Operaciones Sunat 
                                    {rex_data_sell_out.find(so => so == "Operaciones Sunat") 
                                    ? <span className="colorSellActualizado"> (actualizado)</span> 
                                    : <span className="colorSellPendiente"> (pendiente)</span>}
                                </li>
                                <li>
                                    Proceso Subsidios Sell Out
                                    {rex_data_sell_out.find(so => so == "Proceso Subsidios Sell Out") 
                                    ? <span className="colorSellActualizado"> (actualizado)</span> 
                                    : <span className="colorSellPendiente"> (pendiente)</span>}
                                </li>
                            </ul>
                        </div>
                    </div>
            }
        </Modal>
    )
}

export default ModalNotaCredito;