import { Button } from "antd";
import React from "react";
// import ControlArchivos from "../../Rutas/Administrar/ControlArchivos/ControlArchivos";
import '../../Estilos/Componentes/Administrar/BotonesRutas.css';
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";

const BotonesRutas = (props) => {
    return (
        <div className="col-button-controlArchivos">
            <Link to="/administrar/tipos-usuarios">
                <Button className={`button-controlArchivos ${props.ruta == `tipo` ? `active-button-administrar`: ``} `} shape="round">
                    Tipos de Usuarios
                </Button>
            </Link>
            <Link to="/administrar/usuarios">
                <Button className={`button-controlArchivos ${props.ruta == `usuario` ? `active-button-administrar`: ``} `} shape="round">
                    Usuarios
                </Button>
            </Link>
            <Link to="/administrar/permisos">
                <Button className={`button-controlArchivos ${props.ruta == `permiso` ? `active-button-administrar`: ``} `} shape="round">
                    Permisos
                </Button>
            </Link>
            <Link to="/administrar/control-archivo">
                <Button className={`button-controlArchivos ${props.ruta == `control` ? `active-button-administrar`: ``} `} shape="round">
                    Control de Archivo
                </Button>
            </Link>
            <Link to="/administrar/paises">
                <Button className={`button-controlArchivos ${props.ruta == `pais` ? `active-button-administrar`: ``} `} shape="round">
                    Paises
                </Button>
            </Link>
        </div>
    );
};

export default BotonesRutas;