import React, {useEffect, useState} from 'react'
import { Button,Table, Modal, Spin, Tooltip } from 'antd'
import {
    LoadingOutlined,
    ExclamationCircleOutlined,
    DeleteOutlined,
} from '@ant-design/icons'
import Column from 'antd/lib/table/Column'
import Moment from 'moment'
import 'moment/locale/es'
import {useDispatch, useSelector} from "react-redux"
import {
    EliminarDataAdministrarUsuariosReducer,
    CambiarEstadoUsuariosReducer,
} from '../../../Redux/Actions/Administrar/Usuarios/Usuarios'

const TablaLista = (props) => {
    const vaciarFormulario = props.vaciarFormulario
    const seleccionarFila = props.seleccionarFila
    const numeroPagina = props.numeroPagina
    const txtBuscar = props.txtBuscar

    const { confirm } = Modal
    const dispatch = useDispatch()

    const {
        rex_data_tabla_usuarios,
        rex_spin_table_usuarios,
    } = useSelector(({administrarUsuarios}) => administrarUsuarios)

    const abrirModal = (eliminar, usuario) => {
        confirm({
            title: 'Eliminar Usuario',
            icon: <ExclamationCircleOutlined />,
            content: `Deseas eliminar el usuario ${usuario.pernombrecompleto}?`,
            okText: 'Eliminar',
            cancelText: 'Cancelar',
        async onOk() {
            await dispatch(
                EliminarDataAdministrarUsuariosReducer(eliminar, numeroPagina, txtBuscar)
            )
            dispatch(CambiarEstadoUsuariosReducer(true, false, false, null, true))
            vaciarFormulario()
        },
            onCancel() {},
        })
    }

    const iconoCarga = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    )

    return(
        <Spin 
            spinning={rex_spin_table_usuarios} 
            indicator={iconoCarga}
        >
            <Table 
                className="table-administrarUsuarios pointer"
                dataSource={rex_data_tabla_usuarios.data}
                scroll={{
                    y: 460,
                }}
                onRow={seleccionarFila}
                pagination={false}
            >
                <Column 
                    title="Item"
                    dataIndex="index" 
                    key="index"
                    fixed="left"
                    width={60}
                    render={(value, item, index) => (numeroPagina - 1) * 10 + (index + 1)}
                />
                <Column 
                    title="Estado" 
                    dataIndex="estid" 
                    key="estid"
                    width={70} 
                    render={(estid) => (
                        <Tooltip title={estid === 1 ? 'Activo' : 'Inactivo'}>
                            <p className={estid === 1 ? 'activoColumnaUsuario' : 'inactivoColumnaUsuario'}></p>
                        </Tooltip>
                    )}
                />    
                <Column 
                    title="Usuario" 
                    dataIndex="usuusuario" 
                    key="usuusuario" 
                    width={300}
                />    
                <Column 
                    title="Nombre Completo" 
                    dataIndex="pernombrecompleto"
                    key="pernombrecompleto"
                    width={200}
                />
                <Column 
                    title="Tipo de Usuario" 
                    dataIndex="tpunombre"
                    key="tpunombre"
                    width={130}
                />
                <Column 
                    title="Fecha de Creación" 
                    dataIndex="created_at" 
                    key="created_at"
                    width={200}
                    render={(created_at) => (
                        <>{Moment(created_at).format('D MMMM YYYY HH:mm')}</>
                    )}
                />
                <Column 
                    title="Última fecha de edición" 
                    dataIndex="updated_at" 
                    key="updated_at"
                    width={200}
                    render={(updated_at) => (
                        <>{Moment(updated_at).format('D MMMM YYYY HH:mm')}</>
                    )}
                />
                <Column 
                    title="Acciones" 
                    dataIndex="usuid" 
                    key="usuid"
                    fixed="right"
                    width={100}
                    responsive={['sm']}
                    render={(usuid, usuario) => 
                        <Tooltip title="Eliminar">
                            <Button 
                                onClick={() => {
                                    abrirModal(usuid, usuario)
                                }}
                                danger
                                className="colorBotonEliminar"
                            >
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    }
                />
                <Column 
                    title="Acciones" 
                    dataIndex="usuid" 
                    key="usuid"
                    width={100}
                    responsive={['xs']}
                    render={(usuid, usuario) => 
                        <Tooltip title="Eliminar">
                            <Button 
                                onClick={() => {
                                    abrirModal(usuid, usuario)
                                }}
                                danger
                            >
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    }
                />
            </Table>
        </Spin>
    )
}

export default TablaLista