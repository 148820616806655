import React, {useState, useRef, useEffect} from 'react'
import { Button, Modal, Checkbox } from 'antd'
import {useSelector} from "react-redux"
import {
    CaretUpOutlined,
    CaretDownOutlined,
} from '@ant-design/icons'
import {
    CargarDatosFiltradosNotaCreditoReducer,
    OnCheckDataZonaReducer, 
    OnCheckDataZonaTodoReducer,
    OnCheckDataTerritorioReducer,
    OnCheckDataTerritorioTodoReducer,
    OnCheckDataDistribuidoraReducer,
    OnCheckDataDistribuidoraTodoReducer,
    CancelarModalReducer
} from '../../Redux/Actions/Descarga/Descarga'
import { ObtenerNotaCreditoReducer, ObtenerDataDistribuidorasReducer } from '../../Redux/Actions/NotaCredito/NotaCredito'

const ModalNotaCredito = (props) => {
    const abrirModalNotaCredito = props.abrirModalNotaCredito
    const setAbrirModalNotaCredito = props.setAbrirModalNotaCredito
    const notificacionDescarga = props.notificacionDescarga
    const dispatch = props.dispatch
    const anioSeleccionado = props.anioSeleccionado
    const mesSeleccionado = props.mesSeleccionado

    const CheckboxGroup = Checkbox.Group

    const {
        rex_data_filtro_territorio_nota_credito,
        rex_data_filtro_distribuidora_nota_credito,
        rex_check_data_zona,
        rex_check_data_zona_todo,
        rex_check_data_territorio,
        rex_check_data_territorio_todo,
        rex_check_data_distribuidora,
        rex_check_data_distribuidora_todo,
        list_data_zona_nota_credito,
    } = useSelector(({descarga}) => descarga)

    const {
        data_distribuidoras_nota_credito,
    } = useSelector(({notaCredito}) => notaCredito)

    useEffect(() => {
        dispatch(ObtenerDataDistribuidorasReducer())
    }, [])

    const [cargandoBoton, setCargandoBoton] = useState(false)
    const [mostrarDataZona, setMostrarDataZona] = useState(false)
    const [mostrarDataTerritorio, setMostrarDataTerritorio] = useState(false)
    const [mostrarDataDistribuidora, setMostrarDataDistribuidora] = useState(false)

    const [urlDescarga, setUrlDescarga] = useState("#")
    const btnDescargar = useRef(null)

    const cancelarModalDescarga = () => {
        setAbrirModalNotaCredito(!abrirModalNotaCredito)
        setMostrarDataZona(false)
        setMostrarDataTerritorio(false)
        setMostrarDataDistribuidora(false)
        setCargandoBoton(false)
        dispatch(CancelarModalReducer())
    }

    const DescargarExcel = async () => {
        if(mostrarDataZona == false && mostrarDataTerritorio == false && mostrarDataDistribuidora == false ){
            notificacionDescarga('warning', 'Lo sentimos, debes seleccionar algun filtro')
        }else{
            let nuevasZonas = []
            let nuevosTerritorios = []
            let nuevosDistribuidores = []
            
            if(mostrarDataZona == true){
                rex_check_data_zona.map((zona) => {
                    nuevasZonas.push({"clizona":zona})
                })
            }else if(mostrarDataTerritorio == true){
                rex_check_data_territorio.map((territorio) => {
                    nuevosTerritorios.push({"data":territorio})
                })
            }else if(mostrarDataDistribuidora == true){
                rex_check_data_distribuidora.map((distribuidora) => {
                    nuevosDistribuidores.push({"clisuchml":distribuidora})
                })
            }

            if(nuevasZonas.length == 0 && nuevosTerritorios.length == 0 && nuevosDistribuidores.length == 0){
                notificacionDescarga('warning', 'Lo sentimos, debes seleccionar por lo menos 1 dato')
            }else{
                setCargandoBoton(true)
                let rutaDescarga = await dispatch(ObtenerNotaCreditoReducer(nuevasZonas, nuevosTerritorios, nuevosDistribuidores, mostrarDataZona, mostrarDataTerritorio, mostrarDataDistribuidora, anioSeleccionado, mesSeleccionado ))
                if(rutaDescarga == "/"){
                }else{
                    setUrlDescarga(rutaDescarga)
                    btnDescargar.current.click()
                }
                cancelarModalDescarga()
            }
        }
    }
    return (
        <Modal
            width={450}
            className="modalNotaCreditoDescarga"
            title="Filtro de descarga"
            wrapClassName="prevModalNotaCreditoDescarga"
            closable={false}
            visible={abrirModalNotaCredito} 
            onOk={() => setAbrirModalNotaCredito(!abrirModalNotaCredito)}
            onCancel={() => setAbrirModalNotaCredito(!abrirModalNotaCredito)}
            footer={[
                <div style={{display:'flex', justifyContent: 'center', columnGap: '10px'}}>
                    <Button 
                        className={
                            mostrarDataZona == false && mostrarDataTerritorio == false && mostrarDataDistribuidora == false
                            ? "btnNotaCreditoDescargaDesactivado"
                            : "btnNotaCreditoDescarga"
                        }
                        onClick={() => DescargarExcel()}
                        loading={cargandoBoton}
                    >
                        Aceptar
                    </Button>
                    <a 
                        style={{display:'none'}}
                        ref={btnDescargar} 
                        href={urlDescarga}
                        download>Click to download
                    </a>
                    <Button 
                        className="btnCancelarNotaCreditoDescarga" 
                        onClick={() => cancelarModalDescarga()}
                    >
                        Cancelar
                    </Button>
                </div>
            ]}
        >
            <div 
                className={
                    mostrarDataTerritorio == true || mostrarDataDistribuidora == true
                    ?"selectNotaCreditoDescargaDesactivado Wbold-S13-H17-CFFFFFF" 
                    :"selectNotaCreditoDescarga Wbold-S13-H17-CFFFFFF" 
                }
                onClick={() => {
                    setMostrarDataZona(!mostrarDataZona)
                    setMostrarDataTerritorio(false)
                    setMostrarDataDistribuidora(false)
                }}
            >
                Zona
                <div className='Icono-Arrow-Filtro-Nota-Credito' style={{right: '40px'}}>
                    {
                        mostrarDataZona == true
                        ?<CaretUpOutlined />
                        :<CaretDownOutlined />
                    }
                </div>
            </div>
            {
                mostrarDataZona == true
                ?<div style={{paddingRight: '7px'}}>
                    <div className='Cuerpo-Filtro-Nota-Credito checkBoxListNotaCreditoDescarga'>
                        <div className='Wnormal-S13-H17-C004FB8' style={{marginBottom:'3px', marginTop: '5px',}}>
                            <Checkbox 
                                onChange={(data) => dispatch(OnCheckDataZonaTodoReducer(data))} 
                                checked={rex_check_data_zona_todo} 
                                className="checkBoxPadreNotaCreditoDescarga"
                            >
                                Seleccionar todo
                            </Checkbox>
                        </div>
                        <CheckboxGroup 
                            className="checkBoxGrupoNotaCreditoDescarga"
                            options={list_data_zona_nota_credito.map(zona => zona.clizona)} 
                            value={rex_check_data_zona} 
                            onChange={(data) => dispatch(OnCheckDataZonaReducer(data))} 
                        />
                    </div>
                </div>
                :null
            }
            <div 
                className={
                    mostrarDataZona == true || mostrarDataDistribuidora == true
                    ?"selectNotaCreditoDescargaDesactivado Wbold-S13-H17-CFFFFFF" 
                    :"selectNotaCreditoDescarga Wbold-S13-H17-CFFFFFF" 
                }
                onClick={() => {
                    setMostrarDataTerritorio(!mostrarDataTerritorio)
                    setMostrarDataZona(false)
                    setMostrarDataDistribuidora(false)
                }}
            >
                Territorio
                <div className='Icono-Arrow-Filtro-Nota-Credito' style={{right: '40px'}}>
                    {
                        mostrarDataTerritorio == true
                        ?<CaretUpOutlined />
                        :<CaretDownOutlined />
                    }
                </div>
            </div>
            {
                mostrarDataTerritorio == true
                ?<div style={{paddingRight: '7px',}}>
                    <div className='Cuerpo-Filtro-Nota-Credito' style={{paddingLeft: '65px', paddingRight:'65px', overflowY: 'auto', height: 'auto', maxHeight: '180px'}}>
                        <div className='Wnormal-S13-H17-C004FB8' style={{marginBottom:'3px', marginTop: '5px',}}>
                            <Checkbox 
                                onChange={(data) => dispatch(OnCheckDataTerritorioTodoReducer(data))} 
                                checked={rex_check_data_territorio_todo} 
                                className="checkBoxPadreNotaCreditoDescarga"
                            >
                                Seleccionar todo
                            </Checkbox>
                        </div>
                        <CheckboxGroup 
                            className="checkBoxGrupoNotaCreditoDescarga"
                            // options={rex_data_filtro_territorio_nota_credito.map(territorio => territorio.data)}
                            options={rex_data_filtro_territorio_nota_credito.map(territorio => territorio.alias)}
                            value={rex_check_data_territorio} 
                            onChange={(data) => {
                                dispatch(OnCheckDataTerritorioReducer(data))
                            }} 
                        />
                    </div>
                </div>
                :null
            }
            <div 
                className={
                    mostrarDataTerritorio == true || mostrarDataZona == true
                    ?"selectNotaCreditoDescargaDesactivado Wbold-S13-H17-CFFFFFF" 
                    :"selectNotaCreditoDescarga Wbold-S13-H17-CFFFFFF" 
                }
                onClick={() => {
                    setMostrarDataZona(false)
                    setMostrarDataTerritorio(false)
                    setMostrarDataDistribuidora(!mostrarDataDistribuidora)
                }}
            >
                Distribuidora
                <div className='Icono-Arrow-Filtro-Nota-Credito' style={{right: '40px'}}>
                    {
                        mostrarDataDistribuidora == true
                        ?<CaretUpOutlined />
                        :<CaretDownOutlined />
                    }
                </div>
            </div>
            {
                mostrarDataDistribuidora == true
                ? <div style={{paddingRight: '7px', maxHeight: '180px',}}>
                    <div className='Cuerpo-Filtro-Nota-Credito' style={{paddingLeft: '65px', paddingRight:'65px', overflowY: 'auto', height: 'auto', maxHeight: '180px'}}>
                        <div className='Wnormal-S13-H17-C004FB8' style={{marginBottom:'3px', marginTop: '5px',}}>
                            <Checkbox 
                                onChange={(data) => dispatch(OnCheckDataDistribuidoraTodoReducer(data))} 
                                checked={rex_check_data_distribuidora_todo} 
                                className="checkBoxPadreNotaCreditoDescarga"
                            >
                                Seleccionar todo
                            </Checkbox>
                        </div>
                        <CheckboxGroup 
                            className="checkBoxGrupoNotaCreditoDescarga"
                            options={
                                rex_data_filtro_distribuidora_nota_credito.length == 0
                                ? data_distribuidoras_nota_credito.map(distribuidora => distribuidora.clisuchml) 
                                : rex_data_filtro_distribuidora_nota_credito.map(distribuidora => distribuidora.clisuchml)
                            } 
                            value={rex_check_data_distribuidora} 
                            onChange={(data) => dispatch(OnCheckDataDistribuidoraReducer(data))} 
                        />
                    </div>
                </div>
                :null
            }
        </Modal>
    )
}

export default ModalNotaCredito