import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Popconfirm } from 'antd'
import {useDispatch, useSelector} from "react-redux";
import { CargarSellOutReducer } from '../../Redux/Actions';
import moment from 'moment'

const FiltroFechas = (props) => {
    
    const dispatch = useDispatch();

    const [ loadingSO, setLoadingSO ] = useState(false)

    const fechaActualizacion = useSelector(({login}) => login.fechaActualizacion)

    const {LoginUsuario} = useSelector(({login}) => login);

    const {
        fecha_actualizacion_so,
    } = useSelector(({subsidiosSo}) => subsidiosSo);


    const uploadSO = async () => {
        setLoadingSO(true)
        const rpta = await dispatch(CargarSellOutReducer())
        setLoadingSO(false)
    }

    const {
        ComunesTipoDisenio
    } = useSelector(({comunes}) => comunes);

    return (
        <Row style={{ width:'100%', display:'flex', justifyContent:'space-between'}}>
            <Col className={ComunesTipoDisenio == "Light" ?"Wbold-S20-H27-C004FB8" :"Wbold-S20-H27-Ce4e6eb"}>
                {props.titulo}
            </Col>
            {
                props.titulo == 'Subsidios Sell Out' && (LoginUsuario.usuusuario == 'jazmin.laguna@grow-analytics.com.pe' || LoginUsuario.usuusuario == 'gerson.vilca@grow-analytics.com.pe' || LoginUsuario.usuusuario == 'Jose.Cruz@grow-analytics.com.pe' || LoginUsuario.usuusuario == 'frank.martinez@grow-analytics.com.pe' || LoginUsuario.usuusuario == 'cindy.laguna@grow-analytics.com.pe')
                ? <Col style={{marginRight:'40px'}}>
                    <span style={{fontSize:'12px', fontWeight:'700', marginRight:'10px'}}>Última actualización: {moment(fecha_actualizacion_so).format("YYYY-MM-DD HH:mm:ss")}</span>
                    <Popconfirm
                        title={<div><b>¿Cargar SO?</b><br/> Posteriormente se deberán ejecutar los procesos SO y SI</div>}
                        onConfirm={() => uploadSO()}
                        onCancel={() => {}}
                        placement='bottomRight'
                        okText='Cargar'
                        cancelText='Cancelar'
                    >
                        <Button
                            style={{
                                backgroundColor:'#FF8023',
                                color:'#FFFFFF',
                                borderRadius: '23px',
                                fontWeight:'bold',
                            }}
                            loading={loadingSO}
                        >Cargar SO</Button>
                    </Popconfirm>
                </Col>
                :null
            }

            {/* <Col xl={14} 
                className="W600-S13-H17-C1EC0ED"
                style={{
                    textAlign: "-webkit-right",
                    alignSelf: "center",
                    paddingRight:'40px'
                }}
            >
                <img src={IconoActualizacion} width={"15px"} style={{marginRight:'5px'}}/>
                última actualización {fechaActualizacion}
            </Col> */}
        </Row>
    )
}

export default FiltroFechas
