export const OBTENER_DATA_TABLA_ADMINISTRAR_USUARIOS = "OBTENER_DATA_TABLA_ADMINISTRAR_USUARIOS"

export const OBTENER_DATA_TIPO_ADMINISTRAR_USUARIOS = "OBTENER_DATA_TIPO_ADMINISTRAR_USUARIOS"

export const OBTENER_DATA_EXCEL_ADMINISTRAR_USUARIOS = "OBTENER_DATA_EXCEL_ADMINISTRAR_USUARIOS"

export const OBTENER_DATA_EXCEL_AUDITORIAS_ADMINISTRAR_USUARIOS = "OBTENER_DATA_EXCEL_AUDITORIAS_ADMINISTRAR_USUARIOS"

export const CREAR_DATA_ADMINISTRAR_USUARIOS = "CREAR_DATA_ADMINISTRAR_USUARIOS"

export const ACTUALIZAR_DATA_ADMINISTRAR_USUARIOS = "ACTUALIZAR_DATA_ADMINISTRAR_USUARIOS"

export const ELIMINAR_DATA_ADMINISTRAR_USUARIOS = "ELIMINAR_DATA_ADMINISTRAR_USUARIOS"

export const ESTADO_ADMINISTRAR_USUARIOS = "ESTADO_ADMINISTRAR_USUARIOS"

export const SPIN_TABLE_ADMINISTRAR_USUARIOS = "SPIN_TABLE_ADMINISTRAR_USUARIOS"