import React, {useRef} from 'react'
import { Modal, Col, Row, Checkbox, Spin } from 'antd';
import { 
    LoadingOutlined,
    CaretDownOutlined,
    CaretRightOutlined
} from '@ant-design/icons';
import ReactExport from 'react-data-export';
import {useDispatch, useSelector} from "react-redux";
import {
    DesplegarFiltroColumnaReducer,
    SeleccionarColumnasDescargarReducer,
    VolverArmarExcelSubSoReducer
} from '../../Redux/Actions/SubsidiosSo/SubsidiosSoFront'
import {
    ObtenerDescargableSubsidiosSoReducer
} from '../../Redux/Actions/SubsidiosSo/SubsidiosSo'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ModalFiltrosSo = (props) => {
    
    const dispatch = useDispatch()

    const {
        AgrupacionesColumnas_Subsidios_SO,
        columnas_descargable_subsidios_so,
        armar_descargable_sub_so,
        cargando_descargable_subsidiosso,
        data_descarga_subsidiosso
    } = useSelector(({subsidiosSo}) => subsidiosSo);

    const mostrar = props.mostrar
    const setmostrar = props.setmostrar
    
    let refInput = useRef(null)

    return (
        <div>
            <Modal 
                title={null} 
                visible={mostrar} 
                footer={null}
                centered
                width="620px"
                height= "407px"
                bodyStyle={{
                    borderRadius: "8px"
                }}
                closeIcon={<img onClick={() =>setmostrar(!mostrar) } src={null}/>}
                onCancel={() =>setmostrar(!mostrar) }
            >
                <div
                    className="Wbold-S16-H19-C004FB8-L0015" 
                    style={{textAlign: "-webkit-center", marginBottom:'20px'}}>Filtros de Columnas a Descargar</div>

                <Row>
                    <Col xl={11}>
                        <div
                            className="Columnas-Mostradas-Filtro-Columnas"
                        >
                            <div className="Cabecera-Columnas-Mostradas-Filtro-Columnas">
                                <div className="Wbold-S14-H19-C004FB8-L0015">Columnas</div>
                                <div className="Wnormal-S11-H15-C706C64-L0015">Lista de columnas ocultas</div>
                            </div>
                            <div style={{overflow:'auto', width:'100%', height:'215px', marginTop:'10px'}}>
                                {
                                    AgrupacionesColumnas_Subsidios_SO.map((agrupacion, posicion) => {
                                        return(
                                            <>
                                                <div className="Etiqueta-Filtro-Columnas W600-S11-H15-CFFFFFF" style={{marginBottom:'2px'}}>
                                                    {
                                                        agrupacion.seleccionado == true
                                                        ?<CaretDownOutlined 
                                                            onClick={() => dispatch(DesplegarFiltroColumnaReducer(posicion))}
                                                            style={{paddingRight:'5px', cursor:'pointer'}} /> 
                                                        :<CaretRightOutlined 
                                                            onClick={() => dispatch(DesplegarFiltroColumnaReducer(posicion))}
                                                            style={{paddingRight:'5px', cursor:'pointer'}} /> 
                                                    }
                                                    {agrupacion.agrupacion}
                                                </div>
                                                {
                                                    agrupacion.seleccionado == true
                                                    ?columnas_descargable_subsidios_so.map((columna, pos) => {
                                                        return(
                                                            columna.seleccionado == false && columna.cabeceraAgrupacion == agrupacion.cabeceraAgrupacion 
                                                            ?<div key={columna.columna} style={{paddingLeft:'10px'}}>
                                                                <Checkbox 
                                                                    onChange={(e) => dispatch(SeleccionarColumnasDescargarReducer(pos, true))}
                                                                    checked={false}
                                                                >
                                                                    <span className="W600-S13-H17-C004FB8">{columna.columna}</span>
                                                                </Checkbox>
                                                            </div>
                                                            :null
                                                        )
                                                    })
                                                    // ?allColumns.map((column, posicion) => (
                                                    //     column.isVisible == false && column.cabeceraAgrupacion == agrupacion.cabeceraAgrupacion
                                                    //     ?<div key={column.id} style={{paddingLeft:'10px'}}>
                                                    //         <Checkbox  {...column.getToggleHiddenProps()}>
                                                    //         {/* <Checkbox > */}
                                                    //             <span className="W600-S13-H17-C004FB8">{column.Homologado}</span>
                                                    //         </Checkbox>
                                                    //     </div>
                                                    //     :null
                                                    // ))
                                                    :null
                                                }
                                            </>            
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </Col>
                    <Col xl={2} 
                        style={{
                            alignSelf: "center",
                            textAlign: "-webkit-center"
                        }}
                    >
                        {/* <div className="Flecha-Medio-Filtro-Columnas">{">"}</div>
                        <div className="Flecha-Medio-Filtro-Columnas">{"<"}</div> */}
                    </Col>
                    <Col xl={11}>
                        <div
                            className="Columnas-Mostradas-Filtro-Columnas"
                        >
                            <div className="Cabecera-Columnas-Mostradas-Filtro-Columnas">
                                <div className="Wbold-S14-H19-C004FB8-L0015">Columnas a Descargar</div>
                                <div className="Wnormal-S11-H15-C706C64-L0015">Seleccionar las columnas que desea descargar</div>
                            </div>
                            <div style={{overflow:'auto', width:'100%', height:'215px', marginTop:'10px', }}>
                                
                                {
                                    AgrupacionesColumnas_Subsidios_SO.map((agrupacion, posicion) => {
                                        return(
                                            <>
                                                <div className="Etiqueta-Filtro-Columnas W600-S12-H15-CFFFFFF" style={{marginBottom:'2px'}}>
                                                    {
                                                        agrupacion.seleccionado == true
                                                        ?<CaretDownOutlined 
                                                            onClick={() => dispatch(DesplegarFiltroColumnaReducer(posicion))}
                                                            style={{paddingRight:'5px', cursor:'pointer'}} /> 
                                                        :<CaretRightOutlined 
                                                            onClick={() => dispatch(DesplegarFiltroColumnaReducer(posicion))}
                                                            style={{paddingRight:'5px', cursor:'pointer'}} /> 
                                                    }
                                                    {agrupacion.agrupacion}
                                                </div>
                                                {
                                                    agrupacion.seleccionado == true
                                                    ?columnas_descargable_subsidios_so.map((columna, pos) => {
                                                        return(
                                                            columna.seleccionado == true && columna.cabeceraAgrupacion == agrupacion.cabeceraAgrupacion 
                                                            ?<div key={columna.columna} style={{paddingLeft:'10px'}}>
                                                                <Checkbox 
                                                                    onChange={(e) => dispatch(SeleccionarColumnasDescargarReducer(pos, false))}
                                                                    checked={true}
                                                                >
                                                                    <span className="W600-S13-H17-C004FB8">{columna.columna}</span>
                                                                </Checkbox>
                                                            </div>
                                                            :null
                                                        )
                                                    })
                                                    // ?allColumns.map((column, posicion) => (
                                                    //     column.isVisible == true && column.cabeceraAgrupacion == agrupacion.cabeceraAgrupacion
                                                    //     ?<div key={column.id} style={{paddingLeft:'10px'}}>
                                                    //         <Checkbox  {...column.getToggleHiddenProps()}>
                                                    //         {/* <Checkbox > */}
                                                    //             <span className="W400-S13-H17-C004FB8">{column.Homologado}</span>
                                                    //         </Checkbox>
                                                    //     </div>
                                                    //     :null
                                                    // ))
                                                    :null
                                                }
                                            </>            
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </Col>
                </Row>

                <div
                    style={{
                        textAlign: "-webkit-center",
                        marginTop: "20px"
                    }}
                >
                    {/* <button onClick={() => dispatch(VolverArmarExcelSubSoReducer())}>armar</button> */}

                    {
                        armar_descargable_sub_so == true
                        ?<Spin 
                            spinning={cargando_descargable_subsidiosso}
                            indicator={<LoadingOutlined />}
                            style={
                                cargando_descargable_subsidiosso == true
                                ?{width:'100%',
                                height:'100%',
                                cursor: 'not-allowed'}
                                :{}
                            }
                        >

                            <div
                                style={{
                                    width: "126px",
                                    height: "24px",
                                    background: "#EDF0FA",
                                    border: "1px solid #004FB8",
                                    boxSizing: "border-box",
                                    borderRadius: "14px",
                                    paddingTop:'2px',
                                    cursor:'pointer'
                                }}
                                className="W600-S13-H17-C004FB8"
                                onClick={async() => {
                                    await dispatch(ObtenerDescargableSubsidiosSoReducer(true))
                                    refInput.current.click();
                                    // dispatch(VolverArmarExcelSubSoReducer())
                                }}
                            >
                                Armar Descargable
                            </div>

                        </Spin>
                        :<ExcelFile 
                            filename="Subsidios So"
                            element={
                                <Spin 
                                    spinning={cargando_descargable_subsidiosso}
                                    indicator={<LoadingOutlined />}
                                    style={
                                        cargando_descargable_subsidiosso == true
                                        ?{width:'100%',
                                        height:'100%',
                                        cursor: 'not-allowed'}
                                        :{}
                                    }
                                >

                                    <div
                                        style={{
                                            width: "66px",
                                            height: "24px",
                                            background: "#EDF0FA",
                                            border: "1px solid #004FB8",
                                            boxSizing: "border-box",
                                            borderRadius: "14px",
                                            paddingTop:'2px',
                                            cursor:'pointer'
                                        }}
                                        className="W600-S13-H17-C004FB8"
                                        onClick={() => {
                                            setmostrar(!mostrar)
                                        }}
                                        ref={refInput}
                                    >
                                        Descargar
                                    </div>

                                </Spin>
                            }>
                            <ExcelSheet 
                                dataSet={data_descarga_subsidiosso} 
                                name="Subsidios So"
                            />
                        </ExcelFile>
                    }
                    

                    
                </div>

            </Modal>
        </div>
    )
}

export default ModalFiltrosSo