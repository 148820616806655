export default {
    // api: 'https://comosea.dir.tips/',
    // api: 'http://127.0.0.1:8000/',
    // api: 'http://3.15.235.193:8002/',
    api: 'https://backend-subsidios.grow-solutions.com/',
    // api: 'https://back-subtwo.gavsistemas.com/',
    // urlFrontend: 'http://3.15.235.193',
    // urlFrontend: 'http://localhost:3000',
    // urlFrontend: 'https://subsidios-thanos.softys-leadcorporate.com',
    urlFrontend: 'http://subsidios.grow-solutions.com',
    basename: '/',
    produccion: true,
    // pusherKey : '4fd88474ee9fac0fd635',
    nombreSistema : 'Subsidios',
    nombreSistemaHo : 'Subsidios',
    nombreCliente : 'Softys Perú',

    activarpoliticas : true
};
