import React, {useEffect, useState} from 'react'
import { Link, useParams } from "react-router-dom"
import { Button, Col, Row, Switch, notification, Modal, Spin, Input, Tooltip, Select, Empty } from 'antd'
import { CaretRightOutlined, CaretDownOutlined, LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import NoData from '../../../Assets/Gifs/no-data.gif'
import Search from 'antd/lib/input/Search'
import Title from 'antd/lib/typography/Title'
import BotonesRutas from '../../../Componentes/Administrar/BotonesRutas'
import '../../../Estilos/Rutas/Administrar/TiposUsuarios/TiposUsuarios.css'
import {
    ObtenerDataAdministrarTiposUsuariosReducer,
    CambiarEstadoPermisosTipoUsuarioReducer,
    CambiarEstadoPadrePermisosTipoUsuarioReducer,
    ObtenerDataAdministrarPermisosTiposUsuariosReducer,
    CrearDataAdministrarTiposUsuariosReducer,
    ActualizarDataAdministrarTiposUsuariosReducer,
    ObtenerListaFiltroPermisosTiposUsuariosReducer,
} from '../../../Redux/Actions/Administrar/TiposUsuarios/TiposUsuarios'
import {useDispatch, useSelector} from "react-redux"

const CrearActualizarTipoUsuario = () => {

    const dispatch = useDispatch()
    const { tpunombre } = useParams()
    const { Option } = Select

    const {
        rex_data_tipos_usuarios,
        rex_data_permisos_tipos_usuarios,
        rex_filtro_permisos_tipos_usuarios,
        rex_spin_permisos_tipos_usuarios,
        rex_spin_crear_editar_permisos_tipos_usuarios,
    } = useSelector(({administrarTiposUsuarios}) => administrarTiposUsuarios)

    useEffect(() => {
        dispatch(ObtenerDataAdministrarPermisosTiposUsuariosReducer(tpunombre ? "editar" : "crear", tpunombre))
        dispatch(ObtenerDataAdministrarTiposUsuariosReducer())
    }, [])

    const [txtBuscar, setTxtBuscar] = useState(null)
    const [txtTipoUsuario, setTxtTipoUsuario] = useState(tpunombre ? tpunombre : null)
    const [abrirPermisos, setAbrirPermisos] = useState([])
    const [abrirModalPermisos, setAbrirModalPermisos] = useState(false)
    const [mensajeModalTipoUsuario, setMensajeModalTipoUsuario] = useState(false)
    const [valorTipoUsuario, setValorTipoUsuario] = useState(null)

    const notificacionPrecaucion = (type, message) => {
        if(type === 'warning' && message){
            notification[type]({
                message: 'Advertencia',
                description: message,
            })
        }else if(type === 'warning'){
            notification[type]({
                message: 'Crear Usuario',
                description:
                    'No se guardarán los cambios realizados, si desea continuar con la opción de crear.',
            })
        }else if(type === 'error'){
            notification[type]({
                message: 'Error',
                description: 'Error al eliminar el usuario.',
            })
        }
    }

    const abrirPermisosTiposUsuarios = (tppid) => {
        const agregarArray = abrirPermisos.find(e => e == tppid)
        const quitarArray = abrirPermisos.filter(e => e !== tppid)
        setAbrirPermisos(
            agregarArray 
            ? quitarArray : [...abrirPermisos, tppid]
        )
    }

    const tipoUsuario = (tpuidValor, value) => {
        if(tpuidValor && value.children != tpunombre){
            Modal.confirm({
                title: 'Advertencia',
                content: tpuidValor 
                        ? `Todos los permisos se reemplazarán por el tipo de usuario ${value.children}. Estas seguro?`
                        : `Deseas agregar los permisos del tipo de usuario ${value.children}?`,
                okText: 'Modificar',
                cancelText: 'Cancelar',
                onOk() {
                    setValorTipoUsuario(tpuidValor)
                    dispatch(
                        ObtenerDataAdministrarPermisosTiposUsuariosReducer("editar", value.children)
                    )
                },
                onCancel() {
                },
            })
        }else{
            setValorTipoUsuario(tpuidValor)
            dispatch(
                ObtenerDataAdministrarPermisosTiposUsuariosReducer("editar", tpunombre)
            )
        }
    }

    const enviarData = async (tipo) => {
        if(txtTipoUsuario){
            const permisosTU = rex_data_permisos_tipos_usuarios.map(
                ({permisos}) => 
                ({data: permisos.map(p => ({pemid: p.pemid, seleccionado : p.seleccionado}))})
            )
            if(tipo == "crear"){
                const data = await dispatch(CrearDataAdministrarTiposUsuariosReducer(txtTipoUsuario, permisosTU))
                if(data.respuesta){
                    setAbrirModalPermisos(true)
                    setMensajeModalTipoUsuario(data.mensaje)
                }
            }else{
                const idTipoUsuario = rex_data_tipos_usuarios.find(tpu => tpu.tpunombre == tpunombre)
                const data = await dispatch(ActualizarDataAdministrarTiposUsuariosReducer(idTipoUsuario.tpuid, txtTipoUsuario, permisosTU))
                if(data.respuesta){
                    setAbrirModalPermisos(true)
                    setMensajeModalTipoUsuario(data.mensaje)
                }
            }
        }else{
            notificacionPrecaucion('warning', 'Por favor rellenar el campo tipo de usuario.')
        }
    }

    const iconoCarga = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    )

    return(
        <>
            <div 
                className="contenedorAdministrarModulo"
            >
                <Title 
                    className="title-controlArchivos"
                >
                    <Link 
                        to="/administrar/tipos-usuarios/"
                    >
                        <Tooltip 
                            placement="bottomLeft" 
                            title="Regresar a Tipos de Usuarios"
                        >
                            <ArrowLeftOutlined className="iconoRedireccion" />
                        </Tooltip>
                    </Link>
                    ADMINISTRADOR
                </Title>
                <Row 
                    className="row-button-controlArchivos"
                    style={{ 
                        rowGap:'25px',
                        padding: '0 15px 0 15px', 
                        marginTop: '25px',
                    }}
                >
                    <Col 
                        xl={11}
                        lg={14}
                        md={24}
                        xs={24}
                    >
                        <BotonesRutas 
                            ruta="tipo" 
                        />
                    </Col>
                    <Col 
                        xl={13}
                        lg={{
                            span: 10,
                            offset: 0,
                        }}
                        md={{
                            span: 12,
                            offset: 12,
                        }}
                        sm={{
                            span: 14,
                            offset: 10,
                        }}
                        xs={24}
                    >
                        <Search
                            placeholder="Buscar"
                            allowClear
                            className="search-controlArchivos tipoUsuario"
                            onChange={(e) => {
                                setTxtBuscar(e.target.value)
                                dispatch(ObtenerListaFiltroPermisosTiposUsuariosReducer(e.target.value))
                            }}
                        />
                    </Col>
                </Row>
                <Spin
                    spinning={rex_spin_permisos_tipos_usuarios} 
                    indicator={iconoCarga}
                >
                    <Row 
                        style={{
                            marginTop: '30px', 
                            paddingLeft: '15px', 
                            paddingRight: '15px'
                        }}
                    >
                        <Col 
                            lg={12}
                            xs={24} 
                        >
                            <Row 
                                className="switchAdministarContenedorTexto"
                                style={{rowGap: '15px'}}
                            >
                                <Col 
                                    md={8} 
                                    sm={24} 
                                    style={{
                                        display: 'flex', 
                                        alignItems: 'center'
                                    }}
                                >
                                    <h3 className="switchAdministrarTitulo">
                                        {tpunombre ? "Editar:" : "Crear Nuevo:"}
                                    </h3>
                                </Col>
                                <Col 
                                    md={16}
                                    sm={15} 
                                    xs={24}
                                >
                                    <Input 
                                        defaultValue={tpunombre}
                                        onChange={(e) => setTxtTipoUsuario(e.target.value)} 
                                        placeholder="Tipo de Usuario"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{
                                        offset: 9,
                                        span: 15,
                                    }}
                                    sm={{
                                        offset: 0,
                                        span: 17,
                                    }} 
                                    xs={{
                                        offset: 0,
                                        span: 24,
                                    }} 
                                    className="selectContenedorAdministrar"
                                >
                                    <Select 
                                        size="large" 
                                        onChange={(e, value) => tipoUsuario(e, value)} 
                                        defaultValue="Seleccione un tipo de usuario"
                                        value={valorTipoUsuario} 
                                        className="tipoControlArchivo"
                                    >
                                        <Option>Seleccione un tipo de usuario</Option>
                                        {
                                            rex_data_tipos_usuarios.map((data, index) => (
                                                <Option 
                                                    key={index} 
                                                    value={data.tpuid}
                                                >
                                                    {data.tpunombre}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Col>
                            </Row>
                            {
                                rex_filtro_permisos_tipos_usuarios.length > 0
                                ? rex_filtro_permisos_tipos_usuarios.map((permisos, index) => {
                                    let lengthData = rex_data_permisos_tipos_usuarios.find(d => d.tppnombre == permisos.tppnombre)
                                    return(
                                    <>
                                    <Row 
                                        className="switchAdministrarContenedorPadre" style={{marginTop: '25px'}} 
                                        key={index}
                                    >
                                        <Col 
                                            md={1}
                                            sm={1}
                                            xs={1}
                                            style={{
                                                display: 'flex', 
                                                justifyContent: 'end', 
                                                alignItems: 'center',
                                                cursor: 'pointer', 
                                                padding: '11px 0'
                                            }}
                                            onClick={() => 
                                                abrirPermisosTiposUsuarios(permisos.tppid)
                                            }
                                        >
                                            {
                                                abrirPermisos.find(abrir => abrir == permisos.tppid)
                                                ?   <CaretDownOutlined />
                                                :   <CaretRightOutlined />
                                            }
                                        </Col>
                                        <Col 
                                            md={20} 
                                            sm={20}
                                            xs={17}
                                            style={{
                                                cursor: 'pointer', 
                                                padding: '11px 0'
                                            }}
                                            onClick={() => 
                                                abrirPermisosTiposUsuarios(permisos.tppid)
                                            }
                                        >
                                            <p className="switchAdministrarTextoPadre">{permisos.tppnombre}</p>
                                        </Col>
                                        <Col 
                                            md={3} 
                                            sm={3}
                                            xs={6}
                                            style={{
                                                display: 'flex', 
                                                justifyContent: 'end', 
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Switch 
                                                className="switchPadreAdministrar" 
                                                style={{ 
                                                    display: 
                                                    permisos.permisos.length == lengthData.permisos.length
                                                    ? 'block' 
                                                    : 'none'
                                                }}
                                                checked={permisos.seleccionado ? true : false}
                                                defaultChecked={permisos.seleccionado ? true : false}
                                                onChange={(estado) => 
                                                    dispatch(CambiarEstadoPadrePermisosTipoUsuarioReducer(permisos.tppid, estado, txtBuscar))
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        abrirPermisos.find(abrir => abrir == permisos.tppid)
                                        ?   permisos.permisos.map((p, index) =>
                                            <Row 
                                                className="switchAdministrarContenedorHijo"
                                                key={index}
                                            >
                                                <Col 
                                                    sm={20}
                                                    xs={{
                                                        span: 15,
                                                        offset: 1,
                                                    }}
                                                >
                                                    <p 
                                                        className="switchAdministrarTextoHijo"
                                                    >
                                                        {p.pemnombre}
                                                    </p>
                                                </Col>
                                                <Col 
                                                    sm={3}
                                                    xs={8}
                                                    style={{
                                                        display: 'flex', justifyContent: 'end', alignItems: 'center'
                                                    }}
                                                >
                                                    <Switch 
                                                        className="switchHijoAdministrar"
                                                        checked={p.seleccionado ? true : false}
                                                        defaultChecked={p.seleccionado ? true : false}
                                                        onChange={
                                                            (estado) => dispatch(CambiarEstadoPermisosTipoUsuarioReducer(p.pemid, estado, permisos.tppid, txtBuscar))
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                        :null
                                    }
                                    </>)
                                })
                                :   <Empty 
                                        style={{
                                            marginTop: '25px', 
                                            border: '1px solid #f0f0f0',
                                            paddingBottom: '23px',
                                        }} 
                                        image={NoData} 
                                    />
                            }
                            <Row 
                                style={{marginTop: '30px'}}
                            >
                                <Col 
                                    sm={{
                                        span: 8,
                                        offset: 16,
                                    }}
                                    xs={24}
                                    style={{textAlign: 'end'}}
                                >
                                    <Button 
                                        className="button-controlArchivos btnAdministrar" 
                                        shape="round"
                                        onClick={() => enviarData(tpunombre ? "editar" : "crear")}
                                        loading={rex_spin_crear_editar_permisos_tipos_usuarios}
                                    >
                                        Crear y Guardar
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="gutter-row" md={12}></Col>
                    </Row>
                </Spin>

                <Modal 
                    title={tpunombre ? "Editar Tipo de Usuario" : "Crear Nuevo Tipo de Usuario"}
                    className="modalAdministrarCrear"
                    width={350}
                    visible={abrirModalPermisos} 
                    closable={false}
                    centered={true}
                    onOk={() => setAbrirModalPermisos(true)} 
                    onCancel={() => setAbrirModalPermisos(false)}
                    footer={[
                        <Link
                            to="/administrar/tipos-usuarios/"
                        >
                            <Button
                                className="button-controlArchivos btnAdministrarModal" 
                                shape="round"
                            >
                                Listo
                            </Button>
                        </Link>
                    ]}
                >
                    <p>{mensajeModalTipoUsuario}</p>
                </Modal>
            </div>
        </>
    )
}

export default CrearActualizarTipoUsuario