export const OBTENER_FACTURAS_SI = "OBTENER_FACTURAS_SI"
export const CARGANDO_FACTURAS_SI = "CARGANDO_FACTURAS_SI"

export const CARGANDO_RECONOCIMIENTOS_FACTURA_SI_FACTURAS_SI = "CARGANDO_RECONOCIMIENTOS_FACTURA_SI_FACTURAS_SI"
export const OBTENER_RECONOCIMIENTOS_FACTURA_SI_FACTURAS_SI = "OBTENER_RECONOCIMIENTOS_FACTURA_SI_FACTURAS_SI"

export const CARGANDO_OBTENER_BIGDATA = 'CARGANDO_OBTENER_BIGDATA'
export const OBTENER_FACTURAS_SI_BIGDATA = 'OBTENER_FACTURAS_SI_BIGDATA'
export const OBTENER_FACTURAS_SO_BIGDATA = 'OBTENER_FACTURAS_SO_BIGDATA'
export const OBTENER_MATERIALES_BIGDATA  = 'OBTENER_MATERIALES_BIGDATA'
export const OBTENER_CLIENTES_BIGDATA    = 'OBTENER_CLIENTES_BIGDATA'