import { 
    SELECT_DOWNLOAD_DESCARGA,
    SELECT_LIST_DOWNLOAD_DESCARGA,
    FILTRO_DATA_TERRITORIO_NOTA_CREDITO,
    FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO,
    DATA_SELL_OUT,
    CHECK_DATA_ZONA,
    CHECK_DATA_ZONA_TODO,
    CHECK_DATA_TERRITORIO,
    CHECK_DATA_TERRITORIO_TODO,
    CHECK_DATA_DISTRIBUIDORA,
    CHECK_DATA_DISTRIBUIDORA_TODO,
    LIST_DATA_ZONA_NOTA_CREDITO,
    LIST_DATA_TERRITORIO_NOTA_CREDITO
} from "../../../Constantes/Descarga/Descarga";

const INIT_STATE = {
    list_downloads_descarga : [
        {download: 'Subsidios Sell Out', selected: true},
        {download: 'Subsidios Sell In', selected: false},
        {download: 'Nota de Crédito', selected: false},
        {download: 'Histórico de Subsidios', selected: false}        
    ],
    list_data_zona_nota_credito : [
        {"clizona": "B2B", "seleccionado": false}, 
        {"clizona" : "TRADICIONAL","seleccionado": false},
        // {"clizona": "LIMA", "seleccionado": false}, 
        // {"clizona" : "PROVINCIA","seleccionado": false},
        {"clizona" : "DIRECTOS","seleccionado": false},    
    ],
    list_data_territorio_nota_credito : [
        {"data": "CENTRO", "seleccionado": false, "zona": "TRADICIONAL"},
        {"data": "ORIENTE", "seleccionado": false, "zona": "TRADICIONAL"},
        {"data": "CENTRO ORIENTE", "seleccionado": false, "zona": "TRADICIONAL"},
        {"data": "NORTE 1", "seleccionado": false, "zona": "TRADICIONAL"},
        {"data": "NORTE 2", "seleccionado": false, "zona": "TRADICIONAL"},
        {"data": "SUR 1", "seleccionado": false, "zona": "TRADICIONAL"},
        {"data": "SUR 2", "seleccionado": false, "zona": "TRADICIONAL"},
        {"data": "DIRECTOS 1", "seleccionado": false, "zona": "DIRECTOS"},
        {"data": "LIMA 1", "seleccionado": false, "zona": "B2B"},
        {"data": "LIMA 2", "seleccionado": false, "zona": "B2B"},
        {"data": "LIMA 3", "seleccionado": false, "zona": "B2B"},
        {"data": "LIMA 4", "seleccionado": false, "zona": "B2B"},
        {"data": "INDIRECTO ESTADO", "seleccionado": false, "zona": null},
        {"data": "MEDIANA EMPRESA", "seleccionado": false, "zona": "DIRECTOS"},
    ],
    rex_data_filtro_territorio_nota_credito : [],
    rex_data_filtro_distribuidora_nota_credito : [],
    rex_check_data_zona : [],
    rex_check_data_zona_todo : false,
    rex_check_data_territorio : [],
    rex_check_data_territorio_todo : false,
    rex_check_data_distribuidora : [],
    rex_check_data_distribuidora_todo : false,
    rex_data_sell_out : [],
    download_selected_descarga : "Subsidios Sell Out"
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SELECT_LIST_DOWNLOAD_DESCARGA: {
            return {
                ...state,
                download_selected_descarga : action.payload
            }
        }
        case LIST_DATA_ZONA_NOTA_CREDITO: {
            return {
                ...state,
                list_data_zona_nota_credito : action.payload
            }
        }
        case LIST_DATA_TERRITORIO_NOTA_CREDITO: {
            return {
                ...state,
                list_data_territorio_nota_credito : action.payload
            }
        }
        case SELECT_DOWNLOAD_DESCARGA: {
            return {
                ...state,
                download_selected_descarga : action.payload
            }
        }
        case FILTRO_DATA_TERRITORIO_NOTA_CREDITO: {
            return {
                ...state,
                rex_data_filtro_territorio_nota_credito : action.payload
            }
        }
        case FILTRO_DATA_DISTRIBUIDORA_NOTA_CREDITO: {
            return {
                ...state,
                rex_data_filtro_distribuidora_nota_credito : action.payload
            }
        }
        case DATA_SELL_OUT: {
            return {
                ...state,
                rex_data_sell_out : action.payload
            }
        }
        case CHECK_DATA_ZONA: {
            return {
                ...state,
                rex_check_data_zona : action.payload
            }
        }
        case CHECK_DATA_ZONA_TODO: {
            return {
                ...state,
                rex_check_data_zona_todo : action.payload
            }
        }
        case CHECK_DATA_TERRITORIO: {
            return {
                ...state,
                rex_check_data_territorio : action.payload
            }
        }
        case CHECK_DATA_TERRITORIO_TODO: {
            return {
                ...state,
                rex_check_data_territorio_todo : action.payload
            }
        }
        case CHECK_DATA_DISTRIBUIDORA: {
            return {
                ...state,
                rex_check_data_distribuidora : action.payload
            }
        }
        case CHECK_DATA_DISTRIBUIDORA_TODO: {
            return {
                ...state,
                rex_check_data_distribuidora_todo : action.payload
            }
        }
        default:
            return state;
    }
}
  